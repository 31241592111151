import { getAPI, postAPI } from "../../apis/apis";

export const skipProperty = async (payload: any) => {
  return postAPI({
    url: process.env.REACT_APP_BASE_URL + `/property/skip`,
    headers: {},
    data: payload,
  });
};

export const activeSkip = async () => {
  return getAPI({
    url: process.env.REACT_APP_BASE_URL + `/active-skip`,
    headers: {},
  });
};


export const taxOnSkip= async(payload:any)=>{
  return postAPI({
    url : process.env.REACT_APP_BASE_URL + `/skip-trace-tax`,
    headers:{},
    data:payload
  })
}


export const uploadListSkipTrace= async(payload:any)=>{
  return postAPI({
    url : process.env.REACT_APP_BASE_URL + `/api/v1/skip-uploaded-list`,
    headers:{},
    data: payload
  })
}