import React from 'react'
import { motion } from "framer-motion";
import Button from "../../atoms/Button";
import styles from "./CRMHero.module.scss";
import { Star } from 'lucide-react';
import VaVideo from "../../assets/videos/crm.mp4";
import { features, headingOne, headingTwo, title } from './constants';


const CRMHero = ({ handleSignUp, handleLearnMore }: any) => {
    return (
        <>
            <section className={`dflex justifyCenter alignCenter  ${styles.hero}`}>
                <div className={`wrapper-lg`}>
                    <div className={`dflex alignCenter ${styles.hero__main}`}>
                        <div className={` ${styles.hero__left}`}>
                            <motion.div
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.6, delay: 0.1 }}
                                className={` ${styles.hero__badge} flex alignCenter`}
                            >
                                <Star />
                                <span>{title}</span>
                            </motion.div>
                            <motion.div
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.6, delay: 0.2 }}
                                className={` ${styles.hero__content}`}
                            >
                                <h1 dangerouslySetInnerHTML={{ __html: headingOne }} />
                                <p>{headingTwo}</p>
                                <ul>
                                    {features.map((feature, index) => (
                                        <motion.li
                                            key={index}
                                            initial={{ opacity: 0, x: -20 }}
                                            animate={{ opacity: 1, x: 0 }}
                                            transition={{ duration: 0.5, delay: 0.3 + index * 0.1 }}
                                            className="flex alignCenter"
                                        >
                                            <span className="flex alignCenter justifyCenter">
                                                <feature.icon />
                                            </span>
                                            {feature.title}
                                        </motion.li>
                                    ))}
                                </ul>
                            </motion.div>
                            <motion.div
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.6, delay: 0.6 }}
                                className={`flex ${styles.hero__btn}`}
                            >
                                <Button
                                    className="primary"
                                    label="7 Day Free Trial"
                                    action={handleSignUp}
                                />
                            </motion.div>
                        </div>

                        <motion.div
                            initial={{ opacity: 0, scale: 0.95 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{ duration: 1, delay: 0.3 }}
                            className={` ${styles.hero__right}`}
                        >
                            <video
                                muted
                                autoPlay
                                loop
                                playsInline
                                width="100%"
                                src={VaVideo}
                            />
                        </motion.div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CRMHero