import { useState, useRef, useEffect, useCallback } from "react";
import styles from "./MeetOttoSec.module.scss";
import sampleFile from "../../../assets/audio/sample_audio.mp3";
import sampleOttoConvo1 from "../../../assets/audio/sample_otto_convo.mp3";
import { waveformInfo } from "../HomeConstants";
import MemoizedWaveform from "./waveform/Waveform";
import Counter from "./Counter";

const MeetOttoSec = () => {
  const meetOttoRef = useRef(null);
  const waveformRefOne = useRef<HTMLDivElement | any>(null);
  const waveformRefTwo = useRef<HTMLDivElement | any>(null);
  const waveformRefThree = useRef<HTMLDivElement | any>(null);
  const [isMeetOttoVisible, setIsMeetOttoVisible] = useState(false);
  const [playAudioOne, setPlayAudioOne] = useState(false);
  const [playAudioTwo, setPlayAudioTwo] = useState(false);
  const [playAudioThree, setPlayAudioThree] = useState(false);
  const [counter, setCounter] = useState({
    one: 0,
    two: 0,
    three: 0,
    four: 0.001,
  });

  const handleAudio = (waveId: string) => {
    switch (waveId) {
      case "waveform1":
        setPlayAudioTwo(false);
        waveformRefTwo.current.pause();
        setPlayAudioThree(false);
        waveformRefThree.current.pause();
        break;
      case "waveform2":
        setPlayAudioThree(false);
        waveformRefThree.current.pause();
        setPlayAudioOne(false);
        waveformRefOne.current.pause();
        break;
      case "waveform3":
        setPlayAudioOne(false);
        waveformRefOne.current.pause();
        setPlayAudioTwo(false);
        waveformRefTwo.current.pause();
        break;
      default:
        break;
    }
  };

  const incrementCounter = useCallback(
    (counterName: string, limit: number, intervalTime: number) => {
      const decimalPart = limit % 1;
      const wholePart = limit - decimalPart;
      let currentValue = 0;

      return setInterval(() => {
        setCounter((prevCounter: any) => {
          if (currentValue < wholePart) {
            currentValue += 1;
            return {
              ...prevCounter,
              [counterName]: currentValue,
            };
          } else if (currentValue < limit) {
            currentValue += 0.1;
            return {
              ...prevCounter,
              [counterName]: currentValue.toFixed(1),
            };
          }
          return prevCounter;
        });
      }, intervalTime);
    },
    [setCounter]
  );

  useEffect(() => {
    if (isMeetOttoVisible) {
      const intervalOne = incrementCounter("one", 150, 15);
      const intervalTwo = incrementCounter("two", 97, 5);
      const intervalThree = incrementCounter("three", 130, 8);
      const intervalFour = incrementCounter("four", 1, 400);

      return () => {
        clearInterval(intervalOne);
        clearInterval(intervalTwo);
        clearInterval(intervalThree);
        clearInterval(intervalFour);
      };
    }
  }, [isMeetOttoVisible, incrementCounter]);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsMeetOttoVisible(true);
        } else {
          setIsMeetOttoVisible(false);
        }
      });
    }, options);

    if (meetOttoRef.current) {
      observer.observe(meetOttoRef.current);
    }

    return () => {
      if (meetOttoRef.current) {
        observer.unobserve(meetOttoRef.current);
      }
    };
  }, [meetOttoRef]);

  return (
    <div ref={meetOttoRef} className={`${styles.numberSection}`}>
        <div className="wrapper-lg">
    <ul
      className="dflex justifySpaceBetween"
    >
      <Counter value={counter.one} label="Nationwide Properties" lastDigit="M+"
      description="Our data covers 98% of the market, reaching every corner of the U.S." />
      <Counter value={counter.two} label="Skip Tracing Accuracy" lastDigit="%+" 
      description="Connect with property owners effectively with fewer wrong numbers."
      />
      <Counter
        value={counter.three}
        label="Data Points + Filters"
        lastDigit="+"
        description="Target properties using distress indicators like ownership history, valuations, and mortgage details."
      />
      <Counter value={counter.four} label="Successful Leads" lastDigit="M+"
      description="We've successfully delivered over 1M leads to real estate professionals just like you."
      />
    </ul>
    </div>
  </div>

  );
};

export default MeetOttoSec;
