
import {  Users, FileText, Globe, ZoomIn, ThermometerSun} from "lucide-react"
import ClockRewind from "../../assets/icons/clockRewind"
import Bar from "../../assets/icons/bar"
import CallDownIcon from "../../assets/icons/callDown"

export const extracts = [
"Seller Situation",
"Selling Timeline",
"Motivation Level",
"Asking Price",
"Price Flexibility",
"Repairs Needed",
  ]

 export const features = [
    {
      icon: FileText,
      title: "Upload Your List",
      description: "Upload a list of leads or pull fresh leads from OttoLeads' 122M+ property database.",
    },
    {
      icon: CallDownIcon,
      title: "AI Starts Calling",
      description: "Schedule your campaign and our AI instantly starts calling prospective sellers.",
    },
    {
      icon: Users,
      title: "AI Qualifies Leads",
      description: "Human-like conversational AI qualifies leads based on motivation, asking price, property condition, and deal readiness.",
    },
    {
      icon: ClockRewind,
      title: "AI Detects Details",
      description: "AI detects seller objections, motivations, and interest level.",
    },
    {
      icon: ThermometerSun,
      title: "Get Warm Leads",
      description: "You get a list of motivated prospects with full conversation summaries.",
    },
    {
      icon: Bar,
      title: "Smart Lead Summaries",
      description: "Every call is transcribed and analyzed so you never miss key details.",
    },
  ]