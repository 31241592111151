import { useEffect, useState } from "react"
import Register from "../../organisms/auth/Register";
import CRMHero from "../../organisms/crmHero/CRMHero";
import CRMFeatures from "../../organisms/crmFeatures/CRMFeatures";
import CRMWorkflow from "../../organisms/crmWorkflow/CRMWorkflow";
import CRMCalculator from "../../organisms/crmCalculator/CRMCalculator";
import CRMComparison from "../../organisms/crmComparison/CRMComparison";
import HappyCustomerSec from "../home/happyCustomerSec/HappyCustomerSec";


const IntegratedCRMPage=() =>{
    const [openRegisterModal, setOpenRegisterModal] = useState<boolean>(false);
      const [email, setEmail] = useState<string>("");
    
    
      const handleSignUp=()=>{
        setOpenRegisterModal(!openRegisterModal)
    }
  

  useEffect(() => {
    // Scroll to top when the component mounts
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
    <CRMHero handleSignUp={handleSignUp} />
    <CRMFeatures handleSignUp={handleSignUp} />
    <CRMWorkflow/>
    <CRMCalculator />
    <CRMComparison handleSignUp={handleSignUp} />
    <HappyCustomerSec extraBottomSpace/>

    {openRegisterModal ? (
      <Register
      open={openRegisterModal}
      setOpen={setOpenRegisterModal}
      openLogin={true}
      email={email}
      ></Register>
  ) : (
      ""
  )}
  </>
  )
}

export default IntegratedCRMPage;