export const DISCOVER_COLUMNS = [
    "Feature",
    "Ottoleads",
    "Deal Machine",
    "Prop Stream",
    "Property Radar",
    "Privy",
    "Batch Leads",
  ];
  
  export const DISCOVER_DATA = [
    {
      feature: "AI-Driven Lead Prioritization",
      ottoleads: true,
      dealMachine: false,
      propStream: false,
      propertyRadar: false,
      privy: false,
      batchLeads: false,
    },
    {
      feature: "Conversational AI",
      ottoleads: true,
      dealMachine: false,
      propStream: false,
      propertyRadar: false,
      privy: false,
      batchLeads: false,
    },
    {
      feature: "Nationwide Property Data",
      ottoleads: true,
      dealMachine: true,
      propStream: true,
      propertyRadar: true,
      privy: true,
      batchLeads: true,
    },
    {
      feature: "MLS Photos & Insights",
      ottoleads: true,
      dealMachine: false,
      propStream: true,
      propertyRadar: true,
      privy: false,
      batchLeads: true,
    },
    {
      feature: "Pre-Foreclosure & Tax Lien Data",
      ottoleads: true,
      dealMachine: true,
      propStream: true,
      propertyRadar: true,
      privy: true,
      batchLeads: true,
    },
    {
      feature: "CRM Integration",
      ottoleads: true,
      dealMachine: false,
      propStream: true,
      propertyRadar: true,
      privy: false,
      batchLeads: true,
    },
    {
      feature: "Human VA Calling",
      ottoleads: true,
      dealMachine: false,
      propStream: false,
      propertyRadar: true,
      privy: false,
      batchLeads: false,
    },
    {
      feature: "Real-Time Lead Status Updates",
      ottoleads: true,
      dealMachine: false,
      propStream: false,
      propertyRadar: true,
      privy: false,
      batchLeads: true,
    },
    {
      feature: "Phone Number & Email Skip Tracing",
      ottoleads: true,
      dealMachine: true,
      propStream: true,
      propertyRadar: true,
      privy: false,
      batchLeads: true,
    },
    {
      feature: "Lead Scoring",
      ottoleads: true,
      dealMachine: false,
      propStream: false,
      propertyRadar: true,
      privy: false,
      batchLeads: true,
    },
  ];
  