const TickTock = () => {
    return (
        <>
   <svg xmlns="http://www.w3.org/2000/svg" width="21" height="25" viewBox="0 0 21 25" fill="none">
<path d="M15.5725 0.839844H11.5278V17.1876C11.5278 19.1355 9.97219 20.7355 8.03626 20.7355C6.10034 20.7355 4.54469 19.1355 4.54469 17.1876C4.54469 15.2746 6.06577 13.7094 7.93257 13.6398V9.53551C3.81872 9.60504 0.5 12.979 0.5 17.1876C0.5 21.4312 3.88786 24.8398 8.07085 24.8398C12.2538 24.8398 15.6416 21.3964 15.6416 17.1876V8.80504C17.1627 9.91811 19.0295 10.579 21 10.6138V6.50941C17.9579 6.40506 15.5725 3.90071 15.5725 0.839844Z" 
fill="currentColor"
/>
</svg>
        </>
    );
};

export default TickTock;