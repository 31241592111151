import React, { useEffect, useState } from "react";
import { CampaignInsufficientProps } from "./interface";
import Button from "../../atoms/Button";
import ArrowLeft from "../../assets/icons/arrowLeft";
import { CampaignSteps } from "./enum";
import styles from "./CreateCampaign.module.scss";
import { fetchInsufficientCredits } from "./createCampaignServices";
import DbIcon from "../../assets/icons/dbIcon";
import Zap from "../../assets/icons/zap";
import Loader from "../../atoms/Loader";
import { formartNumberAsLocalString } from "../../utils/utils";
import CouponCard from "../../pages/couponCard/CouponCard";
import { validateCoupon } from "../creditsSubscription/CreditsSubscriptionService";

const CampaignInsufficientCredits = ({
  onSubmit,
  handleBack,
  listArray,
  insufficientCreditsLoader,
  openAddPaymentModal,
  couponStatus,
  setCouponStatus,
  updatedPricing,
  handleRemoveCoupon,
  validateButtonLoading,setValidateButtonLoading,
  setUpdatedPricing
}: CampaignInsufficientProps) => {
  const [amount, setAmount] = useState({
    amount: 0,
    taxAmount: 0,
    totalAmount: 0,
    additionalNeededCredits:0
  });
  const [isLoading, setIsLoading] = useState(false);
  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await fetchInsufficientCredits(listArray);
      setAmount({
        amount: response?.data?.result?.amount,
        taxAmount: response?.data?.result?.taxAmount,
        totalAmount: response?.data?.result?.totalAmount,
        additionalNeededCredits:response?.data?.result?.additionalNeededCredits
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };
  
  const handleCoupon = async (data: any) => {
        try {
          setValidateButtonLoading(true);
          const res = await validateCoupon({
            couponCode: data?.code.toUpperCase(),
            price:amount?.amount,
            identifier:"ai_credit_top_up"
          });
          setUpdatedPricing(res?.data?.data);
          setValidateButtonLoading(false);
          setCouponStatus(`Coupon Code Applied! (${res?.data?.data.discount_type ==="percentage" ? `${res?.data?.data.discount_value}%` :`$${res?.data?.data.discount_value}`} Off)`);
        } catch (error:any) {
          setValidateButtonLoading(false);
          setCouponStatus(error?.response?.data?.message);
        }
      };

  useEffect(() => {
    if(!openAddPaymentModal){
      fetchData();
    }
  }, [openAddPaymentModal]);

  return (
    <>
      <div className={`${styles.createCampaign}`}>
        {isLoading? (
                <div className="loader-main">
          <Loader />
          </div>
        ) : (
          <>
            <div
              className={`headerIcon ${styles.headerIcon} ${styles.orange} ${styles.spaceBottom}`}
              data-testid="headerIcon"
            >
              <span>
                <DbIcon />
              </span>
            </div>
            <div className={`${styles.createCampaign__header} `}>
              <h3>Additional credits needed to continue</h3>
              <p>
               You need an additional <strong>{formartNumberAsLocalString(amount?.additionalNeededCredits)}</strong> credits  to proceed with launching your campaign.
              </p>
              <p>
                Please <u>purchase</u> a <strong>one-time</strong> top up below
              </p>
              <p>
                We recommend upgrading your AI Calling plan to get more bang for your buck on credits.
              </p>
            </div>

            <CouponCard
          loading={validateButtonLoading}
          couponStatus={couponStatus}
          onSubmit={handleCoupon}
          setCouponStatus={setCouponStatus}
          handleRemoveCoupon={handleRemoveCoupon}
          couponSelected ={updatedPricing?.id}
        />

            <div className={`dflex justifySpaceBetween totalRow borderTop ${styles.createCampaign__totalText}`}>
              <p>
                <small>Subtotal</small>
              </p>
              <div className={`right `}>
                <p>
                  <small> ${formartNumberAsLocalString(amount?.amount,{minimumFractionDigits: 2, maximumFractionDigits: 6})}</small>
                </p>
              </div>
            </div>

          {amount?.taxAmount !=0 ?  <div className={`dflex justifySpaceBetween totalRow  ${styles.createCampaign__totalText}`}>
              <p>
                <small>Tax</small>
              </p>
              <div className={`right `}>
                <p>
                  <small> ${updatedPricing ? updatedPricing?.pricing?.breakdown?.taxAmount :  formartNumberAsLocalString(amount?.taxAmount,{minimumFractionDigits: 2, maximumFractionDigits: 6})}</small>
                </p>
              </div>
            </div>
            :null
        }
         {updatedPricing?.pricing?.breakdown?.discountAmount && (
          <div className={`flex justifySpaceBetween totalRow`}>
            <p>Coupon Discount ({updatedPricing?.id}):</p>
            <div className={` right`}>
              <p>${updatedPricing?.pricing?.breakdown?.discountAmount}</p>
            </div>
          </div>
        )}
            <div className={`dflex justifySpaceBetween totalRow borderTop ${styles.createCampaign__total} `}>
              <strong>Total due:</strong>
              <div className={`right `}>
                <p>
                  <strong>${
                  updatedPricing
                  ? formartNumberAsLocalString(
                      +(updatedPricing?.pricing?.breakdown?.finalAmount ?? 0),
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }
                    )
                  :
                  formartNumberAsLocalString(amount?.totalAmount,{minimumFractionDigits: 2, maximumFractionDigits: 6})}</strong>
                </p>
              </div>
            </div>
            <div className={`${styles.createCampaign__bottomText} `}>
            <p>
                Pressing "Pay and launch" below will bill your active payment method for the price above.
              </p>
              </div>
            <div className={`${styles.createCampaign__action}`}>
              <div className={`${styles.mainCol}`}>
                <div className={`${styles.mainCol__half}`}>
                  <Button
                    label={"Go back"}
                    prefix={<ArrowLeft />}
                    className="outline xl full"
                    action={handleBack}
                  ></Button>
                </div>
                <div
                  className={`${styles.mainCol__half} ${styles.step2Button} `}
                >
                  <Button
                    className="primary xl full animated"
                    label={"Pay and Launch"}
                    action={onSubmit}
                    prefix={<Zap />}
                    disabled={insufficientCreditsLoader}
                    clicked={insufficientCreditsLoader}
                  ></Button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default CampaignInsufficientCredits;
