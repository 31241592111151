

const Pinned = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
        <path d="M7.17687 14.4165L1.52002 20.0733M10.4946 5.44199L8.93367 7.00287C8.80635 7.1302 8.74269 7.19386 8.67016 7.24444C8.60578 7.28934 8.53633 7.32651 8.46326 7.35517C8.38094 7.38746 8.29266 7.40512 8.11609 7.44043L4.45163 8.17332C3.49932 8.36378 3.02317 8.45901 2.80041 8.71007C2.60634 8.92878 2.51772 9.22147 2.55788 9.5111C2.60397 9.84355 2.94733 10.1869 3.63404 10.8736L10.7198 17.9594C11.4065 18.6461 11.7499 18.9894 12.0823 19.0355C12.3719 19.0757 12.6646 18.9871 12.8833 18.793C13.1344 18.5702 13.2296 18.0941 13.4201 17.1418L14.153 13.4773C14.1883 13.3007 14.2059 13.2125 14.2382 13.1301C14.2669 13.0571 14.3041 12.9876 14.349 12.9232C14.3995 12.8507 14.4632 12.7871 14.5905 12.6597L16.1514 11.0988C16.2328 11.0174 16.2735 10.9767 16.3183 10.9412C16.358 10.9096 16.4001 10.8811 16.4442 10.8559C16.4938 10.8276 16.5467 10.8049 16.6525 10.7596L19.1469 9.69057C19.8746 9.3787 20.2384 9.22276 20.4037 8.97078C20.5482 8.75042 20.5999 8.48192 20.5476 8.22365C20.4877 7.9283 20.2078 7.64839 19.648 7.08856L14.5048 1.94543C13.945 1.3856 13.6651 1.10569 13.3698 1.04582C13.1115 0.993461 12.843 1.04517 12.6226 1.1897C12.3706 1.35498 12.2147 1.71883 11.9028 2.44653L10.8338 4.94089C10.7885 5.0467 10.7658 5.09961 10.7375 5.14922C10.7123 5.1933 10.6838 5.23539 10.6522 5.27514C10.6167 5.31988 10.576 5.36058 10.4946 5.44199Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};

export default Pinned;