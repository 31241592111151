import { motion } from "framer-motion"
import { extracts, features } from "./constants"
import styles from "./AICallingWorkflow.module.scss";
import { Check } from "lucide-react";
import Pinned from "../../assets/icons/pinned";
import Light from "../../assets/icons/light";


const AICallingWorkflow = () => {
    return (
        <section className={`${styles.main}`}>
            <div className="wrapper-lg">
                <motion.header
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.5 }}

                >
                    <h2>How AI Calling Works</h2>
                    <p>
                        It's simple—just purchase or upload your list of leads, and OttoLeads AI does the rest.
                    </p>
                </motion.header>
                {/* Features Grid */}
                <div className={`${styles.main__feature} dflex`}>
                    {features.map((feature, index) => (
                        <motion.div
                            key={index}
                            initial={{ opacity: 0, y: 20 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true }}
                            transition={{ duration: 0.5, delay: index * 0.1 }}
                            className={`${styles.main__featureCard}`}
                        >

                            <figure className="flex alignCenter justifyCenter">
                                <feature.icon />
                            </figure>
                            <h3>{feature.title}</h3>
                            <p>{feature.description}</p>
                        </motion.div>
                    ))}
                </div>
                <div className={`${styles.main__extracts}`}>
                    <h2 className="flex alignCenter"><Pinned/> Our Smart AI Engine Extracts:</h2>
                    <ul className="dflex">
                        {extracts.map((extracts, index) => (
                            <motion.li
                                key={index}
                                initial={{ opacity: 0, y: 20 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                viewport={{ once: true }}
                                transition={{ duration: 0.5, delay: index * 0.1 }}

                            >
                                <span className="flex justifyCenter alignCenter">
                                    <Check />
                                </span>
                                {extracts}
                            </motion.li>
                        ))}
                    </ul>
                    <p className="flex alignCenter justifyCenter"> <Light /> AI doesn't just dial—it delivers qualified leads straight to you.</p>
                </div>
            </div>
        </section>
    )
}

export default AICallingWorkflow;
