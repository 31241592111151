import { useEffect, useState } from "react";
import Discover from "../../components/discover/Discover";
import PricingSec from "../../components/pricingSec/PricingSec";
import styles from "./Pricing.module.scss";
import HappyCustomerSec from "../home/happyCustomerSec/HappyCustomerSec";
import Register from "../../organisms/auth/Register";

const Pricing = () => {
    const [openRegisterModal, setOpenRegisterModal] = useState<boolean>(false);
    const [email, setEmail] = useState<string>("");

    const handleSignUp = () => {
        setOpenRegisterModal(!openRegisterModal);
    };
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className={` ${styles.pricing}`}>
            <PricingSec handleSignUp={handleSignUp} />
            <Discover />
            <HappyCustomerSec />
            {openRegisterModal ? (
                <Register
                    open={openRegisterModal}
                    setOpen={setOpenRegisterModal}
                    openLogin={true}
                    email={email}
                ></Register>
            ) : (
                ""
            )}
        </div>
    );
};

export default Pricing;
