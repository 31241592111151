


const Buyer = () => {
    return (
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="15" viewBox="0 0 20 15" fill="none">
<path d="M11.6666 4.70003H9.58329C8.89294 4.70003 8.33329 5.25968 8.33329 5.95003C8.33329 6.64039 8.89294 7.20003 9.58329 7.20003H10.4166C11.107 7.20003 11.6666 7.75968 11.6666 8.45003C11.6666 9.14039 11.107 9.70003 10.4166 9.70003H8.33329M9.99996 3.8667V4.70003M9.99996 9.70003V10.5334M15 7.20003H15.0083M4.99996 7.20003H5.00829M1.66663 4.03337L1.66663 10.3667C1.66663 11.3001 1.66663 11.7668 1.84828 12.1233C2.00807 12.437 2.26304 12.6919 2.57664 12.8517C2.93316 13.0334 3.39987 13.0334 4.33329 13.0334L15.6666 13.0334C16.6 13.0334 17.0668 13.0334 17.4233 12.8517C17.7369 12.6919 17.9918 12.437 18.1516 12.1234C18.3333 11.7668 18.3333 11.3001 18.3333 10.3667V4.03337C18.3333 3.09995 18.3333 2.63324 18.1516 2.27672C17.9919 1.96311 17.7369 1.70815 17.4233 1.54836C17.0668 1.3667 16.6 1.3667 15.6666 1.3667L4.33329 1.3667C3.39987 1.3667 2.93316 1.3667 2.57664 1.54835C2.26304 1.70814 2.00807 1.96311 1.84828 2.27671C1.66663 2.63323 1.66663 3.09994 1.66663 4.03337ZM15.4166 7.20003C15.4166 7.43015 15.2301 7.6167 15 7.6167C14.7698 7.6167 14.5833 7.43015 14.5833 7.20003C14.5833 6.96991 14.7698 6.78337 15 6.78337C15.2301 6.78337 15.4166 6.96991 15.4166 7.20003ZM5.41663 7.20003C5.41663 7.43015 5.23008 7.6167 4.99996 7.6167C4.76984 7.6167 4.58329 7.43015 4.58329 7.20003C4.58329 6.96991 4.76984 6.78337 4.99996 6.78337C5.23008 6.78337 5.41663 6.96991 5.41663 7.20003Z" 
stroke="currentColor" 
strokeWidth="2" 
strokeLinecap="round" 
strokeLinejoin="round"/>
</svg>

    );
};

export default Buyer;