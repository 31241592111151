const ClockRewind = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="21" viewBox="0 0 22 21" fill="none">
                <path d="M20.7 11.7L18.7005 9.69995L16.7 11.7M19 10.2C19 15.1705 14.9706 19.2 10 19.2C5.02944 19.2 1 15.1705 1 10.2C1 5.22939 5.02944 1.19995 10 1.19995C13.3019 1.19995 16.1885 2.97809 17.7545 5.62904M10 5.19995V10.2L13 12.2"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round" />
            </svg>
        </>
    );
};

export default ClockRewind;
