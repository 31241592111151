
const CallDownIcon = () => {
    return (
<svg xmlns="http://www.w3.org/2000/svg" width="22" height="20" viewBox="0 0 22 20" fill="none">
<path d="M3.85864 4C5.67357 2.14864 8.20268 1 11.0001 1C13.7975 1 16.3266 2.14864 18.1415 4M15.4723 7C14.3736 5.7725 12.777 5 11 5C9.22305 5 7.62647 5.7725 6.52783 7M11 15C12.5105 15 13.9608 15.2576 15.3094 15.7313C15.3542 15.747 15.3767 15.7549 15.412 15.7705C15.7326 15.9118 15.9788 16.2591 16.0058 16.6084C16.0088 16.647 16.0088 16.6811 16.0088 16.7494C16.0088 16.9821 16.0088 17.0985 16.0185 17.1965C16.1122 18.1457 16.8631 18.8966 17.8123 18.9903C17.9103 19 18.0267 19 18.2594 19H18.5044C18.965 19 19.1952 19 19.3868 18.9622C20.1829 18.8053 20.8053 18.1829 20.9622 17.3868C21 17.1952 21 16.965 21 16.5044V16.3062C21 15.831 21 15.5933 20.9493 15.3209C20.8358 14.7119 20.3933 13.9583 19.9166 13.5624C19.7035 13.3854 19.5589 13.3048 19.2698 13.1435C16.822 11.7781 14.0019 11 11 11C7.99812 11 5.17797 11.7781 2.73021 13.1435C2.4411 13.3048 2.29654 13.3854 2.0834 13.5624C1.60675 13.9583 1.16421 14.7119 1.05074 15.3209C1 15.5933 1 15.831 1 16.3062V16.5044C1 16.965 1 17.1952 1.03776 17.3868C1.19469 18.1829 1.81709 18.8053 2.61321 18.9622C2.80476 19 3.03504 19 3.4956 19H3.74057C3.97332 19 4.0897 19 4.18773 18.9903C5.13689 18.8966 5.8878 18.1457 5.98152 17.1965C5.9912 17.0985 5.9912 16.9821 5.9912 16.7494C5.9912 16.6811 5.9912 16.647 5.99418 16.6084C6.02122 16.2591 6.2674 15.9118 6.58798 15.7705C6.62335 15.7549 6.64577 15.747 6.69061 15.7313C8.03921 15.2576 9.48952 15 11 15Z" 
stroke="currentColor" 
strokeWidth="2" 
strokeLinecap="round" 
strokeLinejoin="round"/>
</svg>
    );
};

export default CallDownIcon;



