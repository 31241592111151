
import alex from "../../assets/images/alex.jpeg";
import jessica from "../../assets/images/jessica.jpeg";
import { renderCheckWithText, renderWithText } from "../aiCallingTable/AICallingTable";
  export const testimonial = [
    {
      icon: alex,
      title: "Alex P.",
      description: '"Partnering with OttoLeads VAs has transformed our lead generation process. Their professionalism and expertise have led to higher conversion rates and more closed deals."',
      profile:"Real Estate Investor"
    },
    {
        icon: jessica,
        title: "Jessica R.",
        description: '"The integration of OttoLeads VAs into our workflow was seamless. We\'ve saved time and resources while reaching more potential sellers."',
        profile:"Real Estate Agent"
      },
    
  ]

export const tableData = {
        headers: ["Feature", "OttoLeads VAs", "Other VA Providers"],
        rows: [
          ["Seamless Integration",renderCheckWithText("Works within your Ottoleads account"),renderWithText( "Separate systems & logins") ],
          ["No Hidden Costs", renderCheckWithText("Transparent pricing"), renderWithText("Additional fees for training & management")],
          ["Dedicated Support", renderCheckWithText("Team committed to your success"), renderWithText("Limited support options")],
          ["Real Estate Expertise", renderCheckWithText("Specialized in real estate outreach"), renderWithText("General VA services")],
          ["Proven Track Record",renderCheckWithText( "Higher winning conversion rates"),renderWithText( "Inconsistent results")],
        ],
      };