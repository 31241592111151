import { Check } from "lucide-react";

export const title="Find off-market deals & connect with sellers faster";
export const headingOne=`Work Smarter.<br/> Track Leads.<br/> <span>Close More Deals.</span>`
export const headingTwo=`If you're still juggling spreadsheets, sticky notes, and disconnected tools, you're leaving deals on the table. OttoLeads CRM is designed exclusively for real estate professionals to help you manage your leads and close more deals.`
export const features=[{
    title:"Log call attempts & follow-ups without messy spreadsheets.",
    icon:Check
},
{
    title:"Track every seller interaction with notes and lead statuses.",
    icon:Check
},
{
    title:"Seamlessly integrated with skip tracing, expert VAs, & AI calling.",
    icon:Check
}

]  