import { NavLink, useLocation } from "react-router-dom";
import XTwitter from "../../assets/icons/xTwitter";
import styles from "./Footer.module.scss";
import LinkedIn from "../../assets/icons/linkedIn";
import Facebook from "../../assets/icons/facebook";
import Instagram from "../../assets/icons/instagram";
import Button from "../../atoms/Button";
import YoutubeIcon from "../../assets/icons/youtube";
import SwLogo from "../../assets/images/sw-logo.svg";
import TickTock from "../../assets/icons/ticktock";
import GooglePlus from "../../assets/icons/gplus";
import { useState } from "react";
import LearnMore from "../../pages/learnmore/LearnMore";
import Register from "../../organisms/auth/Register";
import { DEFAULT_MESSAGE, DefaultHeader, HeaderMessage, MESSAGES, socialShareMessage } from "./constants";
const Footer = () => {
  const isHomePage = !localStorage.getItem("accessToken");
  const { pathname } = useLocation();
  const messageKey = Object.keys(MESSAGES).find((key) =>
    pathname.includes(key)
  );
  const message = messageKey ? `${MESSAGES[messageKey]}` : DEFAULT_MESSAGE;
  const footerHeading = messageKey ? `${HeaderMessage[messageKey]}` : DefaultHeader;
  const [learnMoreModal, setLearnMoreModal] = useState(false);
  const [email, setEmail] = useState<string>("");
  const [openRegisterModal, setOpenRegisterModal] = useState<boolean>(false);

  const excludedPaths = [
    "/verification",
    "/verify-email",
    "/forgot-password",
    "/property-search",
  ];

  const handleClickTawkLauncher = () => {
    if (window.Tawk_API) {
      window.Tawk_API.maximize();
    }
  };

  return excludedPaths.every((path) => !pathname.includes(path)) ? (
    <div
      className={`${styles.footer} ${isHomePage ? styles.footerSpace : ``} `}
    >
      <div
        className={`${styles.footer__inner} ${
          !isHomePage ? styles.footerWhite : ``
        }`}
      >
        {isHomePage ? (
          <div className="wrapper-lg">
            <div
              className={`${styles.footer__trial} flex alignEnd justifySpaceBetween`}
            >
              <div className={`${styles.footer__trialInfo} `}>

             
          <h2>{footerHeading}</h2>
                <p>{message}</p>
              </div>
              <div className={`flex ${styles.footer__trialButton}`}>
                <Button
                  label="Learn More"
                  className="outline"
                  action={() => setLearnMoreModal(true)}
                />

                <Button
                  label={pathname !== "/" ? "7 Days Free Trial"  :"Get Started"}
                  className="primary"
                  action={() => setOpenRegisterModal(true)}
                />
              </div>
            </div>
            <div className={`${styles.footer__nav}  dflex `}>
              <div className={`${styles.footer__navCol} `}>
                <h5>Solutions</h5>
                <ul>
                  {/* <li>
                    <NavLink to="/" role="link">
                      Overview
                    </NavLink>
                  </li> */}
                  <li>
                    <NavLink to="/nationwide-data" role="link">
                      Nationwide Property Data
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/ai-calling" role="link">
                      AI Calling
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/virtual-assistant" role="link">
                      VA Calling
                    </NavLink>
                  </li>
                  
                  <li>
                    <NavLink to="/integrated-crm" role="link">
                      Integrated CRM
                    </NavLink>
                  </li>
                </ul>
              </div>
              <div className={`${styles.footer__navCol} `}>
                <h5>Resources</h5>
                <ul>
                  {/* <li>
                    <NavLink to="/" role="link">
                      About Us
                    </NavLink>
                  </li>*/}
                  <li>
                    <NavLink to="/pricing" role="link">
                      Pricing
                    </NavLink>
                  </li> 
                  <li>
                    <NavLink to="/creative-calculator" role="link">
                      Creative Calculator
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/satisfaction-guarantee" role="link">
                      Satisfaction Guarantee
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      target="_blank"
                      to="https://ottoleads.getrewardful.com/signup"
                      role="link"
                    >
                      Affiliates
                    </NavLink>
                  </li>
                  <li onClick={handleClickTawkLauncher}>Contact us</li>
                </ul>
              </div>
              <div className={`${styles.footer__navCol} `}>
                <h5>Legal</h5>
                <ul>
                 
                 
                  <li>
                    <NavLink to="/policies/terms-of-use" role="link">
                      Terms of Use
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/policies/privacy-policy" role="link">
                      Privacy Policy
                    </NavLink>
                  </li>
                  
                </ul>
              </div>
            </div>
            <div
              className={`${styles.footer__copyright} dflex justifySpaceBetween `}
            >
              <p className={`dflex alignCenter ${styles.copyrightMobile}`}>
                &copy; OttoLeads, 2025 - All rights reserved. Development in
                partnership with{" "}
                <a href="https://strategywerks.com/" target="_blank">
                  <img
                    src={SwLogo}
                    alt="strategywerks.com"
                    title="strategywerks.com"
                  />
                </a>
              </p>
              <ul className="dflex alignCenter">
                <li>
                  <a
                    href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(
                      socialShareMessage
                    )}`}
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                  >
                    <XTwitter />
                  </a>
                </li>
                <li>
                  <a
                    href={`#`}
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                  >
                    <TickTock />
                  </a>
                </li>
                <li>
                  <a
                    href={`#`}
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                  >
                    <GooglePlus />
                  </a>
                </li>
                <li>
                  <a
                    href={`https://www.linkedin.com/sharing/share-offsite/?url=https://ottoleads.ai/`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <LinkedIn />
                  </a>
                </li>
                <li>
                  <a
                    href={`https://www.facebook.com/sharer.php?u=https://ottoleads.ai/`}
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                  >
                    <Facebook />
                  </a>
                </li>
                <li>
                  <a
                    href={`https://www.instagram.com/ottoleads/`}
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                  >
                    <Instagram />
                  </a>
                </li>
                <li>
                  <a
                    href={`https://www.youtube.com/@OttoLeads`}
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                  >
                    <YoutubeIcon />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        ) : (
          <div
            className={`${styles.footer__dashboard} dflex  justifySpaceBetween`}
          >
            <p className={`${styles.copyrightMobile}`}>
              &copy; OttoLeads, 2025 - All rights reserved.
            </p>
            <ul className="dflex alignCenter">
              <li>
                <NavLink to="/creative-calculator" role="link">
                  Creative Calculator
                </NavLink>
              </li>
              <li>
                <NavLink
                  target="_blank"
                  to="https://ottoleads.getrewardful.com/signup"
                  role="link"
                >
                  Affiliates
                </NavLink>
              </li>
              <li onClick={handleClickTawkLauncher}>Contact Us</li>

              <li>
                <NavLink to="/policies/terms-of-use" role="link">
                  Terms
                </NavLink>
              </li>
              <li>
                <NavLink to="/policies/privacy-policy" role="link">
                  Privacy
                </NavLink>
              </li>
              <li>
                <NavLink to="/policies/cookies" role="link">
                  Cookies
                </NavLink>
              </li>
            </ul>
          </div>
        )}
      </div>
      {learnMoreModal ? (
        <LearnMore
          open={learnMoreModal}
          handleClose={() => setLearnMoreModal(false)}
        />
      ) : (
        ""
      )}
      {openRegisterModal ? (
        <Register
          open={openRegisterModal}
          setOpen={setOpenRegisterModal}
          openLogin={true}
          email={email}
        ></Register>
      ) : (
        ""
      )}
    </div>
  ) : (
    <></>
  );
};

export default Footer;
