
const ZapFast = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="21" viewBox="0 0 23 21" fill="none">
        <path d="M8 15.8999H2.5M5.5 10.3999H1M8 4.8999H3M16 1.3999L9.40357 10.6349C9.1116 11.0437 8.96562 11.248 8.97194 11.4184C8.97744 11.5668 9.04858 11.705 9.1661 11.7957C9.30108 11.8999 9.55224 11.8999 10.0546 11.8999H15L14 19.3999L20.5964 10.1649C20.8884 9.75614 21.0344 9.55177 21.0281 9.38137C21.0226 9.23302 20.9514 9.09479 20.8339 9.00408C20.6989 8.8999 20.4478 8.8999 19.9454 8.8999H15L16 1.3999Z" 
        stroke="currentColor" 
        strokeWidth="2" 
        strokeLinecap="round" 
        strokeLinejoin="round"/>
        </svg>
    );
  };
  
  export default ZapFast;
  