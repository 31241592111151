import { motion } from "framer-motion";
import Button from "../../atoms/Button";
import styles from "./WhyChooseSection.module.scss";
import {
  benefits,
  heading,
  title,
} from "./constant";

const WhyChooseSection = ({ handleSignUp,testimonialMessage,testimonialName,testimonialTitle ,bottomText, imageUrl}: any) => {
  return (
    <section className={`${styles.choose}`}>
      <div className="wrapper-lg">
        <div className={`${styles.choose__wrapper}`}>
          <motion.header
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
          >
            <h2>{title}</h2>
            <p>{heading}</p>
          </motion.header>

          <div className={`${styles.choose__row} dflex`}>
            {benefits.map((benefit, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                className={`${styles.choose__box} flex`}
              >
                <figure className="flex alignCenter justifyCenter">
                  <benefit.icon />
                </figure>
                <figcaption>
                  <h3>{benefit.title}</h3>
                  <p>{benefit.description}</p>
                </figcaption>
              </motion.div>
            ))}
          </div>

          {/* Testimonial Section */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.4 }}
            className={`${styles.choose__testimonial} flex alignCenter`}
          >
            <figure>
              <img
                src={ imageUrl ? imageUrl : "https://randomuser.me/api/portraits/men/32.jpg" }
                alt="Testimonial"
                width={80}
                height={80}
                className="object-cover"
              />
            </figure>
            <figcaption>
              <p>{testimonialMessage}</p>
              <h5>{testimonialName}</h5>
              <p>{testimonialTitle}</p>
            </figcaption>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.5 }}
            className={`${styles.choose__text} `}
          >
            <p> {bottomText} </p>
            <div className="dflex justifyCenter">
              <Button
                className="primary"
                label={"Start 7 Days Free Trial"}
                action={handleSignUp}
              />
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default WhyChooseSection;
