import { Calendar, Clock4 } from "lucide-react";
import CalculatorIcon from "../../assets/icons/calculatorIcon";
import tab1 from "../../assets/images/tab-1.png";
import tab2 from "../../assets/images/tab-2.png";
import tab3 from "../../assets/images/tab-3.png";
import tab4 from "../../assets/images/tab-4.png";
  export const features = [
    {
      icon: Calendar,
      title: "Track Lead Status & Outreach",
      description: "Know exactly where each seller stands.",
      active:true,
      image:tab1
    },
    {
        icon: Clock4,
        title: "Log Call Attempts",
        description: "Get rid of clunky spreadsheets and track all of your outreach in one place.",
        active:false,
        image:tab2
      },
      {
        icon: Calendar,
        title: "Follow-Up Date Picker",
        description: "Schedule the next touch point effortlessly.",
        active:false,
        image:tab3
      },
      {
        icon: CalculatorIcon,
        title: "Profitability & Deal Analysis",
        description: "Quickly assess if a deal makes sense before making an offer.",
        active:false,
        image:tab4
      },
  ]
