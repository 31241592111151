export const socialShareMessage =
"Check out #OttoLeads, a conversational AI bot that handles ALL your real estate outreach using AI! 🤯 OttoLeads.ai";
const commonMessage= `Ottoleads is the trusted platform for real estate investors, agents, and wholesalers nationwide. 
With the most accurate property data and built-in seller outreach tools, you'll never waste time on bad leads again.`
export const MESSAGES: Record<string, string> = {
    "/nationwide-data": commonMessage,
    "/pricing": commonMessage,
     "/ai-calling": `Ottoleads allows you to do more deals without hiring a team. AI handles your calls, 
                     so you scale effortlessly - without spending hours dialing leads yourself. More features, more data, and better results than any other platform.`,
  "/virtual-assistant": ` OttoLeads Virtual Assistants handle your initial outreach, pre-qualify motivated sellers,
       and book appointments—so you only talk to serious leads. Fully trained in real estate, our VAs eliminate the
        hassle of hiring, training, and managing a team. Scale your lead generation affordably and focus on closing 
        more deals`,
        "/integrated-crm":`OttoLeads CRM is built for real estate professionals who want to move fast. Manage every lead in
         one place, tap into nationwide skip tracing and AI calling, and get support from trained virtual assistants—so 
         nothing slips through the cracks and every opportunity turns into a deal.`
};

export const DEFAULT_MESSAGE = `Ottoleads allows you to do more, without having multiple systems. 
                          Get access to features that actually help you close more deals, 
                          access the most accurate data, and automate your outreach better than any other solution.`;

export const DefaultHeader =`Start Your 7 Days Free Trial`

export const HeaderMessage: Record<string, string> = {
  "/nationwide-data": `Start Finding Deals Today!`,
  "/ai-calling": `Start Finding Deals Today!`,
  "/virtual-assistant": `Start Your Journey with OttoLeads VAs Today`,
  "/pricing": `Start Finding Deals Today!`,
  "/integrated-crm":`Close More Deals with OttoLeads CRM Today`
};
