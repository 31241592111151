const Bar = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                <path d="M6 13.2V15.2M10 9.19995V15.2M14 5.19995V15.2M5.8 19.2H14.2C15.8802 19.2 16.7202 19.2 17.362 18.873C17.9265 18.5854 18.3854 18.1264 18.673 17.5619C19 16.9202 19 16.0801 19 14.4V5.99995C19 4.31979 19 3.47971 18.673 2.83798C18.3854 2.27349 17.9265 1.81455 17.362 1.52693C16.7202 1.19995 15.8802 1.19995 14.2 1.19995H5.8C4.11984 1.19995 3.27976 1.19995 2.63803 1.52693C2.07354 1.81455 1.6146 2.27349 1.32698 2.83798C1 3.47971 1 4.31979 1 5.99995V14.4C1 16.0801 1 16.9202 1.32698 17.5619C1.6146 18.1264 2.07354 18.5854 2.63803 18.873C3.27976 19.2 4.11984 19.2 5.8 19.2Z"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round" />
            </svg>

        </>
    );
};

export default Bar;