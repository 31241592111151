import { useState, useEffect, useRef } from "react"
// import Image from "next/image"
import { ChevronLeft, ChevronRight } from "lucide-react"
import { motion } from "framer-motion"
import styles from "./HomePropertyCard.module.scss";

interface PropertyCardProps {
    images: string[]
    price: string
    beds: number
    baths: number
    sqft: number
    year: number
    address: string
    city: string
    state: string
    zip: string
    lastSale: string
    soldPrice: string
    pillType?: string
    pillTypeStyle?: string
}

export function HomePropertyCard({
    images,
    price,
    beds,
    baths,
    sqft,
    year,
    address,
    city,
    state,
    zip,
    lastSale,
    soldPrice,
    pillType="pre-foreclosure",
    pillTypeStyle="status1"
}: PropertyCardProps) {
    const [currentImageIndex, setCurrentImageIndex] = useState(0)
    const [hasInteracted, setHasInteracted] = useState(false)
    const [isHovering, setIsHovering] = useState(false)
    const intervalRef = useRef<NodeJS.Timeout | null>(null)

    // Start auto-sliding when hovering
    useEffect(() => {
        if (isHovering) {
            intervalRef.current = setInterval(() => {
                setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length)
            }, 2000) // Change image every 2 seconds
        }

        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current)
                intervalRef.current = null
            }
        }
    }, [isHovering, images.length])

    // Reset to first image and clear interval when hover ends
    const handleMouseLeave = () => {
        setIsHovering(false)
        if (intervalRef.current) {
            clearInterval(intervalRef.current)
            intervalRef.current = null
        }
    }

    const nextImage = () => {
        setHasInteracted(true)
        if (intervalRef.current) {
            clearInterval(intervalRef.current)
            intervalRef.current = null
        }
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length)
    }

    const prevImage = () => {
        setHasInteracted(true)
        if (intervalRef.current) {
            clearInterval(intervalRef.current)
            intervalRef.current = null
        }
        setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length)
    }

    return (
        <motion.div
            className={` ${styles.card}`}
           // whileHover={{ scale: 1.02 }}
            transition={{ duration: 0.5 }}
            onMouseEnter={() => {
                setIsHovering(true)
                setHasInteracted(false)
            }}
            onMouseLeave={handleMouseLeave}
        >
            {/* Glowing gradient effect */}
            <div
                className={` ${styles.card__animate}`}
            >
                <div className={` ${styles.card__animateInner}`}></div>
                <div className={` ${styles.card__animateBg}`}></div>
            </div>
            <div
                className={` ${styles.card__content}`}
            >
                <div className={` ${styles.card__img}`}>
                <div className={` ${styles.card__tag} ${styles[pillTypeStyle]}`}> {pillType}
                </div>
                    <img
                        src={images[currentImageIndex] || "/placeholder.svg"}
                        alt={`${address}, ${city}, ${state} ${zip}`}
                        onClick={(e) => {
                            e.stopPropagation()
                            nextImage()
                        }}
                    />
                    <div className={` ${styles.card__imgArrows}`}>
                        <button
                            onClick={prevImage}
                            className={`${styles.card__imgArrowLeft}`}
                        >
                            <ChevronLeft />
                        </button>
                        <button
                            onClick={nextImage}
                            className={`${styles.card__imgArrowRight} ${!hasInteracted && "group-hover:animate-pulse"
                                }`}
                        >
                            <ChevronRight />
                        </button>
                    </div>
                    {/* Image dots indicator */}
                    <div className={` ${styles.card__imgDots}`}>
                        {images.map((_, index) => (
                            <div
                                key={index}
                                className={` ${styles.card__imgDot} ${index === currentImageIndex ? styles.active : ""
                                    }`}

                            />
                        ))}
                    </div>
                </div>
                <div className={` ${styles.card__info}`}>
                    <h5>{price} <span>est. value</span></h5>
                    <p> <span><strong>{beds}</strong> bds</span> |  <span><strong>{sqft.toLocaleString()}</strong> sqft</span> | <span> <strong>{year}</strong>  built</span></p>
                    <address>
                        {address}
                    </address>
                    <div className={`flex justifySpaceBetween ${styles.card__bottom}`}>
                        <span>Last Sale: {lastSale}</span>
                        <span>Sold price: {soldPrice}</span>
                    </div>
                </div>
            </div>
        </motion.div>
    )
}

