
import {  Users, Home, DollarSign, FileText, Globe, ZoomIn, TriangleAlert, Frown } from "lucide-react"
import ClockRewind from "../../assets/icons/clockRewind"
import Bar from "../../assets/icons/bar"
import Building from "../../assets/icons/building"
import BookMark from "../../assets/icons/bookmark"
import OffMarket from "../../assets/icons/offMarket"
import VHome from "../../assets/icons/vacentHome"
import Buyer from "../../assets/icons/buyer"
import HeartHand from "../../assets/icons/heartHand"
export const stats = [
    // Row 1
    [
      { number: 155000000, label: "Properties", icon: Home, suffix: "+" },
      { number: 110000000, label: "Residential", icon: Home },
      { number: 4200000, label: "Commercial", icon: Building },
      { number: 770000, label: "Active Listings", icon: BookMark },
    ],
    // Row 2
    [
      { number: 121000000, label: "Off-market", icon: OffMarket , suffix: "+" },
      { number: 2000000, label: "Vacant", icon: VHome , suffix: "+"  },
      { number: 127000, label: "Pre-foreclosures", icon: TriangleAlert  },
      { number: 96000000, label: "High Equity", icon: DollarSign },
    ],
    // Row 3
    [
      { number: 37000000, label: "Absentee Owners", icon: Users , suffix: "+"  },
      { number: 2000000, label: "Tired Landlords", icon: Frown },
      { number: 28000000, label: "Cash Buyers", icon: Buyer },
      { number: 294000, label: "Inherited", icon: HeartHand },
    ],
  ]

 export const features = [
    {
      icon: Globe,
      title: "98% U.S. Coverage",
      description: "Property data from 155+ million residential and commercial properties.",
    },
    {
      icon: Users,
      title: "Verified Owner Contact Info",
      description: "Phone numbers, mailing addresses, and LLC details to connect directly.",
    },
    {
      icon: FileText,
      title: "Mortgage & Lien Data",
      description: "See outstanding loans, tax liens, pre-foreclosures, and distressed properties in seconds.",
    },
    {
      icon: ClockRewind,
      title: "MLS Listings & History",
      description: "Track past sales, market trends, and real-time MLS updates.",
    },
    {
      icon: ZoomIn,
      title: "Property Insights",
      description: "Go beyond the basics with deep property insights.",
    },
    {
      icon: Bar,
      title: "Market Analysis",
      description: "Analyze neighborhood data and market trends for smarter investments.",
    },
  ]