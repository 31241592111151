
import { CircleCheckBig, FileText, Phone} from "lucide-react"
import CalculatorIcon from "../../assets/icons/calculatorIcon"


  export const choose = [
    {
      icon: CircleCheckBig,
      title: "Lead Status & Call Attempt Logging",
      description: "Never wonder how many times a seller has been contacted.",
    },
    {
      icon: Phone,
      title: "Calendar & Follow-Up Management",
      description: "Set reminders, add notes, and track progress effortlessly.",
    },
    {
      icon: FileText,
      title: "Skip Tracing & AI Calling Integration",
      description: "Find, call, and manage leads—all within OttoLeads.",
    },
    {
        icon: CalculatorIcon,
        title: "Creative Finance & Deal Analysis Calculators",
        description: "Structure better offers with built-in tools.",
      },
  ]