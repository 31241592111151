import CsvDownloader from "react-csv-downloader";
import Cloud from "../assets/icons/cloud";
import Loader from "./Loader";
import DocumentAdd from "../assets/icons/docAdd";
const ExportToCsv = ({
  data,
  headers,
  label,
  filename,
  handleChange,
  loadData,
  loading,
  modalButton,
  gradientBtn,
}: any) => {
  return (
    <CsvDownloader
      className={`button ${gradientBtn?'outline':'outline'} ${modalButton?'primary animated':'outline'} ${label?'':'icon'} ${loading && 'loadingBtn'}`}
      filename={filename}
      datas={loadData}
      columns={headers}
      disabled={loading}
    >
      {loading ? <Loader size={20} /> : <DocumentAdd />} {label}
    </CsvDownloader>
  );
};

export default ExportToCsv;
