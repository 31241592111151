

const BookMark = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 14 18" fill="none">
                <path d="M4.50008 7.94995L6.16675 9.61662L9.91675 5.86662M12.8334 16.7V5.69995C12.8334 4.29982 12.8334 3.59975 12.5609 3.06497C12.3212 2.59457 11.9388 2.21212 11.4684 1.97243C10.9336 1.69995 10.2335 1.69995 8.83341 1.69995H5.16675C3.76662 1.69995 3.06655 1.69995 2.53177 1.97243C2.06137 2.21212 1.67892 2.59457 1.43923 3.06497C1.16675 3.59975 1.16675 4.29982 1.16675 5.69995V16.7L7.00008 13.3666L12.8334 16.7Z"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round" />
            </svg>

        </>
    );
};

export default BookMark;

