import { useRef } from "react"
import { motion, useInView } from "framer-motion"
import { useSpring, animated } from "@react-spring/web"
import { features, stats } from "./constants"
import styles from "./ComprehensiveDataSection.module.scss";

function AnimatedStat({
    number,
    label,
    icon: Icon,
    suffix = "",
    delay = 0,
}: {
    number: number
    label: string
    icon: any
    suffix?: string
    delay?: number
}) {
    const ref = useRef(null)
    const isInView = useInView(ref, { once: true })

    const { value } = useSpring({
        from: { value: 0 },
        value: isInView ? number : 0,
        delay: 300 + delay,
        config: { mass: 1, tension: 20, friction: 10 },
    })

    return (
        <motion.div 
            ref={ref}
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5, delay: delay / 1000 }}
            className={`${styles.main__panelCard}`}

        >
            <div className={`${styles.main__panelCardBox} flex alignCenter`}>
                <figure className="flex alignCenter justifyCenter">
                    <Icon />
                </figure>
                <div>
                    <figcaption className="flex alignCenter">
                        <animated.h5>
                            {value.to((n) => {
                                if (n >= 1000000) return `${(n / 1000000).toFixed(0)}M`
                                if (n >= 1000) return `${(n / 1000).toFixed(0)}K`
                                return n.toFixed(0)
                            })}
                        </animated.h5>
                        {suffix && <span>{suffix}</span>}
                    </figcaption>
                    <p>{label}</p>
                </div>
            </div>
        </motion.div>
    )
}

const ComprehensiveDataSection = () => {
    return (
        <section className={`${styles.main}`}>
            <div className="wrapper-lg">
                <motion.header
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.5 }}

                >
                    <h2>Comprehensive Nationwide Data</h2>
                    <p>
                        Most real estate data platforms give you the basics. OttoLeads gives you everything you need to take
                        action and close deals.
                    </p>
                </motion.header>
                   {/* Features Grid */}
                   <div className={`${styles.main__feature} dflex`}>
                    {features.map((feature, index) => (
                        <motion.div
                            key={index}
                            initial={{ opacity: 0, y: 20 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true }}
                            transition={{ duration: 0.5, delay: index * 0.1 }}
                            className={`${styles.main__featureCard}`}
                        >
                          
                                <figure className="flex alignCenter justifyCenter">
                                    <feature.icon />
                                </figure>
                                <h3>{feature.title}</h3>
                                <p>{feature.description}</p>
                        </motion.div>
                    ))}
                </div>
                <div className={`${styles.main__panel}`}>

                    {stats.map((row, rowIndex) => (
                        <div key={rowIndex} className={`${styles.main__panelRow} dflex`}>
                            {row.map((stat, index) => (
                                <AnimatedStat key={index} {...stat} delay={(rowIndex * 4 + index) * 100} />
                            ))}
                        </div>
                    ))}

                </div>

            

            </div>
        </section>
    )
}

export default ComprehensiveDataSection;
