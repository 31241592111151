const DocumentAdd = () => {
    return (
        <>
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="20" viewBox="0 0 17 20" fill="none">
<path d="M14.6668 10.4165V5.6665C14.6668 4.26637 14.6668 3.56631 14.3943 3.03153C14.1547 2.56112 13.7722 2.17867 13.3018 1.93899C12.767 1.6665 12.067 1.6665 10.6668 1.6665H5.3335C3.93336 1.6665 3.2333 1.6665 2.69852 1.93899C2.22811 2.17867 1.84566 2.56112 1.60598 3.03153C1.3335 3.56631 1.3335 4.26637 1.3335 5.6665V14.3332C1.3335 15.7333 1.3335 16.4334 1.60598 16.9681C1.84566 17.4386 2.22811 17.821 2.69852 18.0607C3.2333 18.3332 3.93333 18.3332 5.33338 18.3332H8.41683M9.66683 9.1665H4.66683M6.3335 12.4998H4.66683M11.3335 5.83317H4.66683M10.5002 15.8332L13.0002 18.3332M13.0002 18.3332L15.5002 15.8332M13.0002 18.3332V13.3332" 
stroke="currentColor" 
strokeWidth="1.66667" 
strokeLinecap="round" 
strokeLinejoin="round"/>
</svg>
        </>
    );
};

export default DocumentAdd;