
import alex from "../../assets/images/alex.jpeg";
import jessica from "../../assets/images/jessica.jpeg";
import { renderCheckWithText, renderWrongWithText } from "../aiCallingTable/AICallingTable";
  export const testimonial = [
    {
      icon: alex,
      title: "Alex P.",
      description: '"Partnering with OttoLeads VAs has transformed our lead generation process. Their professionalism and expertise have led to higher conversion rates and more closed deals."',
      profile:"Real Estate Investor"
    },
    {
        icon: jessica,
        title: "Jessica R.",
        description: '"The integration of OttoLeads VAs into our workflow was seamless. We\'ve saved time and resources while reaching more potential sellers."',
        profile:"Real Estate Agent"
      },
    
  ]

export const tableData = {
        headers: ["Feature", "OttoLeads VAs", "Generic CRMs"],
        rows: [
          ["Built for Real Estate Investors & Wholesalers",renderCheckWithText(""),renderWrongWithText( "") ],
          ["Lead Status & Follow-Up Tracking", renderCheckWithText(""), renderWrongWithText( "")],
          ["Skip Tracing & AI Calling Integration", renderCheckWithText(""), renderWrongWithText( "")],
          ["Call Logging & Attempt Tracking", renderCheckWithText(""), renderWrongWithText("")],
          ["Creative Finance & Deal Analysis Calculators",renderCheckWithText(""),renderWrongWithText("")],
        ],
      };