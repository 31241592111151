const DollarNote = () => {
    return (
        <>
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="21" viewBox="0 0 30 21" fill="none">
<path d="M17.6665 6.59993H14.3332C13.2286 6.59993 12.3332 7.49536 12.3332 8.59993C12.3332 9.7045 13.2286 10.5999 14.3332 10.5999H15.6665C16.7711 10.5999 17.6665 11.4954 17.6665 12.5999C17.6665 13.7045 16.7711 14.5999 15.6665 14.5999H12.3332M14.9998 5.2666V6.59993M14.9998 14.5999V15.9333M22.9998 10.5999H23.0132M6.99984 10.5999H7.01317M1.6665 5.53327L1.6665 15.6666C1.6665 17.1601 1.6665 17.9068 1.95715 18.4772C2.21282 18.979 2.62076 19.387 3.12253 19.6426C3.69296 19.9333 4.4397 19.9333 5.93317 19.9333L24.0665 19.9333C25.56 19.9333 26.3067 19.9333 26.8771 19.6426C27.3789 19.387 27.7869 18.979 28.0425 18.4772C28.3332 17.9068 28.3332 17.1601 28.3332 15.6666V5.53327C28.3332 4.0398 28.3332 3.29306 28.0425 2.72263C27.7869 2.22086 27.3789 1.81292 26.8771 1.55725C26.3067 1.2666 25.56 1.2666 24.0665 1.2666L5.93317 1.2666C4.4397 1.2666 3.69296 1.2666 3.12253 1.55725C2.62076 1.81291 2.21282 2.22086 1.95715 2.72263C1.6665 3.29306 1.6665 4.03979 1.6665 5.53327ZM23.6665 10.5999C23.6665 10.9681 23.368 11.2666 22.9998 11.2666C22.6316 11.2666 22.3332 10.9681 22.3332 10.5999C22.3332 10.2317 22.6316 9.93327 22.9998 9.93327C23.368 9.93327 23.6665 10.2317 23.6665 10.5999ZM7.6665 10.5999C7.6665 10.9681 7.36803 11.2666 6.99984 11.2666C6.63165 11.2666 6.33317 10.9681 6.33317 10.5999C6.33317 10.2317 6.63165 9.93327 6.99984 9.93327C7.36803 9.93327 7.6665 10.2317 7.6665 10.5999Z" 
stroke="currentColor" 
strokeOpacity="1" 
strokeWidth="2" 
strokeLinecap="round" 
strokeLinejoin="round"/>
</svg>
        </>
    );
};

export default DollarNote;


