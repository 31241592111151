import { Check } from "lucide-react";
import React from "react";

// import CheckCircle from "../../assets/icons/checkCircle";
// import Check from "../../assets/icons/check";


const DiscoverTable = ({ columns, data, styles }:any) => {
  // Cell rendering logic
  const renderCell = (value:any, type:any) => {
    if (type === "boolean") {
      return value ? (
        <span className={`flex alignCenter justifyCenter ${styles?.discover__check}`}> <Check/></span>
      ) : (
        <span className={`flex alignCenter justifyCenter ${styles?.discover__blank}`}></span>
      );
    }
    // Fallback for other types
    return value || "-";
  };

  return (
    <div className={styles?.tableWrapper || "table-wrapper"}>
      <table >
        <thead>
          <tr>
            {columns.map((col:any) => (
              <th key={col.id} style={{ minWidth: col.minWidth }}>
                {col.displayName}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row:any, rowIndex:any) => (
            <tr key={rowIndex}>
              {columns.map((col:any) => (
                <td key={col.id} style={{ minWidth: col.minWidth }}>
                  {renderCell(row[col.id], col.type)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};



export default DiscoverTable;
