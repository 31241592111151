import Check from "../../../assets/icons/check";
import CheckCircleFill from "../../../assets/icons/CheckCircleFill";
import styles from "./HowItWork.module.scss";
import videoHome from "../../../assets/videos/nationwide-video.mp4";

const HowItWork = () => {

    return <div className={` ${styles.howItWork}`} >
        <div className="wrapper-lg">
            <header>
                <h5>How It Works</h5>
                <h2>All the Tools You Need to Close More Deals </h2>
                <p>Real estate investing is not about luck or speculation. It’s about research, due diligence, and having the correct tools needed for success.</p>
            </header>
            </div>
            <div className={`${styles.howItWork__video}`} >
       <video muted autoPlay loop playsInline width="100%" src={videoHome} />
            </div>
        
    </div>;
}
export default HowItWork;