import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import styles from "./HomeFAQ.module.scss";
import { CircleMinus, CirclePlus } from "lucide-react";
import { homeFaqSec } from "../../pages/home/HomeConstants";
import React from "react";

export default function HomeFAQ() {
    const [expanded, setExpanded] = React.useState<string | false>("panel-0");

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  return (
    <div className={` ${styles.main}`}>
      <div className={` ${styles.faq} wrapper-lg`}>
        <div className={` ${styles.faq__inner} `}>
          <header>
            <h2> Frequently asked questions </h2>
            <p>Everything you need to know about the product and billing.</p>
          </header>

          <div className={` ${styles.accordion}`}>
            {homeFaqSec.questions.map((item, index) => (
              <Accordion
              expanded={expanded === `panel-${index}`}
              onChange={handleChange(`panel-${index}`)}
             >
                <AccordionSummary
                  expandIcon={
                    <>
                      <span className="plus-icon">
                        <CirclePlus />
                      </span>{" "}
                      <span className="minus-icon">
                        <CircleMinus />
                      </span>
                    </>
                  }
                  aria-controls={`panel${index + 1}-content`}
                  id={`panel${index + 1}-header`}
                >
                  {item.question}
                </AccordionSummary>
                <AccordionDetails>
                  {item.answer}
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
