import { Phone, Bot, Zap, CalendarPlus } from "lucide-react"

export const title="AI-Powered Skip Tracing & Direct Outreach"

export const heading=`Having the right data is one thing—acting on it is another. OttoLeads makes it easy to connect with
                        property owners instantly.`

export const features = [
    {
      icon: Phone,
      title: "Skip Tracing",
      description: "Instantly pull verified owner phone numbers, mailing addresses, and LLC data.",
      personImage: "https://randomuser.me/api/portraits/women/29.jpg",
    },
    {
      icon: Bot,
      title: "AI-Powered Calling",
      description: "Automate outreach with AI-driven conversations.",
      personImage: "https://randomuser.me/api/portraits/men/32.jpg",
    },
    {
      icon: CalendarPlus,
      title: "Follow-Up Tracking",
      description: "Keep conversations organized inside the OttoLeads CRM.",
      personImage: "https://randomuser.me/api/portraits/women/68.jpg",
    },
  ]

  export const bottomText="From finding leads to closing deals, OttoLeads keeps you moving forward."