
const HeartHand = () => {
    return (
        <>
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
<path d="M5.00008 16.9392H7.17532C7.45894 16.9392 7.74082 16.973 8.01576 17.0405L10.3142 17.599C10.8129 17.7205 11.3324 17.7323 11.8363 17.6344L14.3776 17.14C15.0489 17.0093 15.6664 16.6878 16.1504 16.217L17.9483 14.468C18.4618 13.9694 18.4618 13.1603 17.9483 12.6608C17.486 12.2111 16.754 12.1605 16.231 12.5419L14.1356 14.0707C13.8355 14.29 13.4703 14.4081 13.0948 14.4081H11.0713L12.3593 14.4081C13.0853 14.4081 13.6733 13.8361 13.6733 13.1299V12.8742C13.6733 12.2879 13.2631 11.7766 12.6785 11.6348L10.6906 11.1514C10.3671 11.0729 10.0357 11.0333 9.7027 11.0333C8.89869 11.0333 7.44333 11.699 7.44333 11.699L5.00008 12.7207M1.66675 12.3666L1.66675 17.1999C1.66675 17.6667 1.66675 17.9 1.75758 18.0783C1.83747 18.2351 1.96495 18.3626 2.12176 18.4424C2.30002 18.5333 2.53337 18.5333 3.00008 18.5333H3.66675C4.13346 18.5333 4.36681 18.5333 4.54507 18.4425C4.70188 18.3626 4.82936 18.2351 4.90925 18.0783C5.00008 17.9 5.00008 17.6667 5.00008 17.1999V12.3666C5.00008 11.8999 5.00008 11.6665 4.90925 11.4883C4.82936 11.3315 4.70188 11.204 4.54507 11.1241C4.36681 11.0333 4.13346 11.0333 3.66675 11.0333H3.00008C2.53337 11.0333 2.30002 11.0333 2.12176 11.1241C1.96495 11.204 1.83747 11.3315 1.75758 11.4883C1.66675 11.6665 1.66675 11.8999 1.66675 12.3666ZM14.3262 3.19349C13.8289 2.15278 12.6822 1.60143 11.5671 2.13359C10.452 2.66575 9.97691 3.92776 10.4438 5.03563C10.7323 5.72033 11.559 7.04995 12.1485 7.9658C12.3663 8.3042 12.4752 8.4734 12.6342 8.57239C12.7707 8.65729 12.9415 8.70305 13.1021 8.69774C13.2893 8.69155 13.4682 8.59947 13.826 8.4153C14.7944 7.91687 16.1752 7.17874 16.7674 6.73005C17.7257 6.00404 17.9631 4.66959 17.2456 3.65515C16.5282 2.6407 15.2773 2.54088 14.3262 3.19349Z" 
stroke="currentColor" 
strokeWidth="2" 
strokeLinecap="round" 
strokeLinejoin="round"
/>
</svg>

        </>
    );
};

export default HeartHand;