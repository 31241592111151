import React from "react";
import { DISCOVER_COLUMNS, DISCOVER_DATA } from "./constants";
import styles from "./Discover.module.scss";
import DiscoverTable from "../../molecules/discoverTable/DiscoverTable";

const Discover = () => {
  // Prepare column configuration for DiscoverTable
  const tableColumns = DISCOVER_COLUMNS.map((col:any, index:any) => ({
    id: col.toLowerCase().replace(/\s+/g, ""),
    displayName: col,
    minWidth: 150, // Default minimum width, can be adjusted
    type: index === 0 ? "string" : "boolean", // First column is text; others are boolean
  }));

  // Transform DISCOVER_DATA to match the column IDs
  const tableData = DISCOVER_DATA.map((row:any) => ({
    feature: row.feature,
    ottoleads: row.ottoleads,
    dealmachine: row.dealMachine,
    propstream: row.propStream,
    propertyradar: row.propertyRadar,
    privy: row.privy,
    batchleads: row.batchLeads,
  }));

  return (
    <div className={styles.discover}>
      <div className="wrapper-lg">
        <header>
          <h5>Ottoleads vs Competition</h5>
          <h3>Discover How Ottoleads Measures Up to Its Rivals.</h3>
          <p>
            Unlike the competition, Ottoleads is a complete solution for real
            estate professionals. Whether you're an investor, agent, or
            wholesaler, we have all the tools you need to find, manage, and
            close deals—without relying on multiple services.
          </p>
        </header>
        <div className={styles.discover__table}>
          <DiscoverTable columns={tableColumns} data={tableData} styles={styles} />
        </div>
      </div>
    </div>
  );
};

export default Discover;
