import type React from "react"
import { useState, useEffect, useRef } from "react"
import { useScroll } from "framer-motion"
import { useNavigate } from "react-router-dom"
import WhyChooseSection from "../../organisms/whyChooseSection/WhyChooseSection"
import Register from "../../organisms/auth/Register"
import { bottomText, imageUrl, tableData, tableTwoData, testimonialMessage, testimonialName, testimonialTitle } from "./constants"
import AICallingWorkflow from "../../components/aICallingWorkflow/AICallingWorkflow"
import AIBestChoice from "../../components/aIBestChoice/AIBestChoice"
import AIHero from "../../components/aIHero/AIHero"

const AICallingPage = () => {
    const navigate = useNavigate();
    const [openRegisterModal, setOpenRegisterModal] = useState<boolean>(false);
    const [email, setEmail] = useState<string>("");
    const handleSignUp = () => {
        setOpenRegisterModal(!openRegisterModal);
    };
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])




    return (
        <>
            <AIHero  handleSignUp={handleSignUp}/>
            <AICallingWorkflow />
            <AIBestChoice
                title="Why OttoLeads AI Calling is the Best Choice"
                subtitle="Not all dialing systems are built for real estate investors and agents. See how OttoLeads compares:"
                footerText="Scale Your Business Without Hiring—AI Does the Work of 10 Employees"
                tableData={tableData}
                handleSignUp={handleSignUp}
            />
            <AIBestChoice
                title="AI vs Human Callers—Which is Better?"
                subtitle="Traditional call centers and manual calling comes with high costs, slow speed, and inconsistent results. 
OttoLeads AI calling removes the bottlenecks, allowing you to scale faster—without hiring a team."
                footerText="Turn Data into Deals—AI Calls 10X Faster Than Any Human"
                hideButton
                bgGradient
                tableData={tableTwoData}
                handleSignUp={handleSignUp}
            />
            <WhyChooseSection handleSignUp={handleSignUp}
                testimonialMessage={testimonialMessage}
                testimonialName={testimonialName}
                testimonialTitle={testimonialTitle}
                bottomText={bottomText}
                imageUrl={imageUrl}

            />

            {openRegisterModal ? (
                <Register
                    open={openRegisterModal}
                    setOpen={setOpenRegisterModal}
                    openLogin={true}
                    email={email}
                ></Register>
            ) : (
                ""
            )}
        </>
    )
}

export default AICallingPage;

