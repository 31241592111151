export const DEFAULT_LIST_DATA = {
  listName: "",
  skipTracedList: "",
  filePath: "",
  nameFirst: "",
  nameLast: "",
  addressStreet: "",
  addressCity: "",
  addressState: "",
  addressZip: "",
  phone1: "",
  phone2: "",
  phone3: "",
  phone4: "",
  phone5: "",
  phone6: "",
  phone7: "",
  phone8: "",
  phone9: "",
  phone10: ""
};

export const DB_COLUMNS = [
  "nameFirst",
  "nameLast",
  "addressStreet",
  "addressCity",
  "addressState",
  "addressZip",
  "phone1",
  "phone2",
  "phone3",
  "phone4",
  "phone5",
  "phone6",
  "phone7",
  "phone8",
  "phone9",
  "phone10",
];
