import { useState } from "react";
import { useEffect } from "react";
import Register from "../../organisms/auth/Register";
import VAWorkflowSection from "../../organisms/vaWorkflowSection/VAWorkflowSection";
import VABenefitSection from "../../organisms/vaBenefitSection/VABenefitSection";
import VATestimonialsSection from "../../organisms/vaTestimonialsSection/VATestimonialsSection";
import VAHeroSection from "../../organisms/vaHeroSection/VAHeroSection";
import LearnMore from "../learnmore/LearnMore";

const VirtualAssistantPage = () => {
  const [openRegisterModal, setOpenRegisterModal] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [learnMoreModal, setLearnMoreModal] = useState(false);
  const handleLearnMore=()=>{
    setLearnMoreModal(true)
  }

  const handleSignUp = () => {
    setOpenRegisterModal(!openRegisterModal);
  };

  useEffect(() => {
    // Scroll to top when the component mounts
    window.scrollTo(0, 0);
  }, []);



  return (
    <>
        {/* Hero Section - Redesigned with brand aesthetics and human elements */}
        <VAHeroSection handleSignUp={handleSignUp} handleLearnMore={handleLearnMore}/>

        {/* How OttoLeads VA Services Work Section */}
        <VAWorkflowSection />

        {/* Benefits of Using OttoLeads VAs Section */}
        <VABenefitSection handleSignUp={handleSignUp}  />
        {/* Why Choose OttoLeads Over Other VA Providers Section */}
        <VATestimonialsSection />

      {openRegisterModal ? (
        <Register
          open={openRegisterModal}
          setOpen={setOpenRegisterModal}
          openLogin={true}
          email={email}
        ></Register>
      ) : (
        ""
      )}
       {learnMoreModal ? (
              <LearnMore
                open={learnMoreModal}
                handleClose={() => setLearnMoreModal(false)}
              />
            ) : (
              ""
            )}
    </>
  );
};

export default VirtualAssistantPage;
