import { useEffect, useState, useRef } from "react";
import { motion } from "framer-motion";
import styles from "./CRMWorkflow.module.scss";
import tab1 from "../../assets/images/tab-1.png";
import tab2 from "../../assets/images/tab-2.png";
import tab3 from "../../assets/images/tab-3.png";
import tab4 from "../../assets/images/tab-4.png";
import { features } from "./constants";
import VerifyCheck from "../../assets/icons/verifyCheck";
import { Clock3 } from "lucide-react";

const tabs = [tab1, tab2, tab3, tab4];

const CRMWorkflow = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [bodyScrollEnabled, setBodyScrollEnabled] = useState(false);
  const sectionRef = useRef<HTMLElement | null>(null);
  const observerRef = useRef<IntersectionObserver | null>(null);
  const hasDisabledScroll = useRef(false);
  useEffect(() => {
    const section = sectionRef.current;
    if (!section) return;

    let timeoutId: NodeJS.Timeout | undefined;
    let observer: IntersectionObserver | null = null;

    const observerCallback = (entries: IntersectionObserverEntry[]) => {
      const [entry] = entries;
      if (entry.isIntersecting) {
        timeoutId = setTimeout(() => {
          setBodyScrollEnabled(false);
        }, 500); // Wait 500ms before disabling body scroll
      } else {
        setBodyScrollEnabled(true);
      }
    };

    // Delay observer initialization by 1 second
    const initObserver = () => {
      observer = new IntersectionObserver(observerCallback, {
        root: null,
        threshold: 0.5, // Only trigger when at least 50% is visible
      });

      observer.observe(section);
      observerRef.current = observer;
    };

    const delayInit = setTimeout(initObserver, 1000); // Wait 1 second before observing

    return () => {
      clearTimeout(delayInit);
      if (observer) observer.disconnect();
      clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    const section = sectionRef.current;
    if (!section) return;

    const handleScroll = () => {
      const scrollTop = section.scrollTop;
      const sectionHeight = section.scrollHeight - section.clientHeight;
      const index = Math.min(
        Math.floor((scrollTop / sectionHeight) * features.length),
        features.length - 1
      );
      setActiveIndex(index);

      // Enable body scroll if the last index has been viewed
      if (index === features.length - 1) {
        setBodyScrollEnabled(true);
      }
    };

    const disableBodyScroll = () => {
      if (!hasDisabledScroll.current) {
        document.body.style.overflow = "hidden";
        hasDisabledScroll.current = true; // Mark as disabled once
      }
    };

    const enableBodyScroll = () => (document.body.style.overflow = "");

    if (!bodyScrollEnabled) {
      disableBodyScroll();
    } else {
      enableBodyScroll();
    }

    section.addEventListener("scroll", handleScroll);

    return () => {
      section.removeEventListener("scroll", handleScroll);
      enableBodyScroll();
    };
  }, [bodyScrollEnabled]);

  return (
    <section ref={sectionRef} className={styles.scrollContainer}>
      <div className={styles.main}>
        <div className="wrapper-lg">
          <motion.header
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
          >
            <h2>How OttoLeads CRM Works</h2>
            <p>
              Stop wasting time switching between tools. OttoLeads CRM
              simplifies everything.
            </p>
          </motion.header>

          <div className={`dflex alignCenter ${styles.main__works}`}>
            <div className={styles.main__left}>
              <ul>
                {features.map((feature, index) => (
                    <>
                  <motion.li
                    key={index}
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.5, delay: 0.3 + index * 0.1 }}
                    className={`flex ${
                      index === activeIndex ? styles.active : ""
                    }`}
                  >
                    <figure className="flex alignCenter justifyCenter">
                      {index === activeIndex ? (
                        <VerifyCheck />
                      ) : (
                        <feature.icon />
                      )}
                    </figure>
                    <figcaption>
                      <h4>{feature.title}</h4>
                      <p>{feature.description}</p>
                    </figcaption>
                  </motion.li>
                  <div className={`flex ${styles.main__imgMobile}`}>
<img src={feature.image} alt="CRM Workflow" />
</div>
</>
                ))}
              </ul>
              <div className={`flex ${styles.main__note}`}>
                <figure className="flex alignCenter justifyCenter">
                  <Clock3 />
                </figure>
                <p>
                  Everything you need to manage leads and deals without the
                  complexity of generic CRMs.
                </p>
              </div>
            </div>
            <div
              className={`${styles.main__img} flex alignCenter justifyCenter`}
            >
              <img src={tabs[activeIndex]} alt="CRM Workflow" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CRMWorkflow;
