export const title="Find Properties Faster with Advanced Search Tools"

export const heading=`You shouldn't have to spend hours sifting through bad data.
              OttoLeads makes it easy to zero in on high-potential deals in
              seconds.`
export const searchListItems = [
    "By Address, City, County, or Zip Code",
    "APN & MLS Lookup for Instant Results",
    "Custom Draw on Map to focus on specific neighborhoods",
  ];

export const listItems = [
    "Pre-Foreclosures, tax liens & distressed homes",
    "Equity, ownership type & vacancy status",
    "Failed listings, probate & high-motivation sellers",
  ];

export const bottomText="No wasted time. Just the exact properties you're looking for."