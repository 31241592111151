// import { useState, useEffect } from "react";
// import styles from "./RotatingProfession.module.scss";
// import { AnimatePresence, motion } from "framer-motion";

// const professions = [
//   "real estate investors.",
//   "land buyers.",
//   "wholesalers.",
//   "real estate agents.",
//   "mortgage professionals.",
//   "home & property services.",
//   "contractors.",
//   "property managers.",
// ];

// const RotatingProfession = () => {
//   const [currentIndex, setCurrentIndex] = useState(0);
//   const [isActive, setIsActive] = useState(false);
//   useEffect(() => {
//     const interval = setInterval(() => {
//       setIsActive(false)
//       setTimeout(() => setIsActive(true), 200);
//       setCurrentIndex((prevIndex) => (prevIndex + 1) % professions.length);
//     }, 3000);

//     return () => clearInterval(interval);
//   }, []);

//   return (
//     <div className={`${styles.rotationText}`}>
//       <div className="wrapper-lg">
//         <div className={`dflex justifyCenter ${styles.rotationText__info}`}>
//           <h3>The most comprehensive toolkit for</h3>
//           <div
//             className={styles.rotationText__wrapper}
//             style={{
//               transform: `translateY(-${currentIndex * 50}px)`, // Adjust based on the height of each item
//             }}
//           >
//             {professions.map((prof, index) => (
//               <div
//                 key={index}
//                 className={`${styles.rotationText__text} ${  isActive && index === currentIndex ? styles.active : ""}`}
//               > 
//                 {prof}
//               </div>
//             ))}
//           </div>
//           <div  className={styles.rotationText__mobile}>
//           <AnimatePresence mode="wait">
//               <motion.div
//                 key={currentIndex}
//                 initial={{ opacity: 0, y: 15 }}
//                 animate={{ opacity: 1, y: 0 }}
//                 exit={{ opacity: 0, y: -15 }}
//                 transition={{ duration: 0.5 }}
//               >
//                 {professions[currentIndex]}
//               </motion.div>
//             </AnimatePresence>
//             </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default RotatingProfession;

import { useState } from "react";
import Slider from "react-slick";
import styles from "./RotatingProfession.module.scss";

const professions = [
  "real estate investors.",
  "land buyers.",
  "wholesalers.",
  "real estate agents.",
  "mortgage professionals.",
  "home & property services.",
  "contractors.",
  "property managers.",
];

const RotatingProfession = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const settings = {
    vertical: true,
    autoplay: true,
    autoplaySpeed: 3000,
    centerMode:true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows:false,
    infinite: true,
    pauseOnHover:false,
    beforeChange: (oldIndex:any, newIndex:any) => {
      setCurrentIndex(newIndex);
      setIsActive(false); // Reset the active state
      setTimeout(() => setIsActive(true), 200); // Add a slight delay before reactivating
    },
    responsive: [
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
          }
        },
       
      ]
  };

  return (
    <div className={`${styles.rotationText}`}>
      <div className="wrapper-lg">
        <div className={`dflex justifyCenter ${styles.rotationText__info}`}>
         
          <div className={`${styles.rotationText__slider}`}>
          <h3>The most comprehensive toolkit for</h3>
            <Slider {...settings}>
              {professions.map((prof, index) => (
                <div className={`${styles.rotationText__sliderText} ${ isActive &&  index === currentIndex ? styles.active : ""}`} key={index} >
                  {prof}
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RotatingProfession;
