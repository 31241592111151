


const CheckGradient = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
        <path d="M6.5 11L9.5 14L15.5 8M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z" stroke="url(#paint0_linear_569_1098)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <defs>
        <linearGradient id="paint0_linear_569_1098" x1="12.1268" y1="-25" x2="-11.124" y2="-18.2233" gradientUnits="userSpaceOnUse">
        <stop stop-color="#11DDFF"/>
        <stop offset="0.284962" stop-color="#2FA1FF"/>
        <stop offset="0.546739" stop-color="#3C88FF"/>
        <stop offset="0.809238" stop-color="#5A4EFF"/>
        <stop offset="1" stop-color="#7022FF"/>
        </linearGradient>
        </defs>
        </svg>
    );
};

export default CheckGradient;


