
import {  Users, FileText, UserRoundPlus, List, Fingerprint} from "lucide-react"
import CallDownIcon from "../../assets/icons/callDown"
import Target from "../../assets/icons/target"
import { Link } from "@mui/material"
import LinkIcon from "../../assets/icons/link"
import ListFlat from "../../assets/icons/linkFlat"
import Cost from "../../assets/icons/cost"


 export const features = [
    {
      icon: Target,
      title: "Target Your Market",
      description: "Use OttoLeads to filter and purchase data on distressed properties.",
    },
    {
      icon: UserRoundPlus,
      title: "Activate Your VA",
      description: "With a few clicks, assign our trained VA to start contacting your selected leads.",
    },
    {
      icon: List,
      title: "Receive Qualified Leads",
      description: "Our VA conducts initial outreach, gathers essential information, and forwards only the most promising leads to you.",
    },
  ]
  export const choose = [
    {
      icon: ListFlat,
      title: "Integrated with Your OttoLeads Account",
      description: "Seamlessly purchase targeted data and have our VAs start calling your leads within minutes.",
    },
    {
      icon: Cost,
      title: "Cost-Effective Solution",
      description: "Ideal for those without the budget for AI calling or the infrastructure to handle high lead volumes.",
    },
    {
      icon: Fingerprint,
      title: "Human Touch",
      description: "Our VAs provide a personal connection, increasing engagement and building trust with potential sellers.",
    },
  ]