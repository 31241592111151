import React, { useEffect, useState } from "react";
import Button from "../../atoms/Button";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Input from "../../atoms/Input";
import { addUserSchema } from "./validations";
import MaskedInput from "../../atoms/MaskedInput";
import DropDown from "../../atoms/DropDown";
import { Dialog } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getUserRoleList } from "../../pages/myTeam/selectors";
import Close from "../../assets/icons/cross";
import { taxOnUser } from "../../pages/myTeam/TeamService";
import { formartNumberAsLocalString, formatCurrency } from "../../utils/utils";
import User from "../../assets/icons/user";
import Loader from "../../atoms/Loader";
import { getSubscriptions } from "../billing/selectors";
import dayjs from "dayjs";
import { ToastType } from "../../molecules/toaster/enum";
import { addToast } from "../../molecules/toaster/toastSlice";
import CouponCard from "../../pages/couponCard/CouponCard";
import { validateCoupon } from "../creditsSubscription/CreditsSubscriptionService";

const AddUser = ({ handleClose, onSubmitDetail, loading, open, couponStatus,
  setCouponStatus,
  updatedPricing,
  handleRemoveCoupon,
  setUpdatedPricing }: any) => {
  const {
    formState: { errors,isValid },
    register,
    control,
    handleSubmit,
    reset,
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(addUserSchema),
    defaultValues:{
      name:"",
      email:"",
      userRoleName:"",
      phone:""
    }
  });
  const dispatch = useDispatch();
  const roleList = useSelector((state) => getUserRoleList(state));
  const [roleListOptions, setRoleListOptions] = useState([]);
  const [confirmClicked, setConfirmClicked] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date());
  const subscriptions = useSelector((state) => getSubscriptions(state));
  const [validateButtonLoading, setValidateButtonLoading] = useState(false);
  const [addUserTax, setAddUserTax] = useState({
    taxAmount: 0,
    price: 0,
    totalPrice: 0,
  });

  const handleShowToast = (
    message: string,
    type: ToastType = ToastType.success
  ) => {
    dispatch(
      addToast({
        id: new Date().getTime(),
        message: message,
        type: type,
      })
    );
  };
  const calculateProratedCharge = (expiresAt:any) => {
    const currentDay = dayjs().startOf('day'); 
  const expiresAtDay = dayjs(expiresAt).startOf('day');
  let daysRemaining = expiresAtDay.diff(currentDay, 'day');
  daysRemaining += 1;
  return ((20 / 30) * daysRemaining).toFixed(2);
};

const handleCoupon = async (data: any) => {
    try {
      setValidateButtonLoading(true);
      const res = await validateCoupon({
        couponCode: data?.code.toUpperCase(),
        price:addUserTax?.price ,
        identifier:"user_seat_subscription"
      });
      setUpdatedPricing(res?.data?.data);
      setValidateButtonLoading(false);
      setCouponStatus(`Coupon Code Applied! (${res?.data?.data.discount_type ==="percentage" ? `${res?.data?.data.discount_value}%` :`$${res?.data?.data.discount_value}`} Off)`);
    } catch (error:any) {
      setValidateButtonLoading(false);
      setCouponStatus(error?.response?.data?.message);
    }
  };


  useEffect(() => {
    const options = roleList.filter((list: any) => list !== 'VA').map((list: any) => ({
      value: list,
      label: list,
    }));
    setRoleListOptions(options);
  }, [roleList]);

  useEffect(() => {
    if (!open) {
      reset();
      setConfirmClicked(false)
      handleRemoveCoupon()
    }
  }, [open]);

  useEffect(()=>{
    if(open){
      const taxValue = async()=>{
        try{
          const res=await taxOnUser();
          setAddUserTax(res.data)
        }catch(err:any){
          handleShowToast(err.message, ToastType.error);
        }
      }
      taxValue()
    }
  },[open])


  useEffect(()=>{
if(subscriptions){
  setCurrentDate(subscriptions?.generalSubData?.expiresAt)
}
  },[subscriptions])

  return (
    <Dialog className="stepsDialog" onClose={handleClose} open={open}>
      {!addUserTax.totalPrice ? (
        <div className="loader-main">
          <Loader />
        </div>
      ) : !confirmClicked ? (
        <div className="dialogWrapper">
          <span role="link" className="closeDialog" onClick={handleClose}>
            <Close />
          </span>
          <div className="dialogWrapper__icon">
            <User />
          </div>
          <div className="dialogWrapper__header">
            <h2>Add User</h2>
          </div>
          <div className="dialogWrapper__info">
            <Input
              type="text"
              label="Name*"
              register={register}
              name="name"
              placeholder="Enter user name"
              errors={errors}
            ></Input>
            <Input
              type="email"
              label="Email*"
              register={register}
              name="email"
              placeholder="Enter user email"
              errors={errors}
            ></Input>
            <MaskedInput
              placeholder="Enter user phone number"
              label="Phone"
              name="phone"
              register={register}
              errors={errors}
              mask={"999-999-9999"}
            ></MaskedInput>

            <DropDown
              options={roleListOptions}
              label="Role*"
              control={control}
              name="userRoleName"
              errors={errors}
              placeholder="Select role"
            ></DropDown>
          </div>
          <div className="mainCol actionsBorder">
            <div className="mainCol__half">
              <Button
                label="Cancel"
                className="outline full xl animated"
                action={handleClose}
              ></Button>
            </div>
            <div className="mainCol__half">
              <Button
                label="Confirm"
                className="primary full xl animated"
                action={() => setConfirmClicked(true)}
                disabled={!isValid}
              ></Button>
            </div>
          </div>
        </div>
      ) : (
        <div className="dialogWrapper">
          <span role="link" className="closeDialog" onClick={handleClose}>
            <Close />
          </span>
          <div className="dialogWrapper__icon">
            <User />
          </div>

          <div className="dialogWrapper__info">
            Seats are $20 each. Today’s charge will be prorated for the days
            left until your General Access Plan renewal, and $20/mo will renew
            on{" "}
            {subscriptions?.generalSubData?.expiresAt
              ? dayjs(
                  new Date(currentDate).setDate(
                    new Date(currentDate).getDate() + 1
                  )
                ).format(`MMM DD, YYYY`)
              : "-"}
            .
          </div>

          <CouponCard
            loading={validateButtonLoading}
            couponStatus={couponStatus}
            onSubmit={handleCoupon}
            setCouponStatus={setCouponStatus}
            handleRemoveCoupon={handleRemoveCoupon}
            couponSelected ={updatedPricing?.id}
          />
          <div>
            <div className={`dflex alignCenter justifySpaceBetween totalRow`}>
              <p>Prorated Charges Today:</p>
              <p className="right">
                {subscriptions?.generalSubData?.expiresAt
                  ? formatCurrency(
                      calculateProratedCharge(
                        subscriptions?.generalSubData?.expiresAt
                      )
                    )
                  : "-"}
              </p>
            </div>

            <div className={`dflex alignCenter justifySpaceBetween totalRow`}>
              <p> Subtotal:</p>
              <p className="right">
                {addUserTax?.price ? formatCurrency(addUserTax?.price) : "-"}
              </p>
            </div>
            {addUserTax?.taxAmount != 0 && (
              <div className={`dflex alignCenter justifySpaceBetween totalRow`}>
                <p>Tax:</p>
                <p className="right">{formatCurrency(addUserTax?.taxAmount)}</p>
              </div>
            )}
            {updatedPricing?.pricing?.breakdown?.discountAmount && (
              <div className={`flex justifySpaceBetween totalRow`}>
                <p>Coupon Discount ({updatedPricing?.id}):</p>
                <div className={` right`}>
                  <p>${updatedPricing?.pricing?.breakdown?.discountAmount}</p>
                </div>
              </div>
            )}

            <div className={`dflex alignCenter justifySpaceBetween totalRow `}>
              <p>Total Price Due Today:</p>
              <p className="right">
                <strong>
                  {updatedPricing
                    ?` $${formartNumberAsLocalString(
                        +(updatedPricing?.pricing?.breakdown?.finalAmount ?? 0),
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      )}`
                    : subscriptions?.generalSubData?.expiresAt
                    ? formatCurrency(
                        calculateProratedCharge(
                          subscriptions?.generalSubData?.expiresAt
                        ) + (addUserTax?.taxAmount || 0)
                      )
                    : "-"}
                </strong>
              </p>
            </div>
            <div className={`dflex alignCenter justifySpaceBetween totalRow `}>
              <p>
                Total Monthly Price Renewing On{" "}
                {subscriptions?.generalSubData?.expiresAt
                  ? dayjs(
                      new Date(currentDate).setDate(
                        new Date(currentDate).getDate() + 1
                      )
                    ).format(`MMM DD, YYYY`)
                  : "-"}
                :
              </p>
              <p className="right">
                <strong>
                  {addUserTax?.totalPrice
                    ? formatCurrency(
                        addUserTax?.totalPrice + (updatedPricing ? updatedPricing?.pricing?.breakdown?.taxAmount : addUserTax?.taxAmount || 0)
                      )
                    : "-"}
                </strong>
              </p>
            </div>
          </div>

          <div className="mainCol actionsBorder actions">
            <div className="mainCol__half">
              <Button
                label="Cancel"
                className="outline full xl animated"
                action={handleClose}
              ></Button>
            </div>
            <div className="mainCol__half">
              <Button
                label="Submit"
                className="primary full xl animated"
                action={handleSubmit(onSubmitDetail)}
                clicked={loading}
                disabled={loading}
              ></Button>
            </div>
          </div>
        </div>
      )}
    </Dialog>
  );
};

export default AddUser;
