import styles from "./contentSec.module.scss";
import sample from "../../../assets/images/video-poster.png"
import Check from "../../../assets/icons/check";
import { ArrowRightIcon } from "@mui/x-date-pickers";
import { NavLink } from "react-router-dom";
import NationWide from "../../../assets/videos/nationwide-video.mp4";
import AiCalling from "../../../assets/videos/ai-calling.mp4";
import videoHome from "../../../assets/videos/nationwide-video.mp4";
import VirtualAssistant from "../../../assets/videos/virtual-assistant.mp4";
import CRM from "../../../assets/videos/crm.mp4";


const ContentSec = () => {

    return (
      <div className={`${styles.contentSec}`}>
        <div className="wrapper-lg">
          <div className={`dflex alignCenter ${styles.contentSec__row}`}>
            <div className={`${styles.contentSec__info}`}>
              <h2>Nationwide Data</h2>
              <p>
                Having the right data is critical to making informed decisions.
                Target properties with precision. Ottoleads gives you access to
                detailed information – such as Ownership Information, Property
                Details, Mortgage History, Tax Information, MLS Details, and
                much more.
              </p>
              <ul>
                <li>
                  <span>
                    <Check />
                  </span>{" "}
                  Identify pre-foreclosures, tax liens, vacant homes, and
                  probates.
                </li>
                <li>
                  <span>
                    <Check />
                  </span>{" "}
                  Combine 100+ data points for laser-focused searches.
                </li>
                <li>
                  <span>
                    <Check />
                  </span>{" "}
                  Skip-trace properties & obtain multiple phone numbers & emails
                  for each.
                </li>
              </ul>

              <div
                className={`${styles.contentSec__link} flex justifyEnd`}
              >
               <NavLink
               className="flex alignCenter button outline-blue"
                to="/nationwide-data"
              >
                Learn more 
              </NavLink>
              </div>

            
            </div>
            <div className={`${styles.contentSec__img}`}>
            <video muted autoPlay loop playsInline width="100%" src={NationWide} />
            </div>
          </div>
          <div className={`dflex alignCenter ${styles.contentSec__row}`}>
            <div className={`${styles.contentSec__info}`}>
              <h2>Conversational AI Calling</h2>
              <p>
                Stop wasting time dialing leads manually. With our
                Conversational AI, you can effortlessly connect with motivated
                sellers, cash buyers, and agents while saving endless hours of
                dialing every day.
              </p>
              <ul>
                <li>
                  <span>
                    <Check />
                  </span>{" "}
                  Makes up to 1.7 million calls per day.
                </li>
                <li>
                  <span>
                    <Check />
                  </span>{" "}
                  Human-like conversations utilizing advanced AI voice modeling.
                </li>
                <li>
                  <span>
                    <Check />
                  </span>{" "}
                  Qualifies leads in real time.
                </li>
                <li>
                  <span>
                    <Check />
                  </span>{" "}
                  Operates 7 days a week.
                </li>
              </ul>
              <div
                className={`${styles.contentSec__link} flex justifyEnd`}
              >
               <NavLink
               className="flex alignCenter button outline-blue"
                 to="/ai-calling"
              >
                Learn more 
              </NavLink>
              </div>
       
            </div>
            <div className={`${styles.contentSec__img}`}>
            <video muted autoPlay loop playsInline width="100%" src={AiCalling} />
            </div>
          </div>
          <div className={`dflex alignCenter ${styles.contentSec__row}`}>
            <div className={`${styles.contentSec__info}`}>
              <h2>Human Virtual Assistant</h2>
              <p>
                Finding and managing reliable Virtual Assistants is a hassle.
                With OttoLeads, we handle everything—so you can focus on closing
                deals while our skilled VAs work your lists and deliver
                qualified leads.
              </p>
              <ul>
                <li>
                  <span>
                    <Check />
                  </span>{" "}
                  No hiring, firing, or management headaches.
                </li>
                <li>
                  <span>
                    <Check />
                  </span>{" "}
                  Trained VAs work your lead lists for you.
                </li>
                <li>
                  <span>
                    <Check />
                  </span>{" "}
                  Consistent lead delivery.
                </li>
              </ul>
              
          
              <div
                className={`${styles.contentSec__link} flex justifyEnd`}
              >
               <NavLink
               className="flex alignCenter button outline-blue"
                 to="/virtual-assistant"
              >
                Learn more 
              </NavLink>
              </div>
            </div>
            <div className={`${styles.contentSec__img}`}>
            <video muted autoPlay loop playsInline width="100%" src={VirtualAssistant} />
            </div>
          </div>
          <div className={`dflex alignCenter ${styles.contentSec__row}`}>
            <div className={`${styles.contentSec__info} ${styles.crmInfo}`}>
              <h2>Integrated CRM</h2>
              <p>
                Effortlessly manage leads without spreadsheets or manual
                dialing. Our built-in CRM keeps you organized, tracks every
                interaction, and streamlines your workflow—so you can focus on
                closing more deals.
              </p>
              <ul>
                <li>
                  <span>
                    <Check />
                  </span>{" "}
                  Call tracking, statuses, and notes.
                </li>
                <li>
                  <span>
                    <Check />
                  </span>{" "}
                  Built-in CRM for lead management.
                </li>
                <li>
                  <span>
                    <Check />
                  </span>{" "}
                  Efficient team collaboration.
                </li>
                <li>
                  <span>
                    <Check />
                  </span>{" "}
                  Wholesale, creative finance, and deal calculators.
                </li>
              </ul>
       
              <div
                className={`${styles.contentSec__link} flex justifyEnd`}
              >
               <NavLink
               className="flex alignCenter button outline-blue"
                to="/integrated-crm"
              >
                Learn more 
              </NavLink>
              </div>
            </div>
            <div className={`${styles.contentSec__img} ${styles.crmImg}`}>
            <video muted autoPlay loop playsInline width="100%" src={CRM} />
            </div>
          </div>
        </div>
      </div>
    );
};

export default ContentSec;
