const GooglePlus = () => {
    return (
        <>
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
<path fillRule="evenodd" clipRule="evenodd" d="M17.3424 7.38754C16.1034 6.20583 14.4499 5.56207 12.739 5.58853C9.60842 5.58853 6.9496 7.70058 6.00158 10.5446V10.5446C5.49892 12.035 5.49892 13.6488 6.00158 15.1392H6.00598C6.9584 17.9788 9.61282 20.0908 12.7434 20.0908C14.3595 20.0908 15.7468 19.6775 16.8221 18.9474V18.9444C18.0876 18.1067 18.9518 16.7883 19.2208 15.2979H12.739V10.6769H24.0578C24.1989 11.4794 24.2651 12.2996 24.2651 13.1153C24.2651 16.7651 22.9607 19.8509 20.6911 21.9409L20.6935 21.9428C18.7049 23.777 15.9755 24.8397 12.739 24.8397C8.20183 24.8397 4.05265 22.2823 2.01553 18.2301V18.2301C0.313544 14.8393 0.313548 10.8445 2.01555 7.4537H2.01557L2.01553 7.45368C4.05265 3.39709 8.20183 0.839676 12.739 0.839676C15.7197 0.804401 18.599 1.92437 20.7684 3.96148L17.3424 7.38754Z" 
fill="currentColor"/>
</svg>

        </>
    );
};

export default GooglePlus;


