import { useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import PlayIcon from "../../../assets/icons/playIcon";
import { happyCustomerSec } from "../HomeConstants";
import styles from "./HappyCustomerSec.module.scss";

import StarIcon from "../../../assets/icons/starIcon";
import { ArrowLeft, ArrowRight } from "lucide-react";

function SampleNextArrow(props: { className: any; style: any; onClick: any }) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
     <ArrowRight />
    </div>
  );
}

function SamplePrevArrow(props: { className: any; style: any; onClick: any }) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <ArrowLeft />
    </div>
  );
}

const HappyCustomerSec = ({extraBottomSpace}:any) => {
  const [openEarlyAccessModal, setOpenEarlyAccessModal] =
  useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [openLoginModal, setOpenLoginModal] = useState<boolean>(false);
  const [playingIndex, setPlayingIndex] = useState(-1);
  const videoRef = useRef<HTMLVideoElement | any>(null);
  const settings = {
    autoplay: false,
    slidesToShow: 3.5,
    slidesToScroll: 1,
    infinite: false,
    arrows: true,
    buttons: false,
    nextArrow: (
      <SampleNextArrow
        className={undefined}
        style={undefined}
        onClick={undefined}
      />
    ),
    prevArrow: (
      <SamplePrevArrow
        className={undefined}
        style={undefined}
        onClick={undefined}
      />
    ),
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2.5,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };


  const handlePlay = (index: any) => {
    const prevElement =
      playingIndex !== null
        ? (document.getElementById(
            `video-element${playingIndex}`
          ) as HTMLVideoElement)
        : null;
    if (prevElement) {
      prevElement.pause();
      prevElement.controls = false;
    }
    if (playingIndex !== index) {
      const currentElement = document.getElementById(
        `video-element${index}`
      ) as HTMLVideoElement;

      if (currentElement) {
        currentElement.controls = true;
        setTimeout(() => {
          currentElement.play().catch(error => {
            console.error('Error occurred while playing video:', error);
          });
        }, 0);
      } else {
        console.error(`Video element with id 'video-element${index}' not found.`);
      }
    }
    
    setPlayingIndex(playingIndex !== index ? index : -1);
  };

  const handlePause = (index: any) => {
    if (index === playingIndex) {
      const videoElement = document.getElementById(
        `video-element${index}`
      ) as HTMLVideoElement;
      const isSeekingPause = videoElement.seeking;
      if (!isSeekingPause) {
        videoElement.controls = false;
        setPlayingIndex(-1);
      }
    }
  };

  return (
    <div className={` ${styles.customer} ${extraBottomSpace && styles.extraBottomSpace} `}>
             <header className="wrapper-lg">
            <h2>Don't Just Take Our Word For It</h2>
            <p>Hear from some of our amazing customers who are closing deals with Ottoleads.</p>
            </header>
        <div className={`${styles.customer__slider}`}>
       
           
        <Slider className="slick" {...settings}>
           {happyCustomerSec.customers.map((item, index) => {
                
                return (
                  <>
                    <div
                      className={` ${styles.customerCard} ${
                        playingIndex === index && styles.videoPlay
                      }`}
                      id={`${index}`}
                      onClick={() => handlePlay(index)}
                    >
                         <div
                className={` ${styles.customerCard__animate}`}
            >
                <div className={` ${styles.customerCard__animateInner}`}></div>
                <div className={` ${styles.customerCard__animateBg}`}></div>
            </div>
                      <div className={` ${styles.cardImg}`}>
                        <video
                          id={`video-element${index}`}
                          key={item?.video}
                          width="400"
                          controlsList={"nodownload noplaybackrate"}
                          poster={item.cardImage}
                        >
                          {playingIndex === index && (
                            <source src={item?.video} type="video/mp4" />
                          )}
                        </video>
                        {playingIndex !== index && (
                          <>
                            <span className={`${styles.playPauseBtn}`}>
                              <PlayIcon />
                            </span>

                            <div className={`flex ${styles.cardBottom}`}>
                              <figcaption>
                              <div className={`flex ${styles.cardBottom__star}`}>
                                <StarIcon/>
                                <StarIcon/>
                                <StarIcon/>
                                <StarIcon/>
                                <StarIcon/>
                                </div>
                                <h5>{item.name}</h5>
                                <p>{item.title}</p>
                              </figcaption>
                            </div>
                          </>
                        )}
                      </div>
                    </div>

                    
                  </>
                );
              })}
            </Slider>
            </div>
    </div>
  );
};

export default HappyCustomerSec;
