import { motion } from "framer-motion"
import styles from "./CRMCalculator.module.scss";
import Button from "../../atoms/Button";
import GifImg from "../../assets/images/finance.gif";
import CalculatorIcon from "../../assets/icons/calculatorIcon";
import { useNavigate } from "react-router-dom";
const CRMCalculator = () => {
    const navigate = useNavigate();
    return (
        <section className={`${styles.main}`}>
                <div className="wrapper-lg">
              
                    {/* Features Grid */}
                    <div className={`${styles.main__finance} dflex`}>

                    <div className={`${styles.main__left}`}>
                    <motion.header
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true }}
                        transition={{ duration: 0.5 }}

                    >
                        <h5>Creative Financing</h5>
                        <h2>The Creative Finance Calculator. Helping You Close More Deals</h2>
                        <p>
                        Not every seller fits the traditional financing model. The OttoLeads Creative Finance Calculator helps you structure
deals using seller financing, subject-to, and other creative
financing strategies.
                        </p>
                   
                    <motion.h3
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true }}
                        transition={{ duration: 0.5 }}

                    >
                        How It Works:

                    </motion.h3>
                  
                    <motion.ul
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true }}
                        transition={{ duration: 0.5 }}

                    >
                        <li>Compare multiple financing options like cash offers, seller financing, and hybrid deals.</li>
                        <li>Analyze monthly payments, interest, and equity structures to
                        craft the perfect offer.</li>
                        <li>Ensure your deal numbers are profitable enabling you to present strong offers that meet each seller’s unique financial situation. </li>
                    </motion.ul>
                    <div className={`flex alignCenter ${styles.main__note}`}>
                                <figure className="flex alignCenter justifyCenter">
                                    <CalculatorIcon />
                                </figure>
                                <span>
                                Negotiate and structure win-win deals, especially when sellers are not open to a straight cash offer.
                                </span>
                            </div>
                    <Button
                            className="primary"
                            label="Click to Access the Creative Calculator for Free"
                            action={() => navigate("/creative-calculator")}
                        />
                         </motion.header>
                        </div>
                        <div className={`flex alignCenter justifyCenter ${styles.main__img}`}>
                 <img src={GifImg} alt="gif" />
                        </div>
                    </div>
                 
                </div>
        </section>
    )
}

export default CRMCalculator;
