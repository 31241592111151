import { motion } from "framer-motion"
import styles from "./CRMFeatures.module.scss";
import { choose } from "./constants";
import Button from "../../atoms/Button";

const CRMFeatures = ({ handleSignUp }: any) => {
    return (
        <section className={`${styles.main}`}>
                <div className="wrapper-lg">
                    <motion.header
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true }}
                        transition={{ duration: 0.5 }}

                    >
                        <h2>The CRM That Helps You Stay Ahead</h2>
                        <p>
                            OttoLeads CRM is built specifically for real estate investors and wholesalers, with
                            features designed to help you close more deals.
                        </p>
                    </motion.header>
                    {/* Features Grid */}
                    <div className={`${styles.main__chooseRow} dflex`}>

                        {choose.map((choose, index) => (
                            <motion.div
                                key={index}
                                initial={{ opacity: 0, y: 20 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                viewport={{ once: true }}
                                transition={{ duration: 0.5, delay: index * 0.1 }}
                                className={`${styles.main__chooseCard}`}
                            >

                                <figure className={`flex alignCenter justifyCenter `}>
                                    <choose.icon />
                                </figure>
                                <h3>{choose.title}</h3>
                                <p>{choose.description}</p>
                            </motion.div>
                        ))}

                    </div>
                    <div className="flex justifyCenter">
                        <Button
                            className="primary"
                            label="7 Day Free Trial"
                            action={handleSignUp}
                        />
                    </div>
                </div>
        </section>
    )
}

export default CRMFeatures;
