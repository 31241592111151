import {  CircularProgress, Dialog, MenuItem, Tooltip } from "@mui/material";
import React, { useCallback, useState } from "react";
import AlertTriangle from "../../assets/icons/alertTriangle";
import ExportToCsv from "../../atoms/ExportToCsvV2";
import { column, skipList_column } from "./constants";
import Edit from "../../assets/icons/edit";
import Trash from "../../assets/icons/trash";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";
import Button from "../../atoms/Button";
import { PermissionListEnum } from "../auth/enum";
import { getProfileInfo } from "../../components/profileMenu/selectors";
import { useSelector } from "react-redux";
import { ROLE_PERMISSION_STATUS } from "../../constants/constants";
import FileCheck from "../../assets/icons/fileCheck";

export const ListRowOptions = ({
    leadDetail,
    loading,
    CurrentId,
    deleteList,
    deleteLoading,
    handleDownload,
    setListOpenModal,
    setSelectedList
}: {
    leadDetail: any;
    loading: Boolean;
    CurrentId: { current: any };
    deleteList: Function;
    deleteLoading:boolean;
    handleDownload:Function
    setListOpenModal:Function
    setSelectedList:Function
}) => {
    const [showDeleteLead, setShowDeleteLead] = useState(false);
    
  const profileInfo = useSelector((state: any) => getProfileInfo(state));
    const downloadListStatus = () => {
      if (leadDetail?.status === "uploading") {
        return (
          <div className="disable-icon listDownload">
            <CircularProgress size={20} />
            Processing
          </div>
        );
      } else if (leadDetail?.status === "failed") {
        return (
          <Tooltip title={"Failed"}>
            <div className="disable-icon listDownload">
              <AlertTriangle />
              Failed
            </div>
          </Tooltip>
        );
      } else if (leadDetail?.status === "success") {
        return (
          <span role="link" className="listDownload">
            <ExportToCsv
              headers={leadDetail?.type === "uploaded" ? column : skipList_column}
              filename={`${leadDetail?.name}.csv`}
              loadData={() => handleDownload(leadDetail?.type,leadDetail?.id)
              }
              loading={loading}
              label="Download"
            />
          </span>
        );
      }
    };
    return (
      <>
        <div className="dflex filterInfo">
          <ul className="filterList">
          {leadDetail?.type === "uploaded" && leadDetail?.status === "success"?<MenuItem onClick={() => {
                  setListOpenModal(true);
                  setSelectedList(leadDetail)
              }} >
   <FileCheck  />
              <span >Skip or Enrich</span>
</MenuItem> :""}
            <MenuItem className={leadDetail?.status === "uploading"? "processing": leadDetail?.status === "success" ? "downloadListStatus" :''}>{downloadListStatus()}</MenuItem>
            <Tooltip
 title={
  profileInfo?.userRoleName !== PermissionListEnum.ORGANISATION_OWNER
    ? ROLE_PERMISSION_STATUS.NoAccess
    : ""}
>
  <span>
            <MenuItem
              onClick={() => {
                setShowDeleteLead(true);
              }}
              disableRipple
              disabled={leadDetail?.status === "uploading" ||profileInfo?.userRoleName !== PermissionListEnum.ORGANISATION_OWNER}
            >
              <Trash />
              <span >Delete</span>
            </MenuItem>
            </span>
</Tooltip>
          </ul>
        </div>
        <ConfirmationModal
          isOpen={showDeleteLead}
          setIsOpen={(value:any) => {
            setShowDeleteLead(value);
          }}
          buttonComponent={
            <Button
              label="Yes, Delete"
              className="red xl full "
              action={() => deleteList(leadDetail.id)}
              disabled={deleteLoading}
              clicked={deleteLoading}
            />
          }
        >
          <h2>Delete List</h2>
          <p>Are you sure you want to delete this list?</p>
        </ConfirmationModal>
      </>
    );
};
