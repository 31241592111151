import { ChevronDown } from "lucide-react";
import styles from "./MenuDropdown.module.scss";
import { useEffect, useRef } from "react";

const MenuDropdown = ({
  children,
  title,
  isOpen,
  toggleDropdown,
}: {
  children: React.ReactNode;
  title: string;
  isOpen: boolean;
  toggleDropdown: (dropdownName: string | null) => void;
}) => {
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    toggleDropdown(isOpen ? null : title);
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        toggleDropdown(null);
      }
    }

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, toggleDropdown]);
  return (
    <>
      <div ref={dropdownRef}>
        <div
          onClick={handleToggle}
          className={`flex alignCenter ${styles.dropdownIcon} ${
            isOpen && styles.active
          }`}
        >
          {title} <ChevronDown size={18} />
        </div>
        {isOpen && (
          <ul className={`${styles.dropdown} ${styles.active}`}>{children}</ul>
        )}
      </div>
    </>
  );
}
export default MenuDropdown;