

const VHome = () => {
    return (
<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
<path d="M1.5 8.00428C1.5 7.52565 1.5 7.28634 1.56169 7.06595C1.61633 6.87073 1.70614 6.68711 1.82669 6.52412C1.96278 6.34012 2.15168 6.19319 2.52949 5.89934L8.18141 1.50341C8.47418 1.27569 8.62057 1.16184 8.78221 1.11807C8.92484 1.07946 9.07516 1.07946 9.21779 1.11807C9.37943 1.16184 9.52582 1.27569 9.81859 1.50341L15.4705 5.89934C15.8483 6.19319 16.0372 6.34012 16.1733 6.52412C16.2939 6.68711 16.3837 6.87073 16.4383 7.06595C16.5 7.28634 16.5 7.52565 16.5 8.00428V14.0334C16.5 14.9668 16.5 15.4335 16.3183 15.79C16.1586 16.1036 15.9036 16.3586 15.59 16.5184C15.2335 16.7001 14.7668 16.7001 13.8333 16.7001H4.16667C3.23325 16.7001 2.76654 16.7001 2.41002 16.5184C2.09641 16.3586 1.84144 16.1036 1.68166 15.79C1.5 15.4335 1.5 14.9668 1.5 14.0334V8.00428Z" 
stroke="currentColor" 
strokeWidth="2" 
strokeLinecap="round" 
strokeLinejoin="round"/>
</svg>

    );
};

export default VHome;