import { motion } from "framer-motion"
import styles from "./VAWorkflowSection.module.scss";
import { choose, features } from "./constants";
import VirtualAssistant from "../../assets/videos/virtual-assistant.mp4";

const VAWorkflowSection = () => {
    return (
        <section className={`${styles.main}`}>
            <div className={`${styles.main__service}`}>
                <div className="wrapper-lg">
                    <motion.header
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true }}
                        transition={{ duration: 0.5 }}

                    >
                        <h2>How OttoLeads VA Services Work</h2>
                        <p>
                            Our VA services are designed to integrate seamlessly with your existing workflow,
                            providing you with qualified leads without disrupting your operations.
                        </p>
                    </motion.header>
                    {/* Features Grid */}
                    <div className={`${styles.main__feature} dflex`}>
                        <div className={`${styles.main__steps}`}>
                            {features.map((feature, index) => (
                                <motion.div
                                    key={index}
                                    initial={{ opacity: 0, y: 20 }}
                                    whileInView={{ opacity: 1, y: 0 }}
                                    viewport={{ once: true }}
                                    transition={{ duration: 0.5, delay: index * 0.1 }}
                                    className={`${styles.main__featureCard}`}
                                >
                                    <span>Step {index + 1}</span>

                                    <figure className={`flex alignCenter justifyCenter `}>
                                        <feature.icon />
                                    </figure>
                                    <h3>{feature.title}</h3>
                                    <p>{feature.description}</p>
                                </motion.div>
                            ))}
                        </div>
                        <div className={`${styles.main__video}`}>
                            <video muted autoPlay loop playsInline width="100%" src={VirtualAssistant} />
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${styles.main__choose}`}>
                <div className="wrapper-lg">
                    <motion.header
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true }}
                        transition={{ duration: 0.5 }}

                    >
                        <h2>Why Choose OttoLeads VAs?</h2>
                        <p>
                        Our Virtual Assistants provide the perfect balance of efficiency and human connection, helping you engage with potential sellers without the hassle of hiring and training your own team.
                        </p>
                    </motion.header>
                    {/* Features Grid */}
                    <div className={`${styles.main__chooseRow} dflex`}>

                            {choose.map((choose, index) => (
                                <motion.div
                                    key={index}
                                    initial={{ opacity: 0, y: 20 }}
                                    whileInView={{ opacity: 1, y: 0 }}
                                    viewport={{ once: true }}
                                    transition={{ duration: 0.5, delay: index * 0.1 }}
                                    className={`${styles.main__chooseCard}`}
                                >
                                   
                                    <figure className={`flex alignCenter justifyCenter `}>
                                        <choose.icon />
                                    </figure>
                                    <h3>{choose.title}</h3>
                                    <p>{choose.description}</p>
                                </motion.div>
                            ))}
                        
                    </div>
                </div>
            </div>
        </section>
    )
}

export default VAWorkflowSection;
