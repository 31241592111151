import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import ToggleMenu from "../../organisms/toggleMenu/ToggleMenu";
import Logo from "../Logo";
import NavLeft from "../navLeft/NavLeft";
import NavRight from "../navRight/NavRight";
import { getProfileInfo } from "../profileMenu/selectors";
import styles from "./Header.module.scss";
import {
  MEDIA_TYPES,
  MediaContext,
  MediaContextProps,
} from "../../context/MediaContext";
import { UserCompletedStep } from "../../organisms/auth/enum";
import { setAuthGuardToken } from "../../organisms/authroute/action";
import { resetCampaignState } from "../../organisms/campaignTable/reducer";
import { resetCreateCampaignState } from "../../organisms/createCampaign/reducer";
import { resetAuthState } from "../../organisms/auth/reducer";
import { resetPropertySearchState } from "../../organisms/propertySearchFilter/reducer";
import { resetCreateListState } from "../../organisms/createList/reducer";
import { resetCampaignDetailState } from "../../pages/detail/reducer";
import { resetPropertyDetailsState } from "../../pages/property/property.reducer";
import { resetProfileInfoState } from "../profileMenu/reducer";
import { resetCreateLeadState } from "../../organisms/addLeads/reducer";
import { resetLeadState } from "../../organisms/leadsTable/reducer";
import { resetMyListsTableState } from "../../organisms/listsTable/reducer";
import { resetSignupForEarlyAccessState } from "../../organisms/earlyAccess/reducer";
import { resetNotificationState } from "../../organisms/notifications/reducer";
import { resetUploadListState } from "../../organisms/uploadList/reducer";
import Button from "../../atoms/Button";
import { fetchSubscriptions } from "../../organisms/billing/sagaActions";
import UpgradeModal from "../../organisms/upgradeModal/UpgradeModal";
import { getSubscriptions } from "../../organisms/billing/selectors";
import { UpgradeModalParentType } from "../../organisms/upgradeModal/enum";
import { signOut } from "../../services/cognitoService";
import { resetBillingState } from "../../organisms/billing/reducer";
import { ArrowLeft } from "lucide-react";

const Header = ({ loggedIn }: { loggedIn?: boolean }) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isHomePage = !localStorage.getItem("accessToken");
  const { media } = useContext(MediaContext) as MediaContextProps;
  const [scrollTopData, setScrollTopData] = useState("");
  const [openPlanUpgradeModal, setOpenPlanUpgradeModal] = useState(false);
  const profileInfo = useSelector((state) => getProfileInfo(state));
  const [upgradSubscriptionProduct, setUpgradSubscriptionProduct] =
    useState(null);
  const sunscriptions = useSelector((state) => getSubscriptions(state));
  const [searchParams, setSearchParams] = useSearchParams();
  const upgradeNowFlag = searchParams.get("upgrade-now");
  const excludedPaths = ["/verification", "/verify-email", "/forgot-password"];

  const logout = () => {
    signOut();
    dispatch(setAuthGuardToken(false));
    dispatch(resetCampaignState({}));
    dispatch(resetCreateCampaignState({}));
    dispatch(resetAuthState({}));
    dispatch(resetSignupForEarlyAccessState({}));
    dispatch(resetNotificationState({}));
    dispatch(resetMyListsTableState({}));
    dispatch(resetUploadListState({}));
    dispatch(resetLeadState({}));
    dispatch(resetCreateLeadState({}));
    dispatch(resetProfileInfoState({}));
    dispatch(resetCampaignDetailState({}));
    dispatch(resetPropertyDetailsState({}));
    dispatch(resetCreateListState({}));
    dispatch(resetPropertySearchState({}));
    dispatch(resetBillingState({}));
    navigate("/");
  };

  const upgradeNow = () => {
    setOpenPlanUpgradeModal(true);
    setUpgradSubscriptionProduct(sunscriptions?.creditSubData);
  };

  useEffect(() => {
    if (profileInfo?.id) {
      dispatch(fetchSubscriptions({}));
      if (window?.Tawk_API && window?.Tawk_API?.setAttributes) {
        window?.Tawk_API?.setAttributes({
          email: profileInfo?.email,
          user_id: profileInfo?.id,
          user_hash: profileInfo?.crypto_hashId,
        });
      }
    }
  }, [profileInfo]);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY < 15) {
        setScrollTopData("");
      } else {
        setScrollTopData("scrolled");
      }
    });
  }, []);

  useEffect(() => {
    if (
      (!localStorage.getItem("accessToken") && !upgradeNowFlag) ||
      (!localStorage.getItem("accessToken") && upgradeNowFlag)
    ) {
      localStorage.setItem("upgrade-now", "false");
      setOpenPlanUpgradeModal(false);
    } else if (upgradeNowFlag && localStorage.getItem("accessToken")) {
      setOpenPlanUpgradeModal(true);
    } else if (localStorage.getItem("upgrade-now") === "true") {
      setOpenPlanUpgradeModal(true);
      localStorage.setItem("upgrade-now", "false");
    }
  }, [upgradeNowFlag]);

  return excludedPaths.every((path) => !pathname.includes(path)) ? (
    <>
      {" "}
      <div
        className={` dflex justifySpaceBetween alignCenter ${scrollTopData} ${
          styles.header
        }  ${isHomePage ? `${styles.headerBlack} header-black` : ``}`}
      >
        <div
          className={` dflex justifySpaceBetween alignCenter ${styles.headerWrapper} `}
        >
          {media !== MEDIA_TYPES.DESKTOP ? (
            <>
              <div className={` dflex alignCenter ${styles.header__left}`}>
              {/* <span role="link" className="inlineFlex"><ArrowLeft /></span> */}
              <Logo />
              </div>
              {/** TO DO need to check the condition for toggle */}
              {isHomePage &&(
                 <div className={` ${styles.mobileToggle}`} role="link">
                  <ToggleMenu
                    blackFixed={isHomePage}
                    loggedIn={Boolean(profileInfo?.id)}
                    onLogout={logout}
                    upgradeNow={upgradeNow}
                  />
                </div> 
              )}

              {profileInfo?.organisation?.stepsCompleted >
              UserCompletedStep.STEP_ONE ? (
                <div className={` ${styles.mobileToggle}`} role="link">
                  <ToggleMenu
                    blackFixed={isHomePage}
                    loggedIn={Boolean(profileInfo?.id)}
                    onLogout={logout}
                    upgradeNow={upgradeNow}
                  />
                </div>
              ) : (
                <></>
              )}
            </>
          ) : (
            <>
              <div className={` dflex alignCenter ${styles.header__left}`}>
             {/* <span role="link" className="inlineFlex"><ArrowLeft /></span>    */}
             <Logo />
                {profileInfo?.organisation?.stepsCompleted >
                UserCompletedStep.STEP_ONE ? (
                  <div className={`${styles.navLeft}`}>
                    <NavLeft
                      blackFixed={isHomePage}
                      loggedIn={Boolean(profileInfo?.id)}
                      upgradeNow={upgradeNow}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
              {!profileInfo?.id ||
              profileInfo?.organisation?.stepsCompleted >
                UserCompletedStep.STEP_ONE ? (
                <div className={` ${styles.header__right} ${isHomePage && styles.rightMenu} dflex justifySpaceBetween`}>
                  <NavRight
                    blackFixed={isHomePage} 
                    loggedIn={Boolean(profileInfo?.id)}
                    onLogout={logout}
                    upgradeNow={upgradeNow}
                  />
                </div>
              ) : (
                <></>
              )}
            </>
          )}
          {profileInfo?.organisation?.stepsCompleted <
            UserCompletedStep.STEP_TWO && (
            <>
              <Button label="Log out" className="primary" action={logout} />
            </>
          )}
        </div>
      </div>
      {openPlanUpgradeModal && (
        <UpgradeModal
          handleClose={() => {
            setOpenPlanUpgradeModal(false);
            setUpgradSubscriptionProduct(null);
          }}
          open={openPlanUpgradeModal}
          product={upgradSubscriptionProduct}
          parentType={UpgradeModalParentType.Header}
        ></UpgradeModal>
      )}
    </>
  ) : (
    <></>
  );
};

export default Header;
