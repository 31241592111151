import { useState } from "react";
import Logo from "../../assets/images/logo.png";
import styles from "./PricingSec.module.scss";
import CheckCircle from "../../assets/icons/checkCircle";
import Button from "../../atoms/Button";
import { pricingSec } from "../../pages/home/HomeConstants";
import Register from "../../organisms/auth/Register";
import { FEATURES_LIST } from "./constants";
import CheckGradient from "../../assets/icons/checkCircleGradient";

const PricingSec = ({ handleSignUp }: any) => {
    const [selectedPlan, setSelectedPlan] = useState({
        price: 1176,
        type: "Annually",
        name: "year",
    });
    const handleBilling = (type: string) => {
        if (type === "Monthly") {
            setSelectedPlan({ price: 98, type: "Monthly", name: "month" });
        } else {
            setSelectedPlan({ price: 1176, type: "Annually", name: "year" });
        }
    };

    return (
        <div className={` ${styles.pricing}`}>
            <div className={` ${styles.pricing__header}`}>
                <h2>Pricing Plans</h2>
                <p>
                    Simple, transparent pricing that grows with you. Choose the features
                    that are right for you to scale your business.
                </p>
                <ul
                    className={`inlineFlex alignCenter justifyCenter ${styles.pricing__toggle}`}
                >
                    <li>
                        <span
                            className={`flex alignCenter justifyCenter ${selectedPlan.type === "Monthly" ? styles.active : ""
                                }`}
                            onClick={() => handleBilling("Monthly")}
                        >
                            Monthly billing
                        </span>
                    </li>
                    <li>
                        <span
                            className={`flex alignCenter justifyCenter ${selectedPlan.type === "Annually" ? styles.active : ""
                                } `}
                            onClick={() => handleBilling("Annually")}
                        >
                            Annual billing
                        </span>
                    </li>
                </ul>
            </div>
            <div className={` ${styles.pricing__prices} `}>
                <div className="wrapper-lg">
                    <div className={` ${styles.pricing__Cards} dflex justifyCenter`}>
                        <div className={` ${styles.pricing__cardsLeft} `}>
                            <div className={` ${styles.cardGray} `}>
                                <header>
                                    <h3>OttoLeads Data</h3>
                                    <aside>
                                        <h2> <sup>$</sup>{selectedPlan.price} </h2>
                                        <span>per {selectedPlan.name}</span>
                                    </aside>
                                    <article></article>
                                </header>

                                <ul>
                                    {FEATURES_LIST.map((feature, index) => (
                                        <li key={index} className="flex alignCenter">
                                            <CheckGradient />
                                            {feature}
                                        </li>
                                    ))}
                                </ul>
                                <footer className="flex justifyEnd">
                                    <Button
                                        className="primary"
                                        label="7 day free trial"
                                        action={handleSignUp}
                                    />
                                </footer>
                            </div>

                        </div>
                        <div className={`dflex ${styles.pricing__cardsRight} `}>
                            {pricingSec.prices.map((item, index) => (
                                <div className={` ${styles.pricingCard}`} key={index}>
                                    <div className={` ${styles.pricingCard__header}`}>
                                        <header>ADD ON</header>
                                        <h2>{item.title}</h2>
                                        <article>
                                            <h3><sup>$</sup>{item.price}</h3>
                                            <p>starting at per month</p>
                                        </article>
                                    </div>
                                    <div className={` ${styles.pricingCard__info}`}>
                                        <ul>
                                            {item.benefits.map((benefit, index) => (
                                                <li key={index} className="flex">
                                                    <CheckCircle color="#079455" /> {benefit}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                    <div className={`flex justifyEnd ${styles.pricingCard__btn}`}>
                                        <Button
                                            className="outline"
                                            label="Learn more"
                                            action={handleSignUp}
                                        />
                                    </div>
                                </div>
                            ))}

                        </div>
                    </div>
                    <div className={` ${styles.pricing__CardsSkip} dflex justifyCenter`}>
                        <div className={` ${styles.pricing__cardsSkipLeft} `}>
                            <div
                                className={`dflex justifySpaceBetween ${styles.cardGrayBottom} `}
                            >
                                <div className={` ${styles.cardGrayBottom__left} `}>
                                    <header>Nationwide</header>
                                    <h2>Skip Tracing </h2>
                                    <article>
                                        <h3>10<sup>¢</sup> </h3>
                                        <p>per property record</p>
                                    </article>
                                </div>
                                <div className={` ${styles.cardGrayBottom__left} `}>
                                    <header>Add Users</header>
                                    <h2>Team Support</h2>
                                    <article>
                                        <h3><sup>$</sup>20</h3>
                                        <p>per seat</p>
                                    </article>
                                </div>
                            </div>
                        </div>
                        <div className={`dflex ${styles.pricing__cardsSkipRight} `}>
                            <h2>Enterprise Solutions</h2>
                            <p>We specialize in developing enterprise-grade AI solutions tailored to your business needs.
                                Our advanced enterprise systems support both inbound and outbound calling,
                                enhancing customer engagement and streamlining operations.
                            </p>
                            <div className={`flex justifyEnd ${styles.pricing__cardsSkipBtn} `}>
                                <Button
                                    className="primary"
                                    label="Contact Us"
                                    action={handleSignUp}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default PricingSec;
