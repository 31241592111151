
const CheckCircle = ({color}:{color?:string}) => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
                <path d="M9.06185 14.5001L12.6868 18.1251L19.9368 10.8751M26.5827 14.5001C26.5827 21.1735 21.1728 26.5834 14.4993 26.5834C7.82591 26.5834 2.41602 21.1735 2.41602 14.5001C2.41602 7.82664 7.82591 2.41675 14.4993 2.41675C21.1728 2.41675 26.5827 7.82664 26.5827 14.5001Z"
                    stroke={color ? color : 'currentColor'}
                    strokeWidth="2.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </>
    );
};

export default CheckCircle;


