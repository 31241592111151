import { Lightbulb, Phone, RefreshCcw } from "lucide-react";
import ZapFast from "../../assets/icons/zapFast";

export const title = "Why Investors, Agents & Wholesalers Choose OttoLeads";
export const heading = ` Most real estate platforms are outdated, overpriced, and slow. OttoLeads is built for speed, accuracy, and
              results.`;
export const benefits = [
  {
    icon: RefreshCcw,
    title: "Most Up-to-Date Property Data",
    description: "Constantly refreshed for maximum accuracy.",
    personImage: "https://randomuser.me/api/portraits/women/29.jpg",
  },
  {
    icon: Lightbulb,
    title: "More Insights, Fewer Gaps",
    description:
      "Tax records, mortgage and sales history, owner portfolios, occupancy and rental data, and much more.",
    personImage: "https://randomuser.me/api/portraits/men/32.jpg",
  },
  {
    icon: ZapFast,
    title: "Faster Search, Faster Decisions",
    description: "Find motivated sellers before anyone else.",
    personImage: "https://randomuser.me/api/portraits/women/68.jpg",
  },
  {
    icon: Phone,
    title: "Built-In Skip Tracing & AI Calling",
    description:
      "Leverage AI to connect instantly with leads making up to 1.7 million calls a day.",
    personImage: "https://randomuser.me/api/portraits/men/75.jpg",
  },
];

export const testimonialMessage = `OttoLeads has completely transformed how I find and connect with motivated sellers. The data is
                  always accurate, and the built-in tools save me hours every day.`;

export const testimonialName = "Jacob Hollingsworth";
export const testimonialTitle = "Real Estate Investor, Cleveland OH";

export const bottomText = "No more outdated spreadsheets. Just better data—and better deals.";