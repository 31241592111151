import BlogCard from "./blogCard/BlogCard";
import styles from "./Blog.module.scss";

const Blog = () => {

    return <div className={` ${styles.blog}`} >
        <div className="wrapper-lg">
            <div className={`dflex ${styles.blog__row}`} >
                {[...Array(10)].map((x, i) =>
                    <div className={` ${styles.blog__col}`} > <BlogCard key={i} /></div>
                )}
            </div>
        </div>
    </div>;
}
export default Blog;