import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Shield from "../../assets/icons/shield";
import Button from "../../atoms/Button";
import DropDown from "../../atoms/DropDown";
import Pill from "../../atoms/Pill";
import PriceCard from "../../components/priceCard/PriceCard";
import PriceCardFooter from "../../components/priceCard/PriceCardFooter";
import PriceCardHeader from "../../components/priceCard/PriceCardHeader";
import PriceCardInfo from "../../components/priceCard/PriceCardInfo";
import { formartNumberAsLocalString } from "../../utils/utils";
import { getProductByInterval } from "../billing/utils";
import { vaPlanSchema } from "./validation";
import { VA_CALLING_DAYS_CONSTANTS, VA_CALLING_WEEK_CONSTANTS, VA_HOURS_CONSTANTS } from "./constant";
import { TimeIcon } from "@mui/x-date-pickers";
import Calendar from "../../assets/icons/calender";
import Calculator from "../../assets/icons/calculator";
import Call from "../../assets/icons/call";
import InfoCircle from "../../assets/icons/infoCircle";
import CouponCard from "../../pages/couponCard/CouponCard";

const VaSubscription = ({
  selectedPricing,
  setSelectedPricing,
  vaAccessProduct,
  handlePurchaseModal,
  setCurrentCredits,
  upgradeNow,
  currentCredits,
  creditCount,
  handleCoupon,
  validateButtonLoading,
  couponStatus,
  setCouponStatus,
  updatedPricing,
  handleRemoveCoupon
}: any) => {
  const {
    formState: { errors },
    control,
    setValue,
    watch,
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(vaPlanSchema),
  });
  const [vaPlanOptions, setVaPlanOptions] = useState<any[]>([]);

  useEffect(() => {
      const options = vaAccessProduct.map((product: any) => ({
        value: product.id,
        label: product.productName,
      }));
      setVaPlanOptions(options ?? []);
    if (creditCount) {
      const selectedOption = vaAccessProduct.filter(
            (product: any) => product.creditCount > creditCount
          );
      options?.length > 0 &&
        setValue(
          "selectedVaProduct",
          selectedOption?.length > 0
            ? selectedOption[0].id
            : options[options?.length - 1].value
        );
    } else {
      options?.length > 0 &&
        setValue("selectedVaProduct", options[0].value);
    }
  }, [vaAccessProduct]);

  useEffect(() => {
    if (watch("selectedVaProduct")) {
      const product = vaAccessProduct.find(
        (product: any) => product.id === watch("selectedVaProduct")
      );
      const selected = getProductByInterval(
        watch("annualPricing") ?? false,
        product
      );
      setSelectedPricing(selected);
      const currentCredit: string =
        vaAccessProduct
          .filter(
            (product: any) => product.id === watch("selectedVaProduct")
          )
          .map((product: any) => String(product.creditCount))[0] || "";
      if (upgradeNow) {
        setCurrentCredits(currentCredit);
      }
    }
  }, [watch("annualPricing"), watch("selectedVaProduct")]);

  return (
    <PriceCard>
      <PriceCardHeader>
        <div className={`flex justifySpaceBetween alignCenter cardHeader `}>
          <div>
            <h2>
              VA Calling <Pill label={`Billed Monthly`} className="completed" />
            </h2>
            {}
            <div className={` cardSwitch flex alignCenter`}>
              <p>Efficient Outreach, Effortless Results, Every Shift.</p>
            </div>
          </div>
          <div className={`cardHeader__right `}>
            <div className="flex">
              
              <div className={`cardPrice`}>
                <small>$</small>
                {selectedPricing?.unitAmount}
              </div>
            </div>
            <p>
              per month
            </p>
          </div>
        </div>
      </PriceCardHeader>
      <PriceCardInfo>
        <div className={`dflex justifySpaceBetween alignCenter cardInfo `}>
          <header className="dflex justifySpaceBetween alignCenter selectFull">
            {/* <div>
              <div className="credits">
                <h3>CALLS </h3>
                <span>/ month</span>{" "}
              </div>
              <p>1 month = {currentCredits} Calls</p>
            </div> */}
            <DropDown
              options={vaPlanOptions ?? []}
              label=""
              control={control}
              name="selectedVaProduct"
              errors={errors}
              placeholder="Select VA Product"
              disabled={couponStatus.includes("Applied")}
            ></DropDown>
          </header>
          <div className="time-breakdown">
            <h5>Time Breakdown</h5>
            <ul>
                <li>
               <TimeIcon /> <strong>{VA_HOURS_CONSTANTS[currentCredits]} hours per month</strong> &#xF7; {VA_CALLING_DAYS_CONSTANTS[currentCredits]} working days = <strong><span>8 hours per day</span></strong>
               </li>
               <li>
            <Calendar/> <strong>{VA_CALLING_WEEK_CONSTANTS[currentCredits]} Week</strong> = <strong><span>{VA_CALLING_DAYS_CONSTANTS[currentCredits]} Days of VA calling</span></strong>
             </li>
             <li>
            <Call/> This means your VA will be available for calls 8 hours each working day, 5 days a week,  totaling {VA_HOURS_CONSTANTS[currentCredits]}  hours per month.
             </li>
             <li className="alignStart">
                <InfoCircle/>
                <div>
                <h5>Please Note:</h5>
                <p>A separate subscription to a third-party dialer is required and is not included in the listed price. The average cost for this service is approximately $1 per day.</p> 
                </div>
             </li>
            </ul>
            
          </div>
        </div>
        <CouponCard
          loading={validateButtonLoading}
          couponStatus={couponStatus}
          onSubmit={handleCoupon}
          setCouponStatus={setCouponStatus}
          handleRemoveCoupon={handleRemoveCoupon}
          couponSelected ={updatedPricing?.id}
        />
        <div className={`flex justifySpaceBetween totalRow borderTop`}>
          <p>Actual Price :</p>
          <div className={` right`}>
            <p>${selectedPricing?.actualPrice}</p>
          </div>
        </div>
        {selectedPricing?.taxAmount != 0 && (
          <div className={`flex justifySpaceBetween totalRow`}>
            <p>Tax</p>
            <div className={` right`}>
              <p>${updatedPricing? updatedPricing?.pricing?.breakdown?.taxAmount : selectedPricing?.taxAmount}</p>
            </div>
          </div>
        )}
        {updatedPricing?.pricing?.breakdown?.discountAmount && (
          <div className={`flex justifySpaceBetween totalRow`}>
            <p>Coupon Discount ({updatedPricing?.id}):</p>
            <div className={` right`}>
              <p>${updatedPricing?.pricing?.breakdown?.discountAmount}</p>
            </div>
          </div>
        )}
        <div className={`flex justifySpaceBetween totalRow`}>
          <p>Total due today :</p>
          <div className={` right`}>
            <p>
              <strong>
                $
                
                {updatedPricing
                  ? formartNumberAsLocalString(
                      +(updatedPricing?.pricing?.breakdown?.finalAmount ?? 0),
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }
                    )
                  :
                formartNumberAsLocalString(
                 +(selectedPricing?.unitAmount ?? 0),
                  {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }
                )}
              </strong>
            </p>
            <p className={` gray`}>
              $
              {formartNumberAsLocalString(+(selectedPricing?.unitAmount ?? 0), {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}{" "}
              / {selectedPricing?.interval} after
            </p>
          </div>
        </div>
      </PriceCardInfo>
      <PriceCardFooter>
            <Button
            label={"Confirm subscription and pay now"}
            postfix={<Shield />}
            className="primary animated full xl"
            action={handlePurchaseModal}
          ></Button>
      </PriceCardFooter>
    </PriceCard>
  );
};
export default VaSubscription;
