import { useEffect, useRef, useState } from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import { HomePropertyCard } from "../homePropertyCard/HomePropertyCard";
import styles from "./ScrollingProperties.module.scss";
import Button from "../../../atoms/Button";
import { properties, propertiesPartTwo } from "./constants";

// Duplicate arrays for each row
const row1 = [...properties, ...properties, ...properties, ...properties];
const row2 = [
  ...properties.reverse(),
  ...properties.reverse(),
  ...properties.reverse(),
  ...properties.reverse(),
];
const row3 = [...propertiesPartTwo, ...propertiesPartTwo, ...propertiesPartTwo, ...propertiesPartTwo];

const  ScrollingProperties=({handleSignUp}:any)=> {
  const heroSectionRef = useRef<HTMLDivElement>(null)
  const propertiesRef = useRef<HTMLDivElement>(null)
    const { scrollYProgress } = useScroll({
        target: propertiesRef,
        offset: ["start start", "end start"],
    })
    const [scrollY, setScrollY] = useState(0)
    const [scrollThreshold, setScrollThreshold] = useState(200)
    const [windowHeight, setWindowHeight] = useState(0)
    const [heroHeight, setHeroHeight] = useState(0)

    const row1X = useTransform(scrollYProgress, [0, 1], ["0%", "-50%"])
    const row2X = useTransform(scrollYProgress, [0, 1], ["0%", "50%"])
    const row3X = useTransform(scrollYProgress, [0, 1], ["0%", "-50%"])


      // Calculate scroll progress
  const calculateScrollProgress = () => {
    if (scrollY <= scrollThreshold) {
      return 0 // Not scrolled to threshold yet
    }

    // Calculate progress over a longer distance for a more gradual animation
    const scrollDistance = windowHeight * 1.2
    const progress = Math.min((scrollY - scrollThreshold) / scrollDistance, 1)
    return progress
  }

 

  // Calculate the transform for the hero content
  const calculateHeroTransform = () => {
    const progress = calculateScrollProgress()
    // Move the hero content up
    return `translateY(${-progress * windowHeight * 1.2}px)`
  }

  useEffect(() => {
    const handleScroll = () => {
      // Use requestAnimationFrame for smoother scrolling
      requestAnimationFrame(() => {
        setScrollY(window.scrollY)
      })
      handleResize()
    }

    const handleResize = () => {
      setWindowHeight(window.innerHeight || 800)

      // Update hero height on resize
      if (heroSectionRef.current) {
        setHeroHeight(heroSectionRef.current.offsetHeight)
      }
    }

    // Initial measurement of hero height
    if (heroSectionRef.current) {
      setHeroHeight(heroSectionRef.current.offsetHeight)
    }

    window.addEventListener("scroll", handleScroll, { passive: true })
    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("scroll", handleScroll)
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return (
    <div ref={heroSectionRef} className={`dflex  ${styles.banner}`}  >
      <div className={` ${styles.banner__text} flex `} >
        <div className={` ${styles.banner__info}`}
        style={{
          transform: calculateHeroTransform(),
        }}
       >
          <h1 >
            Find Leads
            <br/>
            Connect with Sellers.
            <br />
            <span  >Close Deals.</span>
          </h1>
          <p>
            Your all-in-one solution for property data, skip tracing, AI
            calling, human VAs, and a CRM all built for Real Estate
            Professionals.
          </p>
          <div className={`flex  ${styles.banner__button}`}>
            <Button className="primary" label="7 days Free Trial"  action={handleSignUp}  />
            <Button className="outline " label="Listen to Demo" />
          </div>
        </div>
      </div>
      <div className={`${styles.banner__cards}`} ref={propertiesRef}>
   
          <motion.div
                    initial={{ y: -200, x:-100, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.8, ease: [0.16, 1, 0.3, 1] }}
                    className={`flex ${styles.banner__cardsRow}`}
                    style={{ x: row1X }}>
            {row1.map((property, i) => (
              <motion.div
                key={i}
                initial={{ y: -300, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{
                  duration: 0.8,
                  delay: i * 0.05,
                  ease: [0.16, 1, 0.3, 1],
                }}
              >
                <HomePropertyCard {...property} />
              </motion.div>
            ))}
          </motion.div>
      
      
          <motion.div
                    initial={{ y: -250,x:100, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{
                        duration: 0.8,
                        delay: 0.1,
                        ease: [0.16, 1, 0.3, 1],
                    }}
                    className={`flex ${styles.banner__cardsRow}`}
                    style={{ x: row2X }}>
            {row2.map((property, i) => (
              <motion.div
                key={i}
                initial={{ y: -350, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{
                  duration: 0.8,
                  delay: 0.1 + i * 0.05,
                  ease: [0.16, 1, 0.3, 1],
                }}
              >
                <HomePropertyCard {...property} />
              </motion.div>
            ))}
          </motion.div>
      
          <motion.div
                    initial={{ y: -300, x:-100,opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{
                        duration: 0.8,
                        delay: 0.2,
                        ease: [0.16, 1, 0.3, 1],
                    }}
                    className={`flex ${styles.banner__cardsRow}`}
                    style={{ x: row3X }}>
            {row3.map((property, i) => (
              <motion.div
                key={i}
                initial={{ y: -400, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{
                  duration: 0.8,
                  delay: 0.2 + i * 0.05,
                  ease: [0.16, 1, 0.3, 1],
                }}
              >
                <HomePropertyCard {...property} />
              </motion.div>
            ))}
          </motion.div>
      </div>
    </div>
  );
}

export default ScrollingProperties;