import { motion } from "framer-motion";
import styles from "./AIBestChoice.module.scss";
import Button from "../../atoms/Button";
import { Check, Cross, X } from "lucide-react";
import AICallingTable from "../../organisms/aiCallingTable/AICallingTable";
const AIBestChoice = ({ handleSignUp, title, subtitle, footerText, bgGradient, hideButton ,tableData}: any) => {
    return (
        <section className={`dflex justifyCenter alignCenter  ${styles.choices} ${bgGradient && styles.bgGradient}`}>
            <div className={`wrapper-lg`}>
                <div className={`${styles.choices__wrapper}`}>
                    <motion.header
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true }}
                        transition={{ duration: 0.5 }}
                    >
                        <h2>{title}</h2>
                        <p>{subtitle}</p>
                    </motion.header>
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true }}
                        transition={{ duration: 0.5, delay: 0.5 }}
                    >
                        <AICallingTable data={tableData} />
                    </motion.div>
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true }}
                        transition={{ duration: 0.5, delay: 0.5 }}
                        className={`${styles.choices__text} `}
                    >
                        <p> {footerText} </p>
                        {!hideButton &&
                            <div className="dflex justifyCenter">
                                <Button
                                    className="primary"
                                    label={"Start 7 Days Free Trial"}
                                    action={handleSignUp}
                                />
                            </div>
                        }
                    </motion.div>
                </div>
            </div>
        </section>
    );
};

export default AIBestChoice;