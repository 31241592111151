
const VerifyCheck = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M21.8011 9.99999C22.2578 12.2413 21.9323 14.5714 20.879 16.6018C19.8256 18.6322 18.108 20.24 16.0126 21.1573C13.9172 22.0746 11.5707 22.2458 9.3644 21.6424C7.15807 21.0389 5.22529 19.6974 3.88838 17.8414C2.55146 15.9854 1.89122 13.7272 2.01776 11.4434C2.14431 9.15952 3.04998 6.98808 4.58375 5.29116C6.11752 3.59424 8.18668 2.47442 10.4462 2.11844C12.7056 1.76247 15.0189 2.19185 17.0001 3.33499" stroke="url(#paint0_linear_496_3653)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M9 11L12 14L22 4" stroke="url(#paint1_linear_496_3653)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <defs>
                <linearGradient id="paint0_linear_496_3653" x1="13.1292" y1="-24.0034" x2="-10.1216" y2="-17.2268" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#11DDFF" />
                    <stop offset="0.284962" stop-color="#2FA1FF" />
                    <stop offset="0.546739" stop-color="#3C88FF" />
                    <stop offset="0.809238" stop-color="#5A4EFF" />
                    <stop offset="1" stop-color="#7022FF" />
                </linearGradient>
                <linearGradient id="paint1_linear_496_3653" x1="16.2324" y1="-9" x2="1.89401" y2="-3.56722" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#11DDFF" />
                    <stop offset="0.284962" stop-color="#2FA1FF" />
                    <stop offset="0.546739" stop-color="#3C88FF" />
                    <stop offset="0.809238" stop-color="#5A4EFF" />
                    <stop offset="1" stop-color="#7022FF" />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default VerifyCheck;