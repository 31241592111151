import { motion } from "framer-motion";
import { Search, Filter } from "lucide-react";
import styles from "./AdvancedSearchSection.module.scss";
import Button from "../../atoms/Button";
import mapVideo from "../../assets/videos/map-video.mp4";
import { bottomText, heading, listItems, searchListItems, title } from "./constants";

const AdvancedSearchSection = ({ handleSignUp }: any) => {
  return (
    <section className={`${styles.search}`}>
      <div className={`${styles.search__header}`}>
        <div className="wrapper-lg">
          <motion.header
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
          >
            <h2>{title}</h2>
            <p>{heading}</p>
          </motion.header>
        </div>
        <div className={`${styles.search__video}`}>
          <video muted autoPlay loop playsInline width="100%" src={mapVideo} />
        </div>
      </div>
      <div className={`${styles.search__section}`}>
        <div className="wrapper-lg">
          <div className={`${styles.search__row} dflex`}>
            {/* Search Your Way Column */}
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5 }}
              className={`${styles.search__box}`}
            >
              <div className={`${styles.search__boxHeader} flex alignCenter`}>
                <span className="flex alignCenter justifyCenter">
                  <Search />
                </span>
                <h3> Search Your Way</h3>
              </div>

              <ul>
                {searchListItems.map((item, index) => (
                  <li key={index} className="flex alignStart">
                    <span className="flex alignCenter justifyCenter">
                      {index + 1}
                    </span>
                    {item}
                  </li>
                ))}
              </ul>
              {/* Search Example */}
              <div className={`${styles.search__eg} flex alignCenter`}>
                <Search size={16} />
                Portland, OR • 3+ beds • Vacant
              </div>
            </motion.div>

            {/* Filter for Maximum Precision Column */}
            <motion.div
              initial={{ opacity: 0, x: 20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.2 }}
              className={`${styles.search__box}`}
            >
              <div className={`${styles.search__boxHeader} flex alignCenter`}>
                <span className="flex alignCenter justifyCenter">
                  <Filter />
                </span>{" "}
                <h3> Filter for Maximum Precision</h3>
              </div>
              <ul>
                {listItems.map((item, index) => (
                  <li key={index} className="flex alignStart">
                    <span className="flex alignCenter justifyCenter">
                      {index + 1}
                    </span>
                    {item}
                  </li>
                ))}
              </ul>
              <div className={`${styles.search__eg} dflex alignCenter`}>
                <span className="badge completed">Vacant</span>
                <span className="badge scheduled">Pre-Foreclosure</span>
                <span className="badge calling">High Equity</span>
              </div>
            </motion.div>
          </div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.4 }}
            className={`${styles.search__footer}`}
          >
            <p>{bottomText}</p>
            <div className="flex justifyCenter">
              <Button
                className="primary"
                label="7 Days Free Trial"
                action={handleSignUp}
              />
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default AdvancedSearchSection;
