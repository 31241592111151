import { Bot, Phone, Star } from "lucide-react";
import Call from "../../assets/icons/call";
import ZapFast from "../../assets/icons/zapFast";
export const title="OttoLeads AI Calling Software";
export const headingOne="Automated AI Outreach for Real Estate"
export const subHeading="More Calls. More Leads. "
export const subHeadingTwo="More Deals."
export const headingTwo=`OttoLeads AI Calling connects with motivated sellers, cash buyers, and agents on your behalf. No more manual dialing. No more wasted time. Just instant, scalable outreach that gets you in front of sellers first—before your competition.`

export const features=[{
    title:"Make up to 1.7M calls daily. AI calls at scale, so you don't have to.",
    icon:Phone
},
{
    title:"Human-like conversations. Our AI qualifies leads in real-time like a professional representative.",
    icon:Bot
},
{
    title:"Lead scoring & prioritization lets you focus only on the hottest opportunities.",
    icon:Star
},
{
    title:"Optimized for high answer rates. AI dials sellers at the best times with area code matching.",
    icon:ZapFast
}

]                                