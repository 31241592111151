
const ListFlat = () => {
    return (
        <>
<svg xmlns="http://www.w3.org/2000/svg" width="30" height="16" viewBox="0 0 30 16" fill="none">
<path d="M8.79964 1.1333H8.13298C4.45108 1.1333 1.46631 4.11807 1.46631 7.79997C1.46631 11.4819 4.45108 14.4666 8.13298 14.4666H10.7996C14.4815 14.4666 17.4663 11.4819 17.4663 7.79997M20.7996 14.4666H21.4663C25.1482 14.4666 28.133 11.4819 28.133 7.79997C28.133 4.11807 25.1482 1.1333 21.4663 1.1333H18.7996C15.1177 1.1333 12.133 4.11807 12.133 7.79997" 
stroke="currentColor" 
strokeOpacity="0.65" 
strokeWidth="2" 
strokeLinecap="round" 
strokeLinejoin="round"/>
</svg>
        </>
    );
};

export default ListFlat;




