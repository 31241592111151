import React from 'react'
import { motion } from "framer-motion";
import Button from "../../atoms/Button";
import styles from "./VAHeroSection.module.scss";
import { Star } from 'lucide-react';
import { headingOne, headingTwo, title, headingThree } from './constants';
import VaVideo from "../../assets/videos/va-video.mp4";

const VAHeroSection = ({handleSignUp,handleLearnMore}:any) => {
  return (
   <>
    <section className={`dflex justifyCenter alignCenter  ${styles.hero}`}>
      <div className={`wrapper-lg`}>
        <div className={`dflex alignCenter ${styles.hero__main}`}>
          <div className={` ${styles.hero__left}`}>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.1 }}
              className={` ${styles.hero__badge} flex alignCenter`}
            >
              <Star />
              <span>{title}</span>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.2 }}
              className={` ${styles.hero__content}`}
            >
              <h1 dangerouslySetInnerHTML={{ __html: headingOne }} />
              <h2>{headingTwo}</h2>

              <p>{headingThree}</p>

            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.6 }}
              className={`flex ${styles.hero__btn}`}
            >
              <Button
                className="borderGradient"
                label="Learn More"
                action={handleLearnMore}
              />
               <Button
                className="primary"
                label="Sign Up"
                action={handleSignUp}
              />
            </motion.div>
          </div>

          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 1, delay: 0.3 }}
            className={` ${styles.hero__right}`}
          >
            <video
              muted
              autoPlay
              loop
              playsInline
              width="100%"
              src={VaVideo}
            />
          </motion.div>
        </div>
      </div>
    </section>
   </>
  )
}

export default VAHeroSection