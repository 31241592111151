
const Building = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                <g clipPath="url(#clip0_12832_816)">
                    <path d="M6.5 5.5H8.9C9.46005 5.5 9.74008 5.5 9.95399 5.60899C10.1422 5.70487 10.2951 5.85785 10.391 6.04601C10.5 6.25992 10.5 6.53995 10.5 7.1V10.5M6.5 10.5V3.1C6.5 2.53995 6.5 2.25992 6.39101 2.04601C6.29513 1.85785 6.14215 1.70487 5.95399 1.60899C5.74008 1.5 5.46005 1.5 4.9 1.5H3.1C2.53995 1.5 2.25992 1.5 2.04601 1.60899C1.85785 1.70487 1.70487 1.85785 1.60899 2.04601C1.5 2.25992 1.5 2.53995 1.5 3.1V10.5M11 10.5H1M3.25 3.5H4.75M3.25 5.5H4.75M3.25 7.5H4.75"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round" />
                    <path d="M6.5 5.5H8.9C9.46005 5.5 9.74008 5.5 9.95399 5.60899C10.1422 5.70487 10.2951 5.85785 10.391 6.04601C10.5 6.25992 10.5 6.53995 10.5 7.1V10.5M6.5 10.5V3.1C6.5 2.53995 6.5 2.25992 6.39101 2.04601C6.29513 1.85785 6.14215 1.70487 5.95399 1.60899C5.74008 1.5 5.46005 1.5 4.9 1.5H3.1C2.53995 1.5 2.25992 1.5 2.04601 1.60899C1.85785 1.70487 1.70487 1.85785 1.60899 2.04601C1.5 2.25992 1.5 2.53995 1.5 3.1V10.5M11 10.5H1M3.25 3.5H4.75M3.25 5.5H4.75M3.25 7.5H4.75" stroke="currentColor" strokeOpacity="0.2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M6.5 5.5H8.9C9.46005 5.5 9.74008 5.5 9.95399 5.60899C10.1422 5.70487 10.2951 5.85785 10.391 6.04601C10.5 6.25992 10.5 6.53995 10.5 7.1V10.5M6.5 10.5V3.1C6.5 2.53995 6.5 2.25992 6.39101 2.04601C6.29513 1.85785 6.14215 1.70487 5.95399 1.60899C5.74008 1.5 5.46005 1.5 4.9 1.5H3.1C2.53995 1.5 2.25992 1.5 2.04601 1.60899C1.85785 1.70487 1.70487 1.85785 1.60899 2.04601C1.5 2.25992 1.5 2.53995 1.5 3.1V10.5M11 10.5H1M3.25 3.5H4.75M3.25 5.5H4.75M3.25 7.5H4.75" stroke="currentColor" strokeOpacity="0.2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M6.5 5.5H8.9C9.46005 5.5 9.74008 5.5 9.95399 5.60899C10.1422 5.70487 10.2951 5.85785 10.391 6.04601C10.5 6.25992 10.5 6.53995 10.5 7.1V10.5M6.5 10.5V3.1C6.5 2.53995 6.5 2.25992 6.39101 2.04601C6.29513 1.85785 6.14215 1.70487 5.95399 1.60899C5.74008 1.5 5.46005 1.5 4.9 1.5H3.1C2.53995 1.5 2.25992 1.5 2.04601 1.60899C1.85785 1.70487 1.70487 1.85785 1.60899 2.04601C1.5 2.25992 1.5 2.53995 1.5 3.1V10.5M11 10.5H1M3.25 3.5H4.75M3.25 5.5H4.75M3.25 7.5H4.75" stroke="currentColor" strokeOpacity="0.2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M6.5 5.5H8.9C9.46005 5.5 9.74008 5.5 9.95399 5.60899C10.1422 5.70487 10.2951 5.85785 10.391 6.04601C10.5 6.25992 10.5 6.53995 10.5 7.1V10.5M6.5 10.5V3.1C6.5 2.53995 6.5 2.25992 6.39101 2.04601C6.29513 1.85785 6.14215 1.70487 5.95399 1.60899C5.74008 1.5 5.46005 1.5 4.9 1.5H3.1C2.53995 1.5 2.25992 1.5 2.04601 1.60899C1.85785 1.70487 1.70487 1.85785 1.60899 2.04601C1.5 2.25992 1.5 2.53995 1.5 3.1V10.5M11 10.5H1M3.25 3.5H4.75M3.25 5.5H4.75M3.25 7.5H4.75" stroke="currentColor" strokeOpacity="0.2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </g>
                <defs>
                    <clipPath id="clip0_12832_816">
                        <rect width="12" height="12" fill="white" />
                    </clipPath>
                </defs>
            </svg>

        </>
    );
};

export default Building;

