import testimonialImg from "../../assets/images/dillon.jpg";
import { renderCheckWithText, renderWrongWithText } from "../../organisms/aiCallingTable/AICallingTable";
export const testimonialMessage = `"Ottoleads AI has completely changed my wholesaling business. I went from never closing a deal to 
consistently closing multiple deals a month. The AI does 10/10, and the founders/ customer service team are always eager to help."`;

export const testimonialName = "Dillon J.";
export const testimonialTitle = "Real Estate Wholesaler, Birmingham AL";
export const imageUrl=testimonialImg;

export const bottomText = "Stop Chasing Leads. Let AI Do the Talking.";

export const tableData = {
    headers: ["Feature", "OttoLeads", "Mojo Dialer", "Batch Dialer"],
    rows: [
      ["AI-Driven Calls", renderCheckWithText("Yes"), renderWrongWithText("No"), renderWrongWithText("No")],
      ["Human-Like Conversations", renderCheckWithText("Yes"), renderWrongWithText("No"), renderWrongWithText("No")],
      ["Lead Prioritization & Scoring",renderCheckWithText("Yes"), renderWrongWithText("No"), renderWrongWithText("No")],
      ["Call Transcription & Insights", renderCheckWithText("Yes"), renderWrongWithText("No"), renderWrongWithText("No")],
      ["Expertly Trained Real Estate AI Agent", renderCheckWithText("Yes"), renderWrongWithText("No"),renderWrongWithText("No")],
    ],
  };

  export const tableTwoData = {
    headers: ["Feature", "OttoLeads AI Calling", "Human Callers"],
    rows: [
      ["Infinitely Scalable", renderCheckWithText("Yes"), renderWrongWithText("No")],
      ["Instant Lead Qualification", renderCheckWithText("Yes"), renderWrongWithText("No")],
      ["Cost Effective", renderCheckWithText("Yes"), renderWrongWithText("No")],
      ["Works 7 days a Week", renderCheckWithText("Yes"), renderWrongWithText("No")],
      ["Optimized for High Answer Rates",renderCheckWithText("Yes"), renderWrongWithText("No")],
    ],
  };