import React from "react";
import { Check, X } from "lucide-react";
import styles from "./AICallingTable.module.scss";
interface TableProps {
  data: {
    headers: string[];
    rows: (string | JSX.Element)[][];
  };
}

export const renderCheckWithText = (text: string) => (
  <div className={`${styles.aiCallingTable__tableTick} flex alignCenter ${text==="Yes" || text==="" ? "justifyCenter": ""} `} >
  <span className="flex alignCenter justifyCenter">
      <Check />
  </span>
  {text}
</div>
);

export const renderWithText = (text: string) => (
  <div className={`${styles.aiCallingTable__tableText} flex alignCenter`} >
  {text}
</div>
);


export const renderWrongWithText = (text: string) => (
  <div className={`${styles.aiCallingTable__tableTick} ${styles.aiCallingTable__tableTickNo} flex alignCenter justifyCenter`} >
  <span className={` flex alignCenter justifyCenter`} >
      <X />
  </span>
  {text}
</div>
);

const AICallingTable: React.FC<TableProps> = ({ data }) => {
  return (
    <div className={`${styles.aiCallingTable} `}>
      <table className="w-full border-collapse border border-gray-300">
        <thead>
          <tr className="bg-gray-100">
            {data.headers.map((header, index) => (
              <th key={index} className="border border-gray-300 px-4 py-2 text-left">
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.rows.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((cell, cellIndex) => (
                <td key={cellIndex} >
                  {cell}
                  
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AICallingTable;
