import { Tooltip } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import Zap from "../../assets/icons/zap";
import Button from "../../atoms/Button";
import { ROLE_PERMISSION_STATUS, SUBSCRITPTION_STATUS } from "../../constants/constants";
import {
    MEDIA_TYPES,
    MediaContext,
    MediaContextProps,
} from "../../context/MediaContext";
import { ToastType } from "../../molecules/toaster/enum";
import { addToast } from "../../molecules/toaster/toastSlice";
import { PermissionListEnum } from "../../organisms/auth/enum";
import Register from "../../organisms/auth/Register";
import { getSubscriptions } from "../../organisms/billing/selectors";
import EarlyAccessSignup from "../../organisms/earlyAccess/EarlyAccessSignup";
import ForgotPassword from "../../organisms/forgotPassword/ForgotPassword";
import ResetPassword from "../../organisms/resetPassword/ResetPassword";
import VerifyOtp from "../../organisms/verifyOtp/VerifyOtp";
import LearnMore from "../../pages/learnmore/LearnMore";
import Login from "../../pages/login/Login";
import { verifyViaOtp } from "../../services/auth";
import NotificationsMenu from "../notificationsMenu/NotificationsMenu";
import ProfileMenu from "../profileMenu/ProfileMenu";
import { getProfileInfo } from "../profileMenu/selectors";
import { NavRightProps } from "./interface";
import styles from "./NavRight.module.scss";
import MenuDropdown from "../menuDropdown/MenuDropdown";

const NavRight = ({
    blackFixed,
    loggedIn,
    notificationData,
    onLogout,
    upgradeNow,
}: NavRightProps) => {
    const [openDropdown, setOpenDropdown] = useState<string | null>(null);

    const toggleDropdown = (dropdownName: string) => {
        setOpenDropdown((prev) => (prev === dropdownName ? null : dropdownName));
    };
    const [openLoginModal, setOpenLoginModal] = useState<boolean>(false);
    const [openRegisterModal, setOpenRegisterModal] = useState<boolean>(false);
    const [forgotPassword, setForgotpassword] = useState<boolean>(false);
    const [resetPassword, setResetPassword] = useState<boolean>(false);
    const [openVerifyOtp, setOpenVerifyOtp] = useState<boolean>(false);
    const [openEarlyAccessModal, setOpenEarlyAccessModal] =
        useState<boolean>(false);
    const { media } = useContext(MediaContext) as MediaContextProps;

    const [email, setEmail] = useState<string>("");
    const [otpVerificationLoading, setOtpVerificationLoading] = useState(false);
    const profileInfo = useSelector((state: any) => getProfileInfo(state));
    const [learnMoreModal, setLearnMoreModal] = useState(false);
    const dispatch = useDispatch();
    const openForgotPasswordPopup = () => {
        setOpenLoginModal(false);
        setForgotpassword(true);
    };
    const sunscriptions = useSelector((state) => getSubscriptions(state));

    const handleShowToast = (
        message: string,
        type: ToastType = ToastType.success
    ) => {
        dispatch(
            addToast({
                id: new Date().getTime(),
                message: message,
                type: type,
            })
        );
    };

    const verifyAccount = async (OTP: string) => {
        try {
            setOtpVerificationLoading(true);
            const data: any = await verifyViaOtp({ email: email, OTP });
            handleShowToast(data?.message);
            setOpenLoginModal(true);
            setOpenVerifyOtp(false);
            setOtpVerificationLoading(false);
        } catch (err: any) {
            console.error(err);
            handleShowToast(err.message, ToastType.error);
            setOtpVerificationLoading(false);
        }
    };

    useEffect(() => {
        const LoginTwice = sessionStorage.getItem('LoginOpen');
        if (LoginTwice === 'true') {
            setOpenLoginModal(true)
            sessionStorage.removeItem('LoginOpen');
        }
    }, []);

    return (
        <>
        <ul
                className={` dflex alignCenter ${styles.navRight} ${loggedIn ? styles.navRightLogIn : styles.navRightNotLogIn
                    }`}
            >
                {loggedIn ? (
                    <>
                        {media !== MEDIA_TYPES.DESKTOP ? (
                            <></>
                        ) : (
                            <>
                       
                            
                            </>
                        )}
                    
                    </>
                ) : (
                    <>
                        {/* <li className={` ${styles.hasDropdown}`}>

                            <MenuDropdown title="Clients" isOpen={openDropdown === "Clients"}
                    toggleDropdown={() => toggleDropdown("Clients")}>

                                <li>
                                    <NavLink
                                        target="_blank"
                                        to="/"
                                        role="link"
                                    >
                                        Real Estate Investors
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        target="_blank"
                                        to="/"
                                        role="link"
                                    >
                                        Real Estate Agents
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        target="_blank"
                                        to="/"
                                        role="link"
                                    >
                                        Commercial Real Estate
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        target="_blank"
                                        to="/"
                                        role="link"
                                    >
                                        Land Buyers
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        target="_blank"
                                        to="/"
                                        role="link"
                                    >
                                        Mortgage Professionals
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        target="_blank"
                                        to="/"
                                        role="link"
                                    >
                                        Home & Property Services
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        target="_blank"
                                        to="/"
                                        role="link"
                                    >
                                        Contractors
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        target="_blank"
                                        to="/"
                                        role="link"
                                    >
                                        Property Managers
                                    </NavLink>
                                </li>
                            </MenuDropdown>
                        </li> */}
                        <li>
                            <NavLink className={` ${styles.firstNav}`} to="/pricing" >

                                Pricing


                            </NavLink>
                        </li>
                        <li className={` ${styles.hasDropdown}`}>
                            <MenuDropdown title="Contact"  isOpen={openDropdown === "Contact"}
                    toggleDropdown={() => toggleDropdown("Contact")}>

                                <li>
                                    <a
                                        href="javascript:void(0)"
                                        className="transparent font-secondary"
                                        onClick={() => {
                                            if (window.Tawk_API) {
                                                window.Tawk_API.maximize();
                                            }
                                        }}
                                    >
                                        Contact Sales
                                    </a>
                                </li>
                                <li>
                                    <NavLink
                                        target="_blank"
                                        to="https://ottoleads.getrewardful.com/signup"
                                        role="link"
                                    >
                                        Affiliates
                                    </NavLink>
                                </li>

                            </MenuDropdown>
                        </li>
                       
              
                    </>
                )}
            </ul>
            <ul
                className={` dflex alignCenter ${styles.navRight} ${loggedIn ? styles.navRightLogIn : styles.navRightNotLogIn
                    }`}
            >
                {loggedIn ? (
                    <>
                        {media !== MEDIA_TYPES.DESKTOP ? (
                            <></>
                        ) : (
                            <>
                       
                                <Tooltip
                                    title={
                                        profileInfo?.isSeatSubscriptionActive === false ? SUBSCRITPTION_STATUS.DisableUser :
                                            profileInfo?.userRoleName !== PermissionListEnum.ORGANISATION_OWNER
                                                ? ROLE_PERMISSION_STATUS.NoAccess
                                                : sunscriptions?.generalSubData
                                                    ? sunscriptions?.generalSubData?.lastPaymentStatus ===
                                                        "card_declined"
                                                        ? SUBSCRITPTION_STATUS.CardDecline
                                                        : ""
                                                    : profileInfo?.userRoleName !== PermissionListEnum.ORGANISATION_OWNER ? SUBSCRITPTION_STATUS.NoGeneralAccessNewUser : SUBSCRITPTION_STATUS.NoGeneralAccess
                                    }
                                >
                                    <li className={` ${styles.hideMobile}`}>
                                        <Button
                                            label="Upgrade Plan"
                                            className="outline"
                                            prefix={<Zap color="#FAC515" />}
                                            action={(e: any) => {
                                                if (
                                                    !sunscriptions?.generalSubData ||
                                                    sunscriptions?.generalSubData?.lastPaymentStatus === "card_declined" ||
                                                    profileInfo?.userRoleName !== PermissionListEnum.ORGANISATION_OWNER || profileInfo?.isSeatSubscriptionActive === false
                                                ) {
                                                    e.preventDefault();
                                                    return;
                                                }
                                                upgradeNow();
                                            }}
                                        />
                                    </li>
                                </Tooltip>
                                <li className={` ${styles.hideMobile}`}>
                                    <NotificationsMenu />
                                </li>
                            </>
                        )}
                        <li data-testid="profileMenu">
                            <ProfileMenu onLogout={onLogout} />
                        </li>
                    </>
                ) : (
                    <>
                     
                       
                        <li>
                            <a
                       
                      href="javascript:void(0)"
                                onClick={() => setOpenLoginModal(true)}
                            >
                                Log In
                                </a>
                        </li>
                        <li>
                            <Button
                                label="Sign Up"
                                className="primary"
                                action={() => setOpenRegisterModal(true)}
                            />
                        </li>
                        {/* <li>
              <Button label="Login" className="outline" action={() => setOpenLoginModal(true)} />
            </li> */}
                        {/* <li>
              <Button label="Sign up" className="primary rounded font-aeonic font-normal" action={() => setOpenRegisterModal(true)} />
            </li> */}
                    </>
                )}
            </ul>
            {openLoginModal ? (
                <Login
                    open={openLoginModal}
                    setOpen={setOpenLoginModal}
                    setEmail={setEmail}
                    email={email}
                    openVerifyOtp={setOpenVerifyOtp}
                    forgotpassword={openForgotPasswordPopup}
                ></Login>
            ) : (
                ""
            )}
            {openRegisterModal ? (
                <Register
                    open={openRegisterModal}
                    setOpen={setOpenRegisterModal}
                    openLogin={setOpenLoginModal}
                    email={email}
                ></Register>
            ) : (
                ""
            )}
            {forgotPassword ? (
                <ForgotPassword
                    open={forgotPassword}
                    setOpen={setForgotpassword}
                    email={email}
                    setEmail={setEmail}
                    openResetPassword={setResetPassword}
                ></ForgotPassword>
            ) : (
                ""
            )}
            {resetPassword ? (
                <ResetPassword
                    open={resetPassword}
                    setOpen={setResetPassword}
                    email={email}
                ></ResetPassword>
            ) : (
                ""
            )}
            {openVerifyOtp ? (
                <VerifyOtp
                    open={openVerifyOtp}
                    setOpen={setOpenVerifyOtp}
                    onSubmit={verifyAccount}
                    userDetails={{ email: email }}
                    otpVerificationLoading={otpVerificationLoading}
                ></VerifyOtp>
            ) : (
                ""
            )}
            {openEarlyAccessModal ? (
                <EarlyAccessSignup
                    open={openEarlyAccessModal}
                    setOpen={setOpenEarlyAccessModal}
                ></EarlyAccessSignup>
            ) : (
                ""
            )}

            {learnMoreModal ? <LearnMore open={learnMoreModal} handleClose={() => setLearnMoreModal(false)} /> : ""}
        </>
    );
};

export default NavRight;
