import { Home, DollarSign, Users, FileText } from "lucide-react"
import vacantHome from "../../assets/images/vacant-homes.jpg";
import foreclosures from "../../assets/images/foreclosures.jpg";
import absentee from "../../assets/images/absentee.jpg";
import zombie from "../../assets/images/zombie.jpg";

export const title="Target Motivated Sellers Before the Competition"
export const heading=`Real estate deals don't wait. OttoLeads helps you identify and contact motivated sellers before anyone else.`
export const categories = [
    {
      icon: Home,
      title: "Failed Listings & Vacant Homes",
      description: "Find properties likely to sell fast.",
      image:vacantHome,
      personImage: "https://randomuser.me/api/portraits/women/44.jpg",
    },
    {
      icon: DollarSign,
      title: "Pre-Foreclosures & Tax Liens",
      description: "Spot distressed properties with built-in equity potential.",
      image:foreclosures,
      personImage: "https://randomuser.me/api/portraits/men/32.jpg",
    },
    {
      icon: Users,
      title: "Absentee Owners & High-Equity Homes",
      description: "Reach sellers ready to cash out.",
      image:absentee,
      personImage: "https://randomuser.me/api/portraits/women/68.jpg",
    },
    {
      icon: FileText,
      title: "Probate & Zombie Properties",
      description: "Uncover off-market opportunities before they hit public records.",
      image:zombie,
      personImage: "https://randomuser.me/api/portraits/men/75.jpg",
    },
  ]