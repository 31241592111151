import Button from "../../atoms/Button";
import styles from "./ReadMore.module.scss";
import blogImage from "../../assets/images/map-img.jpeg";
import { Check } from "lucide-react";

const ReadMore = () => {
    return (
        <>
            <div className={` ${styles.banner}`}>
                <div className="wrapper-lg">
                    <div className={` ${styles.banner__info}`}>
                        <h1>Ottoleads Features</h1>
                        <p>OttoLeads gives you the data, tools, and insights you need to generate targeted leads, connect with property owners, and close more deals.</p>
                    </div>
                    <Button className="primary" label="7 day free trial" />
                </div>
            </div>
            <div className={` ${styles.section}`}>
                <div className="wrapper-lg">
                    <h2>Target Motivated Property Owners</h2>
                    <div className={`dflex alignCenter ${styles.section__row}`}>
                        <div className={`${styles.section__col}`}>
                        <img src={blogImage} alt="blog" />
                        </div>
                        <div className={`${styles.section__col}`}>
                       <p> Access a comprehensive database covering 98% of properties nationwide, including MLS data and public records, to find exceptional deals both on and off the market. Refine your searches with over 100 filters, accurately evaluate opportunities using our wholesale and creative finance calculators, and connect directly with homeowners via skip tracing.</p>

                       <p>Maximize your outreach with OttoLeads' AI-driven and Human VA calling services. Start your 7-day free trial with OttoLeads today!</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className={` ${styles.section} ${styles.bgGray}`}>
                <div className="wrapper-lg">
                    <h2>Comprehensive Nationwide Data</h2>
                    <div className={`dflex alignCenter ${styles.section__row}`}>
                        <div className={`${styles.section__col}`}>
                        <img src={blogImage} alt="blog" />
                        </div>
                        <div className={`${styles.section__col}`}>
                       <p> Accurate data is the foundation of successful real estate investing. Our comprehensive property reports provide the insights you need to make sound decisions and achieve your financial goals.</p>

                       <p>Our data services transcend basic property information, providing investors with unparalleled precision, scope, and detail. We gather data from multiple primary sources and authenticate it with proprietary research, equipping you with the knowledge necessary to make informed investment choices.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className={` ${styles.section} ${styles.bgGradient}`}>
                <div className="wrapper-lg">
                    <h2>Property Search</h2>
                    <div className={`dflex alignCenter ${styles.section__row}`}>
                        <div className={`${styles.section__col}`}>
                        <img src={blogImage} alt="blog" />
                        </div>
                        <div className={`${styles.section__col}`}>
                       <p> Accurate data is the foundation of successful real estate investing. Our comprehensive property reports provide the insights you need to make sound decisions and achieve your financial goals.</p>

                       <ul>
                     <li><Check/> <strong>Specific Address Search</strong></li>  
                     <li><Check/> <strong>County, City, and Zip Code Search</strong></li>  
                     <li><Check/> <strong>APN Search</strong></li>  
                     <li><Check/> <strong>Custom Drawing Features</strong></li>  
                     <li><Check/> <strong>Map based search</strong></li>  
                       </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ReadMore;
