import { useEffect, useState } from "react"
import NationwideDataHero from "../../organisms/nationwideDataHero/NationwideDataHero"
import ComprehensiveDataSection from "../../organisms/comprehensiveDataSection/ComprehensiveDataSection"
import TargetSellersSection from "../../organisms/targetSellersSection/TargetSellersSection"
import AdvancedSearchSection from "../../organisms/advancedSearchSection/AdvancedSearchSection"
import SkipTracingSection from "../../organisms/skipTracingSection/SkipTracingSection"
import WhyChooseSection from "../../organisms/whyChooseSection/WhyChooseSection"
import styles from "./NationwideData.module.scss";
import Register from "../../organisms/auth/Register"
import { bottomText, testimonialMessage, testimonialName, testimonialTitle } from "../../organisms/whyChooseSection/constant"



const NationwideData = () => {
   const [openRegisterModal, setOpenRegisterModal] = useState<boolean>(false);
    const [email, setEmail] = useState<string>("");
  
    const handleSignUp = () => {
      setOpenRegisterModal(!openRegisterModal);
    };
  useEffect(() => {
    // Scroll to top when the component mounts
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className={`${styles.nationwide}`}>
      <NationwideDataHero handleSignUp={handleSignUp} />
      <AdvancedSearchSection handleSignUp={handleSignUp} />
      <ComprehensiveDataSection />
      <SkipTracingSection />
      <WhyChooseSection
        handleSignUp={handleSignUp}
        testimonialMessage={testimonialMessage}
        testimonialName={testimonialName}
        testimonialTitle={testimonialTitle}
        bottomText={bottomText}
      />
      <TargetSellersSection />
      {openRegisterModal ? (
        <Register
          open={openRegisterModal}
          setOpen={setOpenRegisterModal}
          openLogin={true}
          email={email}
        ></Register>
      ) : (
        ""
      )}
    </div>
  );
}

export default NationwideData
