


const Light = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="23" viewBox="0 0 16 23" fill="none">
        <path d="M5.50513 21.4001H10.5051M6.00513 9.40015H10.0051M8.00513 9.40015L8.00513 15.4001M11.0051 14.7265C13.3701 13.6031 15.0051 11.1925 15.0051 8.40015C15.0051 4.53415 11.8711 1.40015 8.00513 1.40015C4.13913 1.40015 1.00513 4.53415 1.00513 8.40015C1.00513 11.1925 2.64018 13.6031 5.00513 14.7265V15.4001C5.00513 16.332 5.00513 16.798 5.15737 17.1655C5.36036 17.6556 5.7497 18.0449 6.23976 18.2479C6.6073 18.4001 7.07324 18.4001 8.00513 18.4001C8.93701 18.4001 9.40295 18.4001 9.77049 18.2479C10.2605 18.0449 10.6499 17.6556 10.8529 17.1655C11.0051 16.798 11.0051 16.332 11.0051 15.4001V14.7265Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};

export default Light;



