import React, { useEffect, useState } from "react";
import FileHeart from "../../assets/icons/fileHeart";
import Button from "../../atoms/Button";
import { useForm } from "react-hook-form";
import Input from "../../atoms/Input";
import { taxOnSkip, uploadListSkipTrace } from "../../organisms/createList/skipTraceService";
import { formartNumberAsLocalString, formatCurrency } from "../../utils/utils";
import CouponCard from "../couponCard/CouponCard";
import { validateCoupon } from "../../organisms/creditsSubscription/CreditsSubscriptionService";
import TargetIcon from "../../assets/icons/targetIcon";
import { Dialog } from "@mui/material";
import Close from "../../assets/icons/cross";
import { ToastType } from "../../molecules/toaster/enum";
import { addToast } from "../../molecules/toaster/toastSlice";
import { useDispatch } from "react-redux";

const UploadedListSkipTrace = ({ open, onClose, list,selectedPlan }: any) => {
  const {
    formState: { errors },
    handleSubmit,
    register,
    control,
    watch,
    setValue,
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      listName: "",
      searchResultCount: null,
    },
  });
  const [updatedPricing, setUpdatedPricing] = useState<any>(null);
  const [skipTax, setSkipTax] = useState({
    taxAmount: 0,
    price: 0,
    totalPrice: 0,
  });
  const dispatch= useDispatch()
  const [validateButtonLoading, setValidateButtonLoading] = useState(false);
  const [skipTaxLoading, setSkipTaxLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [afterSkip, setAfterSkip] = useState(false);
  const searchResultCount = +watch("searchResultCount")!;
  const [couponStatus, setCouponStatus] = useState("");
  const handleRemoveCoupon = () => {
    setCouponStatus("");
    setUpdatedPricing(null);
  };

    const handleShowToast = (
      message: string,
      type: ToastType = ToastType.success
    ) => {
      dispatch(
        addToast({
          id: new Date().getTime(),
          message: message,
          type: type,
        })
      );
    };

  const handleCoupon = async (data: any) => {
    try {
      setValidateButtonLoading(true);
      const res = await validateCoupon({
        couponCode: data?.code.toUpperCase(),
        price: skipTax?.price,
        identifier: "skip_trace",
      });
      setUpdatedPricing(res?.data?.data);
      setValidateButtonLoading(false);
      setCouponStatus(
        `Coupon Code Applied! (${
          res?.data?.data.discount_type === "percentage"
            ? `${res?.data?.data.discount_value}%`
            : `$${res?.data?.data.discount_value}`
        } Off)`
      );
    } catch (error: any) {
      setValidateButtonLoading(false);
      setCouponStatus(error?.response?.data?.message);
    }
  };

  const taxCalculate = async () => {
    try {
      setSkipTaxLoading(true);
      const payload={skipCount:selectedPlan === "Skip" ? list?.recordCount <10? 10:list?.recordCount : list?.recordCount <50 ?50: list?.recordCount,
        ...(selectedPlan !== "Skip"
          && { isEnrich: true }
          ),
      }
      const taxValue = await taxOnSkip(payload);
      setSkipTax(taxValue?.data);
      setSkipTaxLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const onSubmit = async () => {
    try {
      setLoading(true);
      const payload = {
        listId: list?.id,
        ...(updatedPricing && { couponCode: updatedPricing?.id }),
        ...(selectedPlan === "Skip"
          ? { isSkip: true }
          : { isEnrich: true }),
      };
      await uploadListSkipTrace(payload);
      setLoading(false);
      handleShowToast(`${list?.name} list is being processed. We'll notify you when completed`,  ToastType.info);
      setAfterSkip(true) 
    } catch (err:any) {
      handleShowToast( err?.response?.data?.message??'something went wrong',  ToastType.error);
      setLoading(false);
    }
  };

  useEffect(() => {
    setValue("searchResultCount", list?.recordCount);
    setValue("listName", list?.name);
    taxCalculate();
  }, [list]);

  return (
    <Dialog className="stepsDialog contactInfoModal" onClose={onClose} open={open}>
      {afterSkip ? (
        <div className="dialogWrapper">
          
          <div className="dialogWrapper__info">
            <h3>Processing Your List – Hang Tight! </h3>
          <p>
            We’re gathering property details
            and matching contact records for your list. This process can take
            anywhere from a few minutes to an hour, depending on the number of
            records.
            </p> <p>
             You'll receive a notification once everything is ready—no
            need to refresh or wait around. We'll let you know when your data is
            good to go!
            </p> <p>
             Thanks for your patience—we’re on it!
          </p>
         
          </div>
          <Button
         label="Got It"
         className="primary animated full"
          action={onClose}
        />
        </div>
      ) : (
        <div className="dialogWrapper">
          <div className={`dialogWrapper__icon`}>
            <FileHeart />
          </div>
          <span role="link" className="closeDialog" onClick={onClose}>
            <Close />
          </span>
          <div className={`dialogWrapper__header`}>
            <h3>{selectedPlan ==="Skip" ? `${selectedPlan} Trace` : "Enrich"} list</h3>
            <p>{`${selectedPlan ==="Skip" ? `${selectedPlan} Trace` : "Enrich"} your existing list.`}</p>
          </div>

          <Input
            type="text"
            label="List Name*"
            register={register}
            name="listName"
            errors={errors}
            disabled={true}
          ></Input>

          <Input
            type="numeric"
            label="Records to skip*"
            register={register}
            control={control}
            name="searchResultCount"
            placeholder="How many records would you like?"
            errors={errors}
            disabled={true}
          ></Input>

          <CouponCard
            loading={validateButtonLoading}
            couponStatus={couponStatus}
            onSubmit={handleCoupon}
            setCouponStatus={setCouponStatus}
            handleRemoveCoupon={handleRemoveCoupon}
            validate={searchResultCount < 11}
            couponSelected={updatedPricing?.id}
          />
          <div>
            {skipTaxLoading && (
              <div className="map-loader loader-white">
                <div className="dot-pulse dot-white"></div>
              </div>
            )}

            <div className={`flex justifySpaceBetween totalRow borderTop`}>
              <p> Subtotal:</p>
              <p>{skipTax?.price ? formatCurrency(skipTax?.price) : "-"}</p>
            </div>
            {skipTax?.taxAmount != 0 && (
              <div className={`dflex alignCenter totalRow justifySpaceBetween`}>
                <p> Tax:</p>
                <p>
                  {formatCurrency(
                    updatedPricing
                      ? updatedPricing?.pricing?.breakdown.taxAmount
                      : skipTax?.taxAmount
                  )}
                </p>
              </div>
            )}
            {updatedPricing?.pricing?.breakdown?.discountAmount && (
              <div className={`flex justifySpaceBetween totalRow`}>
                <p>Coupon Discount ({updatedPricing?.id}):</p>
                <div className={` right`}>
                  <p>${updatedPricing?.pricing?.breakdown?.discountAmount}</p>
                </div>
              </div>
            )}
            <div
              className={`dflex alignCenter justifySpaceBetween borderTop totalRow `}
            >
              <p> Total Price:</p>
              <strong>
                {updatedPricing
                  ? `$${formartNumberAsLocalString(
                      +(updatedPricing?.pricing?.breakdown?.finalAmount ?? 0),
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }
                    )}`
                  : skipTax?.totalPrice
                  ? formatCurrency(skipTax?.totalPrice)
                  : "-"}
              </strong>
            </div>
          </div>
          <div className="small-text">
            {((selectedPlan === "Skip" && list?.recordCount <10)|| (selectedPlan === "Enrich" && list?.recordCount <50)) &&
              <h5>
            * $1 minimum purchase required to process payment.
            </h5>}
            <p>
              Pressing "{`${selectedPlan ==="Skip" ? `${selectedPlan} Trace` : "Enrich"} List`}" below will bill your active payment
              method for the price above.
            </p>
          </div>

          {/* {biilingCardError && (
          <div className={` ${styles.billingCard} `}>
            <div
              className={`flex alignStart  justifySpaceBetween  ${styles.billingCard__inner}`}
            >
              <span className={`${styles.info}`}>
                <AlertCircle />
              </span>
              <div className={` ${styles.billingInfo} `}>
                <h5>There was a problem billing your card</h5>
                <p>{billingCardErrorText}</p>
                <Link to={"/settings"} target="_blank" role="link">
                  <span role="link">Manage Payment Method</span>
                </Link>
              </div>
              <span
                role="link"
                className={`${styles.close}`}
                onClick={() => {
                  setBiilingCardError(false);
                  setBillingCardErrorText("");
                }}
              >
                <Close />
              </span>
            </div>
          </div>
        )} */}

          <div>
            <Button
              label={`${selectedPlan ==="Skip" ? `${selectedPlan} Trace` : "Enrich"} List`}
              prefix={<TargetIcon />}
              className="primary xl full animated"
              action={handleSubmit(onSubmit)}
              disabled={loading}
              clicked={loading}
            ></Button>
          </div>
        </div>
      )}
    </Dialog>
  );
};

export default UploadedListSkipTrace;
