import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import AlertCircle from "../../assets/icons/alertCircle";
import Close from "../../assets/icons/cross";
import FileHeart from "../../assets/icons/fileHeart";
import TargetIcon from "../../assets/icons/targetIcon";
import Button from "../../atoms/Button";
import CustomSwitch from "../../atoms/CustomSwitch";
import DropDown from "../../atoms/DropDown";
import Input from "../../atoms/Input";
import Loader from "../../atoms/Loader";
import { formartNumberAsLocalString, formatCurrency } from "../../utils/utils";
import { getCampaignLeadList } from "../createCampaign/selectors";
import styles from "./CreateList.module.scss";
import { getSkipPropertyCount, getSkipPropertyCountLoading } from "./selectors";
import { skipTraceSchema } from "./validations";
import { activeSkip, taxOnSkip } from "./skipTraceService";
import { fetchSkipPropertyCounts } from "./sagaActions";
import { fetchCampaignLeadList } from "../createCampaign/sagaActions";
import { useDbounce } from "../../hooks/debounce.hook";
import { SKIP_REDO_MONTH_OPTIONS } from "./enum";
import { getSearchPropertiesResultCount } from "../propertySearchFilter/selectors";
import CouponCard from "../../pages/couponCard/CouponCard";
import { validateCoupon } from "../creditsSubscription/CreditsSubscriptionService";

const SkipTrace = ({
  onSubmit,
  loading,
  biilingCardError,
  setBiilingCardError,
  billingCardErrorText,
  setBillingCardErrorText,
  invalidResultCount,
  setInvalidResultCount,
  propertySearchFilter,
  skipPropApiFailText,
  setSkipPropApiFailText,
  couponStatus,
  setCouponStatus,
  updatedPricing,
  handleRemoveCoupon,
  setUpdatedPricing
}: any) => {
  const {
    formState: { errors },
    handleSubmit,
    setError,
    clearErrors,
    register,
    control,
    watch,
    setValue,
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(skipTraceSchema),
    defaultValues: {
      addToExistingList: false,
      listName: "",
      targetList: "",
      searchResultCount: null,
      redoSkip: true,
      skipPeriod:SKIP_REDO_MONTH_OPTIONS[1].value
    },
  });
  console.log("billingCardErrorText",billingCardErrorText)
  const dispatch = useDispatch();
  const [init, setInit] = useState(true);
  const [leadListOptions, setLeadListOptions] = useState([]);
  const [activeSkipLoading, setActiveSkipLoading] = useState(false);
  const [showSkipError, setShowSkipError] = useState(false);
  const [validateButtonLoading, setValidateButtonLoading] = useState(false);
  const leadList = useSelector((state) => getCampaignLeadList(state));
  const [skipTax, setSkipTax] = useState({
    taxAmount: 0,
    price: 0,
    totalPrice: 0,
  });
  const [skipTaxLoading, setSkipTaxLoading] = useState(false);
  const skipPropertyCount = useSelector((state: any) =>
    getSkipPropertyCount(state)
  );
  const searchPropertiesResultCount = useSelector((state) =>
    getSearchPropertiesResultCount(state)
  );
  const skipPropertyCountLoading = useSelector((state: any) =>
    getSkipPropertyCountLoading(state)
  );
  const redoSkip = watch("redoSkip");
  const searchResultCount = +watch("searchResultCount")!;
  const skipPeriod= watch("skipPeriod")
 
  const handleCoupon = async (data: any) => {
    try {
      setValidateButtonLoading(true);
      const res = await validateCoupon({
        couponCode: data?.code.toUpperCase(),
        price:skipTax?.price,
        identifier:"skip_trace"
      });
      setUpdatedPricing(res?.data?.data);
      setValidateButtonLoading(false);
      setCouponStatus(`Coupon Code Applied! (${res?.data?.data.discount_type ==="percentage" ? `${res?.data?.data.discount_value}%` :`$${res?.data?.data.discount_value}`} Off)`);
    } catch (error:any) {
      setValidateButtonLoading(false);
      setCouponStatus(error?.response?.data?.message);
    }
  };

  const debouncedFunction = async (data: any) => {
    if (searchResultCount) {
      setSkipTaxLoading(true);
      const taxValue = await taxOnSkip({skipCount:searchResultCount});
      setSkipTax(taxValue?.data);
      setSkipTaxLoading(false);
    }
  };
  
  const debounce = useDbounce(500, debouncedFunction);

  const skipPropertyCountMaxLimit = useMemo(
    () =>
      Math.min(
        10000,
        redoSkip
          ? (skipPropertyCount?.withoutDupCount ?? 0) > 0
            ? skipPropertyCount?.withoutDupCount
            : 0
          : (skipPropertyCount?.totalSkipCount ?? 0) > 0
          ? skipPropertyCount?.totalSkipCount
          : 0
      ),
    [redoSkip, skipPropertyCount]
  );
  
  const handleOnFocus = (e: any) => (e.target.readOnly = true);

  const checkActiveSkip = async () => {
    try {
      setActiveSkipLoading(true);
      await activeSkip();
      setActiveSkipLoading(false);
      setShowSkipError(false);
      if (init) {
        setInit(false);
      } else {
        dispatch(
          fetchSkipPropertyCounts({
            ...propertySearchFilter,
            period: SKIP_REDO_MONTH_OPTIONS[1].value,
          })
        );
        dispatch(fetchCampaignLeadList({ applyfilter: true }));
      }
    } catch (err) {
      console.error(err);
      setActiveSkipLoading(false);
      setValue("redoSkip", false);
      setShowSkipError(true);
      if (init) {
        setInit(false);
      }
    }
  };

  useEffect(() => {
    const options = leadList.map((list: any) => ({
      value: list.id,
      label: list.name,
    }));
    setLeadListOptions(options);
  }, [leadList]);

  useEffect(() => {
    if (searchResultCount === 0) {
      setSkipTax({
        taxAmount: 0,
        price: 0,
        totalPrice: 0
      });
    }
  }, [searchResultCount]);

  useEffect(() => {
    if (searchResultCount > searchPropertiesResultCount) {
      setInvalidResultCount(true);
    } else {
      setInvalidResultCount(false);
    }
    clearErrors("searchResultCount");
  }, [clearErrors, searchResultCount, setError, searchPropertiesResultCount]);

  // useEffect(() => {
  //   if (redoSkip) {
  //     checkActiveSkip();
  //   }
  //   setSkipPropApiFailText("");
  // }, [redoSkip]);

  // useEffect(() => {
  //     checkActiveSkip();
  // }, []);

  useEffect(() => {
    debounce(searchResultCount);
    setSkipPropApiFailText("");
  }, [watch("searchResultCount")]);

  useEffect(() => {
    if (!redoSkip) {
      // setValue("skipPeriod","");
      // dispatch(
      //   fetchSkipPropertyCounts(propertySearchFilter)
      // );
      setSkipPropApiFailText("");
    }
  }, [redoSkip]);

  // useEffect(() => {
  //   if (skipPeriod) {
  //     dispatch(
  //       fetchSkipPropertyCounts({ ...propertySearchFilter, period: skipPeriod })
  //     );
  //     setSkipPropApiFailText("");
  //   }
  // }, [skipPeriod]);
  return (
    <>
      {skipPropertyCountLoading ? (
        <div className={`loader-main-large`}>
          <Loader></Loader>
        </div>
      ) : (
        <div className={`${styles.createList}`}>
          <div className={`headerIcon ${styles.headerIcon}`}>
            <FileHeart />
          </div>
          <div className={`${styles.createList__header}`}>
            <h3>Create your list</h3>
            <p>Skip trace and create a list.</p>
          </div>
          {/* <div className={`${styles.switch} flex alignCenter`}>
            <CustomSwitch
              control={control}
              name="addToExistingList"
              defaultChecked={false}
            />
            <div>
              <h5>Add to an existing list</h5>
            </div>
          </div> */}
          {watch("addToExistingList") ? (
            <DropDown
              options={leadListOptions}
              label="Select a target list*"
              control={control}
              name="targetList"
              errors={errors}
              placeholder="Select list"
              hint="Keep in mind that you’ll only see lists that you haven’t added to any campaign."
            ></DropDown>
          ) : (
            <Input
              type="text"
              label="List Name*"
              register={register}
              name="listName"
              placeholder="Give your list a friendly name"
              errors={errors}
            ></Input>
          )}
          <div className={` ${styles.slider} `}>
            <Input
              type="numeric"
              label="Records to skip*"
              register={register}
              control={control}
              name="searchResultCount"
              placeholder="How many records would you like?"
              errors={errors}
              postfix={true}
              disabled={couponStatus.includes("Applied")}
              postfixValue={formartNumberAsLocalString(searchPropertiesResultCount)}
              invalidResultCount={invalidResultCount}
            ></Input>
          </div>

          {/* <div
            className={`${styles.switch} ${styles.skipTraceSwitch} flex alignCenter`}
          >
            <div className={`${styles.skipTrace} flex alignCenter`}>
              <CustomSwitch
                control={control}
                name="redoSkip"
                defaultChecked={true}
                disabled={activeSkipLoading}
              />
              <h5>Don’t redo skip traces on data you’ve already purchased</h5>
            </div>
            <div className={`${styles.skipTrace} flex alignCenter`}>
              <DropDown
                options={SKIP_REDO_MONTH_OPTIONS}
                name="skipPeriod"
                control={control}
                placeholder={"in the past 2 months"}
                errors={errors}
                disabled={!redoSkip}
                handleOnFocus={(e: any) => handleOnFocus(e)}
                small
              ></DropDown>
            </div>
          </div> */}
          {showSkipError && (
            <div className={` ${styles.billingCard} ${styles.blueCard}`}>
              <div
                className={`flex alignCenter  justifySpaceBetween  ${styles.billingCard__inner}`}
              >
                <span className={`${styles.info}`}>
                  <AlertCircle />
                </span>
                <div className={` ${styles.billingInfo} `}>
                  <h5>Give us a few moments</h5>
                  {/* <p>{skipPropApiFailText}</p> */}
                  <p>
                    We’re working on a list that you requested to be skip traced
                    a moment ago. To avoid duplicates, we have to wait until
                    that one’s done.
                  </p>
                </div>
                {/* <span
                    role="link"
                    className={`${styles.close}`}
                    onClick={() => {
                      setSkipPropApiFailError(false);
                      setSkipPropApiFailText("");
                    }}
                  >
                    <Close />
                  </span> */}
              </div>
            </div>
          )}

         <CouponCard
          loading={validateButtonLoading}
          couponStatus={couponStatus}
          onSubmit={handleCoupon}
          setCouponStatus={setCouponStatus}
          handleRemoveCoupon={handleRemoveCoupon}
          validate={searchResultCount<11}
          couponSelected ={updatedPricing?.id}
        />
          <div className={` ${styles.loader} `}>
            {skipTaxLoading && (
              <div className="map-loader loader-white">
                <div className="dot-pulse dot-white"></div>
              </div>
            )}
            <div className={`dflex alignCenter justifySpaceBetween ${styles.subtotalSpace}`}>
              <p> Subtotal:</p>
              <p>{skipTax?.price ? formatCurrency(skipTax?.price) : "-"}</p>
            </div>
            {skipTax?.taxAmount != 0 && (
              <div className={`dflex alignCenter justifySpaceBetween`}>
                <p> Tax:</p>
                <p>{formatCurrency(updatedPricing? updatedPricing?.pricing?.breakdown.taxAmount :skipTax?.taxAmount)}</p>
              </div>
            )}   
        {updatedPricing?.pricing?.breakdown?.discountAmount && (
          <div className={`flex justifySpaceBetween totalRow`}>
            <p>Coupon Discount ({updatedPricing?.id}):</p>
            <div className={` right`}>
              <p>${updatedPricing?.pricing?.breakdown?.discountAmount}</p>
            </div>
          </div>
        )}
            <div
              className={`dflex alignCenter justifySpaceBetween ${styles.recordsSkip} ${styles.total}`}
            >
              <p> Total Price:</p>
              <h5>
                {updatedPricing
                  ? `$${formartNumberAsLocalString(
                      +(updatedPricing?.pricing?.breakdown?.finalAmount ?? 0),
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }
                    )}`
                  :skipTax?.totalPrice ? formatCurrency(skipTax?.totalPrice) : "-"}
                {/* {formartNumberAsLocalString(
                +(searchResultCount ?? 0) >= 0
                  ? +(searchResultCount ?? 0) *
                      +(process.env.REACT_APP_PRICE_PER_SKIP ?? 0)
                  : 0,
                { minimumFractionDigits: 2, maximumFractionDigits: 2 },
                "0"
              )} */}
              </h5>
            </div>
          </div>
          <div className={` ${styles.note} `}>
            <p>
              Pressing “Skip Trace” below will bill your active payment method
              for the price above.
            </p>
            <p>Although our data accuracy exceeds 97%, we charge $0.10 per record for the attempt to skip-trace, so final results may include fewer records than purchased.</p>
          </div>
        
          {biilingCardError && (
            <div className={` ${styles.billingCard} `}>
              <div
                className={`flex alignStart  justifySpaceBetween  ${styles.billingCard__inner}`}
              >
                <span className={`${styles.info}`}>
                  <AlertCircle />
                </span>
                <div className={` ${styles.billingInfo} `}>
                  <h5>There was a problem billing your card</h5>
                  <p>{billingCardErrorText}</p>
                  <Link to={"/settings"} target="_blank" role="link">
                    <span role="link">Manage Payment Method</span>
                  </Link>
                </div>
                <span
                  role="link"
                  className={`${styles.close}`}
                  onClick={() => {
                    setBiilingCardError(false);
                    setBillingCardErrorText("");
                  }}
                >
                  <Close />
                </span>
              </div>
            </div>
          )}
 <div className={`${styles.createList__error} error`}>{skipPropApiFailText}</div>
          <div className={`${styles.createList__action}`}>
            <Button
              label={"Skip Trace"}
              prefix={<TargetIcon />}
              className="primary xl full animated"
              action={handleSubmit(onSubmit)}
              disabled={
                skipTaxLoading ||
                Boolean(errors["searchResultCount"]?.message) ||
                +(searchResultCount ?? 0) *
                  +(process.env.REACT_APP_PRICE_PER_SKIP ?? 0) <
                  1  ||
                loading || 
                searchResultCount > searchPropertiesResultCount
              }
              clicked={loading}
            ></Button>
          </div>
        </div>
      )}
    </>
  );
};
export default SkipTrace;
