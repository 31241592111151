import Dialog from "@mui/material/Dialog";
import Close from "../../assets/icons/cross";
import styles from "./VaPlanModal.module.scss";
import { useEffect, useState } from "react";
import { createSubscription, updatePlan } from "../billing/billingService";
import { useDispatch, useSelector } from "react-redux";
import { addToast } from "../../molecules/toaster/toastSlice";
import { ToastType } from "../../molecules/toaster/enum";
import {
  fetchInvoices,
  fetchSubscriptions,
} from "../billing/sagaActions";
import { ProductType } from "../billing/enum";
import { getProfileInfo } from "../../components/profileMenu/selectors";
import { fetchProfileInfo } from "../../components/profileMenu/sagaActions";
import Loader from "../../atoms/Loader";
import AddPaymentModal from "../billing/AddPaymentModal";
import { getSubscriptions } from "../billing/selectors";
import PurchaseConfirmation from "../../components/PurchaseConfirmation/PurchaseConfirmation";
import { fetchVaAccessProducts } from "../vaSubscription/VaSubscriptionService";
import VaSubscription from "../vaSubscription/VaSubscription";
import { validateCoupon } from "../creditsSubscription/CreditsSubscriptionService";


const VaPlanModal = ({
  handleClose,
  open,
  product,
}: any) => {
  const dispatch = useDispatch();
  const [selectedPricing, setSelectedPricing] = useState<any>(null);
  const [confirmSubscriptionLoading, setConfirmSubscriptionLoading] =
    useState(false);
  const profileInfo = useSelector((state) => getProfileInfo(state));
  const [creditPoints, setCreditPoints] = useState(true);
  const [vaAccessProduct, setVaAccessProduct] = useState([]);
  const [openAddPaymentModal, setOpenAddPaymentModal] = useState(false);
  const subscription = useSelector((state) => getSubscriptions(state));
  const [showPurchaseModal, setShowPurchaseModal] = useState(false);
  const [currentCredits, setCurrentCredits] = useState("");
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [vaPlanTermModal, setVaPlanTermModal] = useState(false);
  const [validateButtonLoading, setValidateButtonLoading] = useState(false);
  const [couponStatus, setCouponStatus] = useState("");
  const [updatedPricing, setUpdatedPricing] = useState<any>(null);
  const confirmSubscription = async () => {
    if (subscription?.vaSubData) {
      try {
        setConfirmSubscriptionLoading(true);
        await updatePlan({
          stripeSubscriptionId: product?.stripeSubscriptionId,
          stripePriceId: selectedPricing.stripePriceId,
          subsProductType: ProductType.VA_ACCESS,
          ...(updatedPricing && { couponCode: updatedPricing.id })
        });

        onSuccess();
      } catch (error: any) {
        displayErrorMessage(error);
        setConfirmSubscriptionLoading(false);
        if (error?.response?.data?.errorCode === "card_declined") {
          setOpenAddPaymentModal(true);
        }
      }
    } else {
      try {
        setConfirmSubscriptionLoading(true);
        let payload = {
          paymentMethod: profileInfo?.organisation?.paymentMethodId,
          price: selectedPricing.stripePriceId,
          subsProductType: ProductType.VA_ACCESS,
          trialPeriodDays: 0,
          ...(updatedPricing && { couponCode: updatedPricing.id })
        };
        await createSubscription(payload);
        onSuccess();
      } catch (error: any) {
        displayErrorMessage(error);
        setConfirmSubscriptionLoading(false);
        if (error?.response?.data?.errorCode === "card_declined") {
          setOpenAddPaymentModal(true);
        }
      }
    }
  };

  const onSuccess = () => {
    setTimeout(() => {
      setVaPlanTermModal(false)
      setConfirmSubscriptionLoading(false);
      dispatch(fetchInvoices({}));
      dispatch(fetchProfileInfo({}));
      setPaymentSuccess(true);
      handleShowToast("Subscription confirmed successfully!",ToastType.success)
    }, 3000);
  };

  const handleShowToast = (
    message: string,
    type: ToastType = ToastType.success
  ) => {
    dispatch(
      addToast({
        id: new Date().getTime(),
        message: message,
        type: type,
      })
    );
  };

  const handlePurchaseModal = () => {
    setShowPurchaseModal(!showPurchaseModal);
  };

  const handleCloseModal = () => {
    handleClose()
    setPaymentSuccess(false);
    setShowPurchaseModal(false);
  }

  const displayErrorMessage = (error: any) => {
    dispatch(
      addToast({
        id: new Date().getTime(),
        message: error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message ?? "Something went wrong!",
        type: ToastType.error,
      })
    );
  };

    const handleCoupon = async (data: any) => {
      try {
        setValidateButtonLoading(true);
        const res = await validateCoupon({
          couponCode: data?.code.toUpperCase(),
          priceId: selectedPricing.stripePriceId,
        });
        setUpdatedPricing(res?.data?.data);
        setValidateButtonLoading(false);
        setCouponStatus(`Coupon Code Applied! (${res?.data?.data.discount_type ==="percentage" ? `${res?.data?.data.discount_value}%` :`$${res?.data?.data.discount_value}`} Off)`);
      } catch (error:any) {
        setValidateButtonLoading(false);
        setCouponStatus(error?.response?.data?.message);
      }
    };
  
    const handleRemoveCoupon=()=>{
      setCouponStatus("");
      setUpdatedPricing(null)
    }


  useEffect(() => {
    const fetchData = async () => {
      try {
        const vaAccessProduct: any = await fetchVaAccessProducts();
        setVaAccessProduct(vaAccessProduct.data);
        setCreditPoints(false);
      } catch (error: any) {
        handleShowToast(error?.message, ToastType.error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    dispatch(fetchSubscriptions({})); 
  }, []);

  return (
    <>
      <Dialog
        onClose={() => {
          if (!paymentSuccess && showPurchaseModal) {
            handlePurchaseModal();
          } else {
            handleCloseModal();
          }
        }}
        open={open}
        className={`stepsDialog upgradeModal upgradeModalSmall ${
          paymentSuccess && showPurchaseModal && "paymentSuccess"
        } `}
        onBackdropClick={() => {
          if (showPurchaseModal) {
            handlePurchaseModal();
          }
        }}
      >
        <span
          role="link"
          onClick={() => {
            if (!paymentSuccess && showPurchaseModal) {
              handlePurchaseModal();
            } else {
              !confirmSubscriptionLoading && handleCloseModal()
            }
          }}
          className="closeDialog"
        >
          <Close />
        </span>
        {showPurchaseModal ? (
          <PurchaseConfirmation
            onSubmit={confirmSubscription}
            selectedPricing={selectedPricing}
            currentCredits={currentCredits}
            paymentSuccess={paymentSuccess}
            confirmSubscriptionLoading={confirmSubscriptionLoading}
            handleClose={handleCloseModal}
            vaPlanTermModal={vaPlanTermModal} 
            setVaPlanTermModal={setVaPlanTermModal}
            updatedPricing={updatedPricing}
            vaPlan
          />
        ) : (
          <>
            {creditPoints ? (
              <div className="loader-main">
                <Loader></Loader>
              </div>
            ) : (
              <div className={` ${styles.vaPlan}`}>
                <div className="wrapper-md">
                  <div className={`dflex alignCenter ${styles.vaPlan__info}`}>
                    <div
                      className={` ${styles.vaPlanCard}`}
                    >
                      <VaSubscription
                        selectedPricing={selectedPricing}
                        setSelectedPricing={setSelectedPricing}
                        vaAccessProduct={vaAccessProduct}
                        handlePurchaseModal={handlePurchaseModal}
                        setCurrentCredits={setCurrentCredits}
                        currentCredits={currentCredits}
                        creditCount={product?.creditCount}
                        handleCoupon={handleCoupon}
                        validateButtonLoading={validateButtonLoading}
                        couponStatus={couponStatus} setCouponStatus={ setCouponStatus}
                        updatedPricing={updatedPricing}
                        handleRemoveCoupon={handleRemoveCoupon}
                        upgradeNow
                      ></VaSubscription>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </Dialog>
      {openAddPaymentModal && (
        <AddPaymentModal
          handleClose={() => {
            setOpenAddPaymentModal(false);
          }}
          open={openAddPaymentModal}
          replacePaymentMethod
        ></AddPaymentModal>
      )}
    </>
  );
};
export default VaPlanModal;
