import { yupResolver } from "@hookform/resolvers/yup";
import DropDown from "../../atoms/DropDown";
import Button from "../../atoms/Button";
import { useForm } from "react-hook-form";
import { UploadListProps } from "./interface";
import UploadFile from "../../atoms/UploadFile";
import Papa from "papaparse";
import { useEffect, useRef, useState } from "react";
import { DropDownOption } from "../../models/dropDown.interface";
import { uploadListFormSchema } from "./validation";
import styles from "./UploadList.module.scss";
import FileHeart from "../../assets/icons/fileHeart";
import { DB_COLUMNS, DEFAULT_LIST_DATA } from "./constants";
import { Dialog, Tooltip } from "@mui/material";
import Close from "../../assets/icons/cross";
import Upload from "../../assets/icons/upload";
import Input from "../../atoms/Input";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../atoms/Loader";
import { saveLeadList, uploadLeadList } from "./sagaActions";
import { resetUploadListState, setLeadListCSV } from "./reducer";
import {
  getIsFileUploading,
  getLeadListCSV,
  getListUploading,
  getOpenImportFileModal,
} from "./selectors";
import { addToast } from "../../molecules/toaster/toastSlice";
import { ToastType } from "../../molecules/toaster/enum";
import { default as CSV } from "papaparse";
import { isCSVEmpty } from "../../utils/utils";
import * as XLSX from "xlsx";
import { getProfileInfo } from "../../components/profileMenu/selectors";
import { PermissionListEnum } from "../auth/enum";
import { ROLE_PERMISSION_STATUS } from "../../constants/constants";
import Trash from "../../assets/icons/trash";
import Plus from "../../assets/icons/plus";

const UploadList = ({ open, setLeadFileKey, closeModal }: UploadListProps) => {
  const [phoneCount, setPhoneCount] = useState(2) 
  const validationSchema = uploadListFormSchema(phoneCount);
  const {
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
    trigger,
    control,
    register,
    reset,
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(validationSchema),
    defaultValues: DEFAULT_LIST_DATA,
  });
  const dispatch = useDispatch();
  const uplodeInputRef = useRef<any>(0);
  const [columnsHeader, setColumnsHeader] = useState<DropDownOption[]>([]);
  const [filteredColumnsHeader, setFilteredColumnsHeader] = useState<
    DropDownOption[]
  >([]);
  const isFileUploading = useSelector((state) => getIsFileUploading(state));
  const openImportFileModal = useSelector((state) =>
    getOpenImportFileModal(state)
  );
  const leadListCSV = useSelector((state) => getLeadListCSV(state));
  const listUploading = useSelector((state) => getListUploading(state));
  const profileData = useSelector((state) => getProfileInfo(state));
  const [phoneNumbers, setPhoneNumbers] = useState<string[]>(["", ""]);
  const handleAddPhoneNumber = () => {
    if (phoneNumbers.length < 10) {
      setPhoneCount((prev) => prev + 1);
      setPhoneNumbers([...phoneNumbers, ""]);
    }
  };

  const handleDeletePhoneNumber = (index: number) => {
    if (index >= 2) {
      setPhoneCount((prev) => prev - 1);
      const updatedPhones = [...phoneNumbers];
      updatedPhones.splice(index, 1);
      setPhoneNumbers(updatedPhones);
    }
  };
  const handleUplodeCsv = async (file: File) => {
    if (file) {
      if (Math.ceil(file.size / (1024 * 1024)) > 200) {
        uplodeInputRef.current.value = "";
        dispatch(
          addToast({
            id: new Date().getTime(),
            message: "File size exceeds 200 mb",
            type: ToastType.error,
          })
        );
        return;
      }
      if (file.type === "text/csv") {
        CSV.parse(file, {
          complete(results, file) {
            if (isCSVEmpty(results.data as string[][])) {
              dispatch(
                addToast({
                  id: new Date().getTime(),
                  message: `List doesn’t have any records. Please insert a valid list`,
                  type: "error",
                })
              );
              return;
            }
            const key = `campaign/leads/${new Date().getTime()}.csv`;
            if (setLeadFileKey) {
              setLeadFileKey(key);
            }
            dispatch(
              uploadLeadList({
                key: key,
                method: "put",
                content: file.type,
                file: file,
              })
            );
          },
        });
      }
      if (
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const target = e.target as FileReader;
          if (target) {
            const data = new Uint8Array(target.result as ArrayBuffer);
            const workbook = XLSX.read(data, { type: "array" });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const range = XLSX.utils.decode_range(sheet["!ref"] || "");
            if (
              range.s.c === 0 &&
              range.s.r === 0 &&
              range.e.r === 0 ||(range.e.c === 0 || range.s.r === -1)
            ) {
              dispatch(
                addToast({
                  id: new Date().getTime(),
                  message: `List doesn’t have any records. Please insert a valid list`,
                  type: "error",
                })
              );
              return;
            } else {
              const key = `campaign/leads/${new Date().getTime()}.xlsx`;
              if (setLeadFileKey) {
                setLeadFileKey(key);
              }
              dispatch(
                uploadLeadList({
                  key: key,
                  method: "put",
                  content: file.type,
                  file: file,
                })
              );
            }
          }
        };
        reader.readAsArrayBuffer(file);
      }
    }
  };

  const handleOnDropDownValueChange = () => {
    const filterData = columnsHeader.filter((header: DropDownOption) => {
      let res = true;
      Object.keys(DEFAULT_LIST_DATA).forEach((key: any) => {
        const value = watch(key);
        if (res) {
          res = header.value !== value.toString();
        }
      });
      return res;
    });
    setFilteredColumnsHeader(filterData);
  };

  const onSubmit = (data: any) => {
    try {
      let columnMapping: any[] = [];
      DB_COLUMNS.forEach((column) => {
        if (data[column]) {
          columnMapping.push({
            fileColumnName: data[column],
            dbColumnName: column,
          });
        }
      });
      const payload = {
        name: data?.listName,
        filePath: data?.filePath,
        columnMapping: columnMapping,
      };
      dispatch(saveLeadList(payload));
    } catch (error) {}
  };

  const clearCSVFile = () => {
    reset(DEFAULT_LIST_DATA);
  };
  useEffect(() => {
    if (!openImportFileModal) {
      reset();
      dispatch(setLeadListCSV({ file: null, key: "" }));
    }
  }, [openImportFileModal]);

  useEffect(() => {
    if (watch("listName").length > 0 && watch("listName").trim().length === 0) {
      setValue("listName", "");
    }
  }, [watch("listName")]);

  useEffect(() => {
    return () => {
      dispatch(resetUploadListState({}));
    };
  }, [dispatch]);

  useEffect(() => {
    if (!openImportFileModal) {
      reset();
      dispatch(setLeadListCSV({ file: null, key: "" }));
    }
  }, [openImportFileModal]);

  useEffect(() => {
    if (leadListCSV?.file) {
      setValue("skipTracedList", leadListCSV?.file?.name ?? "");
      setValue("filePath", leadListCSV?.key ?? "");
      trigger("skipTracedList");
      if (leadListCSV?.file.type === "text/csv") {
        Papa.parse(leadListCSV.file, {
          complete: function (results) {
            const headersOption: DropDownOption[] = (
              results.data[0] as string[]
            ).map((header) => ({
              value: header,
              label: header,
            }));
            setColumnsHeader(headersOption);
            setFilteredColumnsHeader(headersOption);
          },
        });
      }
      if (
        leadListCSV?.file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const target = e.target as FileReader;
          if (target) {
            const data = new Uint8Array(target.result as ArrayBuffer);
            const workbook = XLSX.read(data, { type: "array" });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const range = XLSX.utils.decode_range(sheet["!ref"] || "");
            const headers = [];
            for (let C = range.s.c; C <= range.e.c; ++C) {
              const cellAddress = { c: C, r: range.s.r };
              const cellRef = XLSX.utils.encode_cell(cellAddress);
              const headerCellValue = sheet[cellRef]?.v || "";
              if (headerCellValue.trim() !== "") {
                headers.push({
                  value: headerCellValue,
                  label: headerCellValue,
                });
                setColumnsHeader(headers);
                setFilteredColumnsHeader(headers);
              }
            }
          }
        };
        reader.readAsArrayBuffer(leadListCSV.file);
      }
      dispatch(setLeadListCSV({ file: null, key: "" }));
    }
  }, [leadListCSV]);

  return (
    <Dialog className="stepsDialog" onClose={closeModal} open={open}>
      <div className="dialogWrapper">
        <span role="link" onClick={() => closeModal()} className="closeDialog">
          <Close />
        </span>
        <div className={`${styles.uploadList}`}>
          <div className={`${styles.headerIcon}`}>
            <FileHeart />
          </div>
          <div className={`${styles.uploadList__header}`}>
            <h3>Upload a list</h3>
            <p>Upload your own list including phone numbers or upload a list of properties to skip trace and enrich with OttoLeads Data later.</p>
          </div>
          <Input
            type="text"
            label="List Name*"
            register={register}
            name="listName"
            placeholder="Give your list a friendly name"
            errors={errors}
          ></Input>

          {isFileUploading ? (
            <div className={`${styles.loader}`}>
              <Loader />
            </div>
          ) : watch("skipTracedList") ? (
            <>
              <div
                className={`flex alignCenter justifySpaceBetween ${styles.uploadFile}`}
              >
                <span className={`${styles.uploadFile__name}`}>
                  {watch("skipTracedList")}{" "}
                </span>
                <span role="link" onClick={clearCSVFile}>
                  <Close />
                </span>
              </div>
            </>
          ) : (
            <UploadFile
              label="Skip-traced list*"
              name="skipTracedList"
              errors={errors}
              onUpload={handleUplodeCsv}
              uplodeInputRef={uplodeInputRef}
            ></UploadFile>
          )}
          <div className={`${styles.uploadList__header} ${styles.listDesc}`}>
            <h3>Connect your list fields</h3>
            <p>
              Select your list columns that correspond to each of our required
              fields below:
            </p>
          </div>

          <div className={`${styles.step2}`}>
            <div className={`${styles.mainCol}`}>
              <div className={`${styles.mainCol__half}`}>
                <DropDown
                  options={[
                    ...(watch("nameFirst")
                      ? [
                          {
                            value: watch("nameFirst"),
                            label: watch("nameFirst"),
                          },
                        ]
                      : []),
                    ...filteredColumnsHeader,
                  ]}
                  label="First Name*"
                  control={control}
                  name="nameFirst"
                  errors={errors}
                  placeholder="Match first name"
                  disabled={watch("skipTracedList").length === 0}
                  handleOnChange={handleOnDropDownValueChange}
                  clearOnBlur={true}
                ></DropDown>
              </div>
              <div className={`${styles.mainCol__half}`}>
                <DropDown
                  options={[
                    ...(watch("nameLast")
                      ? [
                          {
                            value: watch("nameLast"),
                            label: watch("nameLast"),
                          },
                        ]
                      : []),
                    ...filteredColumnsHeader,
                  ]}
                  label="Last Name*"
                  control={control}
                  name="nameLast"
                  errors={errors}
                  placeholder="Match last name"
                  disabled={watch("skipTracedList").length === 0}
                  handleOnChange={handleOnDropDownValueChange}
                  clearOnBlur={true}
                ></DropDown>
              </div>
            </div>
            <DropDown
              options={[
                ...(watch("addressStreet")
                  ? [
                      {
                        value: watch("addressStreet"),
                        label: watch("addressStreet"),
                      },
                    ]
                  : []),
                ...filteredColumnsHeader,
              ]}
              label="Street Address*"
              control={control}
              name="addressStreet"
              errors={errors}
              placeholder="Match street address"
              disabled={watch("skipTracedList").length === 0}
              handleOnChange={handleOnDropDownValueChange}
              clearOnBlur={true}
            ></DropDown>
            <div className={`${styles.mainCol}`}>
              <div className={`${styles.mainCol__oneThird}`}>
                <DropDown
                  options={[
                    ...(watch("addressCity")
                      ? [
                          {
                            value: watch("addressCity"),
                            label: watch("addressCity"),
                          },
                        ]
                      : []),
                    ...filteredColumnsHeader,
                  ]}
                  label="City*"
                  control={control}
                  name="addressCity"
                  errors={errors}
                  placeholder="Match city"
                  disabled={watch("skipTracedList").length === 0}
                  handleOnChange={handleOnDropDownValueChange}
                  clearOnBlur={true}
                ></DropDown>
              </div>
              <div className={`${styles.mainCol__oneThird}`}>
                <DropDown
                  options={[
                    ...(watch("addressState")
                      ? [
                          {
                            value: watch("addressState"),
                            label: watch("addressState"),
                          },
                        ]
                      : []),
                    ...filteredColumnsHeader,
                  ]}
                  label="State*"
                  control={control}
                  name="addressState"
                  errors={errors}
                  placeholder="Match state"
                  disabled={watch("skipTracedList").length === 0}
                  handleOnChange={handleOnDropDownValueChange}
                  clearOnBlur={true}
                ></DropDown>
              </div>
              <div className={`${styles.mainCol__oneThird}`}>
                <DropDown
                  options={[
                    ...(watch("addressZip")
                      ? [
                          {
                            value: watch("addressZip"),
                            label: watch("addressZip"),
                          },
                        ]
                      : []),
                    ...filteredColumnsHeader,
                  ]}
                  label="Zip code*"
                  control={control}
                  name="addressZip"
                  errors={errors}
                  placeholder="Match ZIP code"
                  disabled={watch("skipTracedList").length === 0}
                  handleOnChange={handleOnDropDownValueChange}
                  clearOnBlur={true}
                ></DropDown>
              </div>
              {/* <div className={`${styles.mainCol__half}`}>
                <DropDown
                  options={[
                    ...(watch("phone1")
                      ? [
                          {
                            value: watch("phone1"),
                            label: watch("phone1"),
                          },
                        ]
                      : []),
                    ...filteredColumnsHeader,
                  ]}
                  label="Phone 1*"
                  control={control}
                  name="phone1"
                  errors={errors}
                  placeholder="Match phone 1"
                  disabled={watch("skipTracedList").length === 0}
                  handleOnChange={handleOnDropDownValueChange}
                  clearOnBlur={true}
                ></DropDown>
              </div>
              <div className={`${styles.mainCol__half}`}>
                <DropDown
                  options={[
                    ...(watch("phone2")
                      ? [
                          {
                            value: watch("phone2"),
                            label: watch("phone2"),
                          },
                        ]
                      : []),
                    ...filteredColumnsHeader,
                  ]}
                  label="Phone 2*"
                  control={control}
                  name="phone2"
                  errors={errors}
                  placeholder="Match phone 2"
                  disabled={watch("skipTracedList").length === 0}
                  handleOnChange={handleOnDropDownValueChange}
                  clearOnBlur={true}
                ></DropDown>
              </div> */}
             

{phoneNumbers?.map((phone, index) => {
  const phoneValue = `phone${index + 1}`;
 
  const value = Array.isArray(phoneValue) ? phoneValue[0] : phoneValue;
console.log(value)
  return (
    <div key={index} className={`${styles.mainCol__half} ${index >= 2 && index === phoneNumbers.length - 1 && styles.trashBtn}`}>
      <DropDown
        options={[
          ...(watch(value) 
            ? [
                {
                  value: watch(value), 
                  label: watch(value), 
                },
              ]
            : []),
          ...filteredColumnsHeader, 
        ]}
        label={`Phone ${index + 1}*`}
        control={control}
        name={`phone${index + 1}`}
        errors={errors}
        placeholder={`Match phone ${index + 1}`}
        disabled={watch("skipTracedList")?.length === 0} 
        handleOnChange={handleOnDropDownValueChange}
        clearOnBlur={true}
      />

      {index >= 2 && index === phoneNumbers.length - 1 && (
        <button className={`${styles.deleteRow}`} type="button" onClick={() => handleDeletePhoneNumber(index)}>
          <Trash/>
        </button>
      )}
    </div>
  );
})}

            </div>
            <div className="flex justifyEnd mt-15">
            <Button
              label="Add Phone Number"
              className="primary animated"
              action={handleAddPhoneNumber}
              prefix={<Plus/>}
            />
            </div>
          </div>
          <div className={`${styles.uploadList__action}`} data-testid="Upload">
            <Tooltip
              title={
                profileData?.userRoleName ===
                  PermissionListEnum.ORGANISATION_VIEWER ||
                profileData?.userRoleName === PermissionListEnum.ORGANISATION_VA
                  ? ROLE_PERMISSION_STATUS.NoAccess
                  : ""
              }
            >
              <div>
                {profileData?.userRoleName ===
                  PermissionListEnum.ORGANISATION_VIEWER ||
                profileData?.userRoleName ===
                  PermissionListEnum.ORGANISATION_VA ? (
                  <Button
                    label={"Upload"}
                    prefix={<Upload />}
                    className="primary xl full animated"
                    disabled={
                      profileData?.userRoleName ===
                        PermissionListEnum.ORGANISATION_VIEWER ||
                      profileData?.userRoleName ===
                        PermissionListEnum.ORGANISATION_VA
                    }
                  />
                ) : (
                  <Button
                    label={"Upload"}
                    prefix={<Upload />}
                    className="primary xl full animated"
                    action={handleSubmit(onSubmit)}
                    clicked={listUploading}
                    disabled={listUploading}
                  ></Button>
                )}
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
export default UploadList;
