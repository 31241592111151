
const ArrowTangle = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                <path d="M12 17.6001H16.2942C18.1594 17.6001 19.092 17.6001 19.6215 17.2093C20.0832 16.8686 20.3763 16.346 20.4263 15.7744C20.4836 15.1188 19.9973 14.323 19.0247 12.7314L18.0278 11.1001M5.13014 9.20531L2.97528 12.7315C2.00267 14.323 1.51637 15.1188 1.57372 15.7744C1.62372 16.346 1.91681 16.8686 2.37846 17.2093C2.90799 17.6001 3.84059 17.6001 5.70578 17.6001H7.5M15.8889 7.60009L13.7305 4.06818C12.8277 2.59089 12.3763 1.85224 11.7952 1.60043C11.2879 1.38059 10.7121 1.38059 10.2048 1.60043C9.62369 1.85224 9.17229 2.59089 8.2695 4.06818L7.24967 5.73699M17 3.60016L15.9019 7.69823L11.8038 6.60016M1 10.1982L5.09808 9.10009L6.19615 13.1982M14.5 20.6001L11.5 17.6001L14.5 14.6001"
                    stroke="currentColor"
                    strokeOpacity="1"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round" />
            </svg>
        </>
    );
};

export default ArrowTangle;