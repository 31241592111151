
const OffMarket = () => {
    return (
<svg xmlns="http://www.w3.org/2000/svg" width="17" height="20" viewBox="0 0 17 20" fill="none">
<path d="M9.66671 9.3667H4.66671M6.33337 12.7H4.66671M11.3334 6.03337H4.66671M14.6667 10.2V5.8667C14.6667 4.46657 14.6667 3.7665 14.3942 3.23172C14.1545 2.76132 13.7721 2.37887 13.3017 2.13918C12.7669 1.8667 12.0668 1.8667 10.6667 1.8667H5.33337C3.93324 1.8667 3.23318 1.8667 2.6984 2.13918C2.22799 2.37887 1.84554 2.76132 1.60586 3.23172C1.33337 3.7665 1.33337 4.46657 1.33337 5.8667V14.5334C1.33337 15.9335 1.33337 16.6336 1.60586 17.1683C1.84554 17.6387 2.22799 18.0212 2.6984 18.2609C3.23318 18.5334 3.93324 18.5334 5.33337 18.5334H8.00004M11.3334 13.5334L15.5 17.7M15.5 13.5334L11.3334 17.7" 
stroke="currentColor" 
strokeWidth="2" 
strokeLinecap="round" 
strokeLinejoin="round"/>
</svg>

    );
};

export default OffMarket;