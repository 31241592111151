const CheckCircleTick = () => {
    return (
        <>
           <svg xmlns="http://www.w3.org/2000/svg" width="133" height="123" viewBox="0 0 133 123" fill="none">
<path d="M97.9522 18.2161C99.6995 16.1413 99.4444 13.0249 97.2368 11.4487C87.3239 4.37052 75.497 0.373072 63.2477 0.0248373C49.3566 -0.370071 35.7413 3.95128 24.6205 12.2847C13.4997 20.618 5.52901 32.4722 2.00744 45.9152C-1.51413 59.3583 -0.379004 73.5978 5.22784 86.3132C10.8347 99.0286 20.5827 109.47 32.8833 115.937C45.1839 122.403 59.3119 124.513 72.9649 121.922C86.618 119.331 98.9912 112.193 108.068 101.67C116.072 92.3913 121.097 80.9631 122.557 68.8702C122.882 66.1773 120.753 63.8871 118.043 63.7779C115.332 63.6687 113.071 65.7834 112.705 68.4712C111.361 78.3442 107.184 87.6566 100.63 95.2542C93.003 104.096 82.6061 110.094 71.1337 112.271C59.6614 114.448 47.7899 112.675 37.454 107.242C27.1181 101.808 18.9271 93.0344 14.2158 82.3499C9.50448 71.6655 8.55066 59.7004 11.5098 48.4045C14.4689 37.1086 21.1664 27.1478 30.511 20.1455C39.8555 13.1431 51.2962 9.51199 62.9685 9.84382C72.9982 10.129 82.6909 13.3257 90.8869 18.992C93.1182 20.5345 96.2049 20.2909 97.9522 18.2161Z" fill="#16B464"/>
<path d="M123.002 19.1963L62.8792 79.6298L35.5508 52.16" 
stroke="#16B464" 
strokeWidth="20" 
strokeLinecap="round" 
strokeLinejoin="round"/>
</svg>
        </>
    );
};

export default CheckCircleTick;


