import { motion } from "framer-motion"
import styles from "./VATestimonialsSection.module.scss";
import AICallingTable from "../aiCallingTable/AICallingTable";
import { tableData, testimonial } from "./constants";
import { Star } from "lucide-react";
const VATestimonialsSection = () => {
    return (
        <section className={`${styles.main}`}>
            <div className={`${styles.main__service}`}>
                <div className="wrapper-lg">
                    <motion.header
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true }}
                        transition={{ duration: 0.5 }}

                    >
                        <h2>Why Choose OttoLeads Over Other VA Providers?</h2>
                        <p>
                            Experience the OttoLeads Advantage
                        </p>
                    </motion.header>
                    {/* Features Grid */}
                    <div className={`${styles.main__table}`}>
                        <AICallingTable data={tableData} />
                    </div>
                </div>
            </div>
            <div className={`${styles.main__choose}`}>
                <div className="wrapper-lg">
                    <motion.header
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true }}
                        transition={{ duration: 0.5 }}

                    >
                        <h2>Client Testimonials</h2>

                    </motion.header>
                    {/* Features Grid */}
                    <div className={`${styles.main__testimonial} dflex justifyCenter`}>

                        {testimonial.map((testimonial, index) => (
                            <motion.div
                                key={index}
                                initial={{ opacity: 0, y: 20 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                viewport={{ once: true }}
                                transition={{ duration: 0.5, delay: index * 0.1 }}
                                className={`${styles.main__testimonialCard}`}
                            >
                                <div className={`flex ${styles.main__testimonialStar}`}>
                                    {[...Array(5)].map((_, i) => (
                                        <Star width={18} key={i} />
                                    ))}
                                </div>
                                <p>{testimonial.description}</p>
                                <div className={`flex alignCenter ${styles.main__testimonialCardInfo}`}>
                                    <figure className={`flex alignCenter justifyCenter `}>
                                        <img src={testimonial.icon} alt={testimonial.title} />
                                    </figure>
                                    <figcaption>
                                        <h3>{testimonial.title}</h3>
                                        <p>{testimonial.profile}</p>
                                    </figcaption>
                                </div>
                            </motion.div>
                        ))}

                    </div>

                </div>
            </div>
        </section>
    )
}

export default VATestimonialsSection;
