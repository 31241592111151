import { motion } from "framer-motion"
import styles from "./VABenefitSection.module.scss";
import { choose, features } from "./constants";
import vaImage from "../../assets/images/va-img.jpeg";
import Button from "../../atoms/Button";

const VABenefitSection = ({handleSignUp}:any) => {
    return (
        <section className={`${styles.main}`}>
            <div className={`${styles.main__service}`}>
                <div className="wrapper-lg">
                    <motion.header
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true }}
                        transition={{ duration: 0.5 }}

                    >
                        <h2>Benefits of Using OttoLeads VAs</h2>
                        <p>
                        Maximize Efficiency and ROI
                        </p>
                    </motion.header>
                    {/* Features Grid */}
                    <div className={`${styles.main__feature} dflex alignCenter`}>
                        <div className={`${styles.main__steps}`}>
                            {features.map((feature, index) => (
                                <motion.div
                                    key={index}
                                    initial={{ opacity: 0, y: 20 }}
                                    whileInView={{ opacity: 1, y: 0 }}
                                    viewport={{ once: true }}
                                    transition={{ duration: 0.5, delay: index * 0.1 }}
                                    className={`${styles.main__featureCard} flex`}
                                >
                                   
                                    <figure className={`flex alignCenter justifyCenter `}>
                                        <feature.icon />
                                    </figure>
                                    <figcaption>
                                    <h3>{feature.title}</h3>
                                    <p>{feature.description}</p>
                                    </figcaption>
                                </motion.div>
                            ))}
                            <Button label="Sign Up Today" className="primary"  action={handleSignUp}/>
                        </div>
                        <div className={`${styles.main__img}`}>
                            <img src={vaImage} alt="VA" />
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${styles.main__choose}`}>
                <div className="wrapper-lg">
                    <motion.header
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true }}
                        transition={{ duration: 0.5 }}

                    >
                        <h2>Who Can Benefit from OttoLeads VA Services?</h2>
                        <p>
                        Tailored Solutions for Various Needs
                        </p>
                    </motion.header>
                    {/* Features Grid */}
                    <div className={`${styles.main__chooseRow} dflex justifyCenter`}>

                            {choose.map((choose, index) => (
                                <motion.div
                                    key={index}
                                    initial={{ opacity: 0, y: 20 }}
                                    whileInView={{ opacity: 1, y: 0 }}
                                    viewport={{ once: true }}
                                    transition={{ duration: 0.5, delay: index * 0.1 }}
                                    className={`${styles.main__chooseCard}`}
                                >
                                   
                                    <figure className={`flex alignCenter justifyCenter `}>
                                        <choose.icon />
                                    </figure>
                                    <h3>{choose.title}</h3>
                                    <p>{choose.description}</p>
                                </motion.div>
                            ))}
                        
                    </div>
                    <div  className={`flex justifyCenter ${styles.main__btn}`}>
                    <Button label="Get Started Today" className="primary" action={handleSignUp} />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default VABenefitSection;
