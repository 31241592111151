import styles from "./Home.module.scss";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { addToast } from "../../molecules/toaster/toastSlice";
import { ToastType } from "../../molecules/toaster/enum";
import { useDispatch, useSelector } from "react-redux";
import { getProfileInfo } from "../../components/profileMenu/selectors"
import MeetOttoSection from "./meetOttoSec/MeetOttoSec";
import HappyCustomerSec from "./happyCustomerSec/HappyCustomerSec";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getParamsValue, hasNullValue } from "../../utils/utils";
import ContentSec from "./contentSec/ContentSec";
import RotatingProfession from "./rotatingProfession/RotatingProfession";
import  ScrollingProperties  from "./scrollingProperties/ScrollingProperties";
import HowItWork from "./howItWork/HowItWork";
import Discover from "../../components/discover/Discover";
import HomeFAQ from "../../components/homeFAQ/HomeFAQ";
import Register from "../../organisms/auth/Register";

const Home = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const meetOttoRef = useRef<HTMLDivElement | null>(null);
    const [openShareModal, setOpenShareModal] = useState<boolean>(false);
    const [openLoginModal, setOpenLoginModal] = useState<boolean>(false);
    const [openRegisterModal, setOpenRegisterModal] = useState<boolean>(false);
    const [forgotPassword, setForgotpassword] = useState<boolean>(false);
    const [email, setEmail] = useState<string>(hasNullValue("email") ? getParamsValue("email") ?? "" : "");
    const [resetPassword, setResetPassword] = useState<boolean>(false);
    const [openVerifyOtp, setOpenVerifyOtp] = useState<boolean>(false);
    const [openResetPasswordModal, setOpenResetPasswordModal] = useState(false)
    const [openEarlyAccessModal, setOpenEarlyAccessModal] = useState<boolean>(false);
    const profileInfo: any = useSelector((state) => getProfileInfo(state));

    // const betaSignup = searchParams.get("beta-signup");
    const createAccount = hasNullValue("create-account") ? getParamsValue("create-account") ?? false : false;
    const upgradeNowFlag = searchParams.get("upgrade-now");

    const openForgotPasswordPopup = () => {
        setOpenLoginModal(false);
        setForgotpassword(true);
    };

    const handleShowToast = (message: string, type: ToastType = ToastType.success) => {
        dispatch(
            addToast({
                id: new Date().getTime(),
                message: message,
                type: type,
            })
        );
    };

    const handleSignUp = () => {
        setOpenRegisterModal(!openRegisterModal);
      };

      
    // const verifyAccount = async (OTP: string) => {
    //   try {
    //     setOtpVerificationLoading(true);
    //     const data: any = await verifyViaOtp({ email: email, OTP });
    //     handleShowToast(data?.message);
    //     setOpenLoginModal(true);
    //     setOpenVerifyOtp(false);
    //     setOtpVerificationLoading(false);
    //   } catch (err: any) {
    //     console.error(err);
    //     handleShowToast(err.message, ToastType.error);
    //     setOtpVerificationLoading(false);
    //   }
    // };

    useEffect(() => {
        if (profileInfo?.id) {
            switch (profileInfo?.organisation.stepsCompleted) {
                case 0: {
                    navigate("/onboarding");
                    break;
                }
                case 1: {
                    navigate("/onboarding");
                    break;
                }
                case 2: {
                    navigate("/campaigns");
                    break;
                }
            }
        }
    }, [profileInfo]);

    useEffect(() => {
        document.body.classList.add("home-layout");
        return () => {
            document.body.classList.remove("home-layout");
        };
    }, []);

    const scrollToMeetOtto = () => {
        if (meetOttoRef.current) {
            meetOttoRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    useEffect(() => {
        if (createAccount === "true") {
            if (localStorage?.getItem("accessToken")) {
                return;
            }
            setOpenRegisterModal(true);
        }
    }, [createAccount, setOpenRegisterModal]);

    useEffect(() => {
        if (upgradeNowFlag && !localStorage.getItem("accessToken")) {
            setOpenLoginModal(true);
            localStorage.setItem("upgrade-now", "true");
        }
    }, [upgradeNowFlag]);

    useEffect(() => {
        if (window.location.pathname.includes("reset-password")) {
            setOpenResetPasswordModal(true)
        }
    }, []);

    useEffect(() => {
        document.body.classList.add("home-layout");
        return () => {
            document.body.classList.remove("home-layout");
        };
    }, []);

    useEffect(() => {
        if (profileInfo?.id) {
            switch (profileInfo?.organisation.stepsCompleted) {
                case 0: {
                    navigate("/onboarding");
                    break;
                }
                case 1: {
                    navigate("/onboarding");
                    break;
                }
                case 2: {
                    navigate("/campaigns");
                    break;
                }
            }
        }
    }, [profileInfo]);

    useEffect(() => {
        window.scrollTo(0, 0);
      },[]);

    return (
      
        <>
          <ScrollingProperties handleSignUp={handleSignUp} />
          <HowItWork />
          <div ref={meetOttoRef}>
            <MeetOttoSection />
          </div>
          <RotatingProfession />
          <ContentSec />
          <HappyCustomerSec />
          <Discover />
          <HomeFAQ />
          {openRegisterModal ? (
        <Register
          open={openRegisterModal}
          setOpen={setOpenRegisterModal}
          openLogin={true}
          email={email}
        ></Register>
      ) : (
        ""
      )}
        </>
      
    );
};

export default Home;
