import { motion } from "framer-motion"
import styles from "./CRMComparison.module.scss";
import AICallingTable from "../aiCallingTable/AICallingTable";
import { tableData } from "./constants";
const CRMComparison = ({handleSignUp}:any) => {
    return (
        <section className={`${styles.main}`}>
                <div className="wrapper-lg">
                    <motion.header
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true }}
                        transition={{ duration: 0.5 }}

                    >
                        <h2>Why the OttoLeads CRM is Better than Generic CRMs</h2>
                        <p>
                        Most CRMs are not built for real estate. The OttoLeads CRM is.
                        </p>
                    </motion.header>
                    {/* Features Grid */}
                    <div className={`${styles.main__table}`}>
                        <AICallingTable data={tableData} />
                    </div>
            </div>

        </section>
    )
}

export default CRMComparison;
