import styles from "./BlogCard.module.scss";
import blogImage from "../../../assets/images/map-img.jpeg";

const BlogCard = () => {

    return <div className={` ${styles.card}`}>
        <img src={blogImage} alt="blog" />
        <div className={` ${styles.card__info}`}>
        <h2>Blog title here</h2>
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
        <span className={` ${styles.card__link}`}>Read More</span>
        </div>
    </div>;
}
export default BlogCard;