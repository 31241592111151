
import { List, Fingerprint, Clock4, House, ShieldCheck, ChartLine, Home} from "lucide-react"
import ListFlat from "../../assets/icons/linkFlat"
import Cost from "../../assets/icons/cost"
import Building from "../../assets/icons/building"
import CurrencyDollar from "../../assets/icons/currencyDoller"
import Refresh from "../../assets/icons/refresh"
import Agency from "../../assets/icons/agency"
import DollarNote from "../../assets/icons/dollerNote"
import ArrowTangle from "../../assets/icons/arrowTrangle"


 export const features = [
    {
      icon: Clock4,
      title: "Time-Saving",
      description: "Delegate time-consuming cold calling tasks to our VAs, freeing up your schedule for high-priority activities.",
    },
    {
      icon: House,
      title: "Expertise in Real Estate",
      description: "Our VAs are trained specifically in real estate lead generation and qualification.",
    },
    {
      icon: ChartLine,
      title: "Scalable Solutions",
      description: "Adjust the level of VA support based on your current needs and market conditions.",
    },
    {
        icon: ShieldCheck,
        title: "Enhanced Lead Quality",
        description: "Focus on leads that have been pre-qualified, increasing your chances of successful conversions.",
      },
  ]
  export const choose = [
    {
      icon: Home,
      title: "New Investors",
      description: "Kickstart your business without the overhead of a full team.",
    },
    {
      icon: Agency,
      title: "Small Agencies",
      description: "Access professional outreach services without significant investments in staffing.",
    },
    {
      icon: DollarNote,
      title: "Budget-Friendly",
      description: "An affordable alternative to AI calling for those with limited marketing budgets.",
    },
    {
        icon: ArrowTangle,
        title: "AI Calling Users",
        description: "Complement your AI campaigns by having VAs re-engage leads that require a human touch.",
      },
  ]