const Agency = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="27" viewBox="0 0 30 27" fill="none">
                <path d="M13.6665 12.2668H7.2665C5.77303 12.2668 5.02629 12.2668 4.45586 12.5574C3.9541 12.8131 3.54615 13.221 3.29049 13.7228C2.99984 14.2932 2.99984 15.04 2.99984 16.5334V25.6001M26.9998 25.6001V5.86676C26.9998 4.37329 26.9998 3.62655 26.7092 3.05612C26.4535 2.55436 26.0456 2.14641 25.5438 1.89075C24.9734 1.6001 24.2266 1.6001 22.7332 1.6001H17.9332C16.4397 1.6001 15.693 1.6001 15.1225 1.89075C14.6208 2.14641 14.2128 2.55436 13.9572 3.05612C13.6665 3.62655 13.6665 4.37329 13.6665 5.86676V25.6001M28.3332 25.6001H1.6665M18.3332 6.93343H22.3332M18.3332 12.2668H22.3332M18.3332 17.6001H22.3332"
                    stroke="currentColor"
                    strokeOpacity="1"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round" />
            </svg>
        </>
    );
};

export default Agency;
