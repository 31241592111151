import { motion } from "framer-motion";
import { categories, heading, title } from "./constants";
import styles from "./TargetSellersSection.module.scss";

const TargetSellersSection = () => {
  return (
    <section className={`${styles.target}`}>
      <div className="wrapper-lg">
        <header>
          <h2>{title}</h2>
          <p>{heading}</p>
        </header>

        <div className={`dflex ${styles.target__cat}`}>
          {categories.map((category, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className={` ${styles.target__catCard}`}
            >
              <figure>
                <img
                  src={category.image || "/placeholder.svg"}
                  alt={category.title}
                />
              </figure>
              <article>
                <h3>{category.title}</h3>
                <p>{category.description}</p>
              </article>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default TargetSellersSection;
