import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCallAttemptsData,
  getCallAttemptsLoading,
  getNoteTemplateData,
  getPropertyDetails,
} from "../../property.selector";
import { ContactInfoItemType } from "../../property.type";
import PhoneV2 from "../../../../assets/icons/phoneV2";
import { Tooltip } from "@mui/material";
import { copyToClipboard, formatPhoneNumber } from "../../../../utils/utils";
import Copy from "../../../../assets/icons/copy";
import { callAttemptsOptions } from "../../../../constants/callAttemps.constants";
import DropDown from "../../../../atoms/DropDown";
import { useForm } from "react-hook-form";
import VoiceMail from "../../../../assets/icons/voiceMail";
import Disconnected from "../../../../assets/icons/disconnected";
import WrongNumber from "../../../../assets/icons/wrongNumber";
import DidNotPickUp from "../../../../assets/icons/didNotPickUp";
import HangUp from "../../../../assets/icons/hungUp";
import NotInterested from "../../../../assets/icons/notInterested ";
import DNC from "../../../../assets/icons/dnc";
import CallBackLater from "../../../../assets/icons/callBackLater";
import Verified from "../../../../assets/icons/verified";
import styles from "./CallAttempts.module.scss";
import { ToastType } from "../../../../molecules/toaster/enum";
import {  editCallAttempt } from "../../property.service";
import { getSelectedLeadId } from "../../../../organisms/leadsTable/selectors";
import { addToast } from "../../../../molecules/toaster/toastSlice";
import AddedCallAttempts from "../../addedCallAttempts/AddedCallAttempts";
import CallAttemptsNote from "../callAttemptsNote/CallAttemptsNote";
import Button from "../../../../atoms/Button";
import Plus from "../../../../assets/icons/plus";
import { fetchCallAttempts, fetchNotesTemplate } from "../../property.sagaAction";
import Loader from "../../../../atoms/Loader";
import { PermissionListEnum } from "../../../../organisms/auth/enum";
import { getProfileInfo } from "../../../../components/profileMenu/selectors";
import { ROLE_PERMISSION_STATUS } from "../../../../constants/constants";

export const CALL_ATTEMPT_STATUS_ICONS = {
  LeftVoiceMail: <VoiceMail />,
  Disconnected: <Disconnected />,
  WrongNumber: <WrongNumber />,
  DoNotPickUp: <DidNotPickUp />,
  HungUp: <HangUp />,
  NotInterested: <NotInterested />,
  DNC: <DNC />,
  CallBackLater: <CallBackLater />,
  Verified: <Verified />,
};

const callAttemptsStatusOptions = Object.entries(callAttemptsOptions).map(
  ([key, value]) => ({
    label: key,
    value: value,
    prefix: CALL_ATTEMPT_STATUS_ICONS[value],
  })
);

const CallAttempts = () => {
  const {
    formState: { errors },
    control,
    setValue,
    getValues,watch
  } = useForm({
    mode: "onSubmit",
  });
  const dispatch = useDispatch();
  const propertyDetails = useSelector((state) => getPropertyDetails(state));
  const callAttemptData = useSelector((state) => getCallAttemptsData(state));
  const notesTemplate = useSelector((state) => getNoteTemplateData(state));
  const callAttemptLoading = useSelector((state) =>
    getCallAttemptsLoading(state)
  );
  const [selectedStatuses, setSelectedStatuses] = useState<{ [key: string]: string }>({});
  const [logButtonLoading, setLogButtonLoading] = useState(false);
  const [noteContent, setNoteContent] = useState<string>('');
  const profileInfo = useSelector((state) => getProfileInfo(state));
  const [templateListOptions, setTemplateListOptions] = useState<Record<string, any>[]>([]);
  const contactInfo = useMemo(() => {
    const phNumber = propertyDetails?.phones
      ? [...propertyDetails?.phones]
      : [];
    phNumber.sort((a: any, b: any) => {
      if ((a?.priority || 0) > (b?.priority || 0)) {
        return 1;
      } else {
        return -1;
      }
    });
    return phNumber as ContactInfoItemType[];
  }, [propertyDetails?.phones]);

  const handleShowToast = (
    message: string,
    type: ToastType = ToastType.success
  ) => {
    dispatch(
      addToast({
        id: new Date().getTime(),
        message: message,
        type: type,
      })
    );
  };

  const handleOnFocus = (e: any) => (e.target.readOnly = true);

  const handleAddNote = async (htmlContent: string) => {
    setNoteContent(htmlContent);
  };

  const handleStatusChange = (item: any, status: any) => {
    setSelectedStatuses((prevStatuses) => ({
      ...prevStatuses,
      [`status_${item.id}`]: status.value,
    }));
  };

  const isAnyStatusSelected = Object.values(selectedStatuses).some(
    (status) => status && status !== "" 
  );

    useEffect(() => {
      const options = notesTemplate.map((list: any) => ({
        value: list?.form,
        label: list.name,
      }));
      setTemplateListOptions(options);
    }, [notesTemplate]);

  const handleAddLogAttempt = async() => {
    const attemptPayload = {
      attempt: callAttemptData.lastCallAttempt + 1, 
      phones: contactInfo.map((item) => ({
        phoneNumber: item.number,
        status: selectedStatuses[`status_${item.id}`] || "Hidden", 
      })),
      summary:noteContent
    };
    try {
      setLogButtonLoading(true);
      await editCallAttempt(propertyDetails.leadId, attemptPayload);
      setValue("template","")
      setLogButtonLoading(false);
      dispatch(fetchCallAttempts(propertyDetails.leadId));
    } catch (error:any) {
      console.log(error);
      handleShowToast(error.response.data.message, ToastType.error);
      setLogButtonLoading(false);
    }
  };

  useEffect(() => {
    dispatch(fetchCallAttempts(propertyDetails.leadId));
    dispatch(fetchNotesTemplate({}))
  }, [propertyDetails.leadId]);

  useEffect(() => {
    if (callAttemptData?.lastCallAttempt > 0) {
      const lastAttemptLog =
      callAttemptData.callAttemptLogs.find(
        (log:any) => log.attempt === callAttemptData.lastCallAttempt
      );
      if (lastAttemptLog) {
        contactInfo.forEach((item) => {
          const matchingCall = lastAttemptLog.callAttempts.find(
            (call: any) => call.phoneNumber === item.number
          );
          if (matchingCall) {
            setValue(`status_${item.id}`, matchingCall.status);
            setSelectedStatuses((prev) => ({
              ...prev,
              [`status_${item.id}`]: matchingCall.status,
            }));
          }
        });
      }
    }
  }, [callAttemptData, contactInfo, setValue]);  
  return (
    <>
      {callAttemptLoading ? (
        <div className={`loader-main`}>
          <Loader></Loader>
        </div>
      ) : (
        <div className={` ${styles.call}`}>
          <div className="wrapper">
            <div
              className={`flex justifySpaceBetween alignCenter ${styles.call__header}`}
            >
              <h3>Call Attempt #{callAttemptData.lastCallAttempt + 1}</h3>
              <Tooltip
                title={
                  profileInfo?.userRoleName ===
                  PermissionListEnum.ORGANISATION_VIEWER
                    ? ROLE_PERMISSION_STATUS.NoAccess
                    : ""
                }
              >
               <div className={`${styles.call__headerRight} flex alignCenter`}>
                  <DropDown
                    options={templateListOptions}
                    clearOnBlur={true}
                    label=""
                    control={control}
                    gradientBorder
                    name={`template`}
                    errors={errors}
                    placeholder="Select template"
                    closeOnOutsideClick={true}
                    handleOnChange={(template: any) =>
                      console.log("template.value")
                    }
                    handleOnFocus={(e: any) => handleOnFocus(e)}
                  />

                  <Button
                    className=" outline"
                    label="Call Attempt"
                    prefix={<Plus />}
                    action={handleAddLogAttempt}
                    disabled={
                      !isAnyStatusSelected ||
                      logButtonLoading ||
                      profileInfo?.userRoleName ===
                        PermissionListEnum.ORGANISATION_VIEWER
                    }
                    clicked={logButtonLoading}
                  />
                </div>
              </Tooltip>
            </div>
            <div className="dflex">
              <div className={` ${styles.call__info}`}>
                <div className={`dflex alignStart`}>
                  {contactInfo?.length > 0 && (
                    <div className={`${styles.call__main} dflex`}>
                      {contactInfo?.map((item: any, count) => (
                        <div
                          className={` ${styles.call__attemptPhone} flex alignCenter`}
                          key={item.id}
                        >
                          <figure className="flex alignCenter">
                            <PhoneV2 /> <label>Phone {count + 1}</label>
                          </figure>
                          <figcaption>
                            <div
                              className={` ${styles.call__attemptPhoneMain} flex alignCenter`}
                            >
                              <a href={`tel:${item.number}`}>
                                {formatPhoneNumber(item.number)}
                              </a>
                              <Tooltip title={"Copy Phone Number"}>
                                <span
                                  role="link"
                                  className={` ${styles.call__copy} copy-icon`}
                                  onClick={(e: any) => {
                                    e.stopPropagation();
                                    copyToClipboard(item.number);
                                  }}
                                >
                                  <Copy />
                                </span>
                              </Tooltip>
                            </div>
                          </figcaption>
                          <div
                            className={` ${styles.call__status} ${
                              selectedStatuses[`status_${item.id}`] ===
                                "Hidden" && styles.disabled
                            }`}
                          >
                            <DropDown
                              options={callAttemptsStatusOptions}
                              clearOnBlur={true}
                              label=""
                              control={control}
                              name={`status_${item.id}`}
                              errors={errors}
                              placeholder="Call Status"
                              closeOnOutsideClick={true}
                              handleOnChange={(status: any) =>
                                handleStatusChange(item, status)
                              }
                              handleOnFocus={(e: any) => handleOnFocus(e)}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              <div className={` ${styles.call__note}`}>
                <CallAttemptsNote onSend={handleAddNote} newAttempt={true} template={watch("template")} />
              </div>
            </div>
            {callAttemptData?.lastCallAttempt > 0 && (
              <AddedCallAttempts
                attemptsDetail={callAttemptData.callAttemptLogs}
                callAttemptsStatusOptions={callAttemptsStatusOptions}
                control={control}
                errors={errors}
                getValues={getValues}
                setValue={setValue}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default CallAttempts;
