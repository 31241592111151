const CalculatorIcon = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
<path d="M16.2998 5.30005L5.2998 16.3M7.2998 9.30005V5.30005M5.2998 7.30005H9.2998M12.2998 14.3H16.2998M6.5998 19.8H14.9998C16.68 19.8 17.52 19.8 18.1618 19.4731C18.7263 19.1854 19.1852 18.7265 19.4728 18.162C19.7998 17.5203 19.7998 16.6802 19.7998 15V6.60005C19.7998 4.91989 19.7998 4.07981 19.4728 3.43808C19.1852 2.87359 18.7263 2.41465 18.1618 2.12703C17.52 1.80005 16.68 1.80005 14.9998 1.80005H6.5998C4.91965 1.80005 4.07957 1.80005 3.43783 2.12703C2.87335 2.41465 2.41441 2.87359 2.12679 3.43808C1.7998 4.07981 1.7998 4.91989 1.7998 6.60005V15C1.7998 16.6802 1.7998 17.5203 2.12679 18.162C2.41441 18.7265 2.87335 19.1854 3.43783 19.4731C4.07957 19.8 4.91965 19.8 6.5998 19.8Z" 
stroke="currentColor" 
strokeOpacity="1" 
strokeWidth="2" 
strokeLinecap="round" 
strokeLinejoin="round"/>
</svg>

        </>
    );
};

export default CalculatorIcon;

