import { motion } from "framer-motion";
import { bottomText, features, heading, title } from "./constants";
import { Bot, Zap } from "lucide-react";
import styles from "./SkipTracingSection.module.scss";

const SkipTracingSection = () => {
  return (
    <section className={`${styles.skip}`}>
      <div className="wrapper-lg">
        <motion.header
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
        >
          <h2>{title}</h2>
          <p>{heading}</p>
        </motion.header>

        <div className={`${styles.skip__row} dflex`}>
          {features.map((feature, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className={`${styles.skip__box}`}
            >
                <div   className={`${styles.skip__boxInfo}`}>
              <figure className="flex alignCenter justifyCenter">
                <feature.icon />
              </figure>

              <h3>{feature.title}</h3>
              <p>{feature.description}</p>
              </div>
              {/* Example UI Element */}
              {index === 0 && (
                <div className={`${styles.skip__boxFooter} flex alignCenter`}>
                  <figure>
                    <img
                      src="https://randomuser.me/api/portraits/men/75.jpg"
                      alt="Property owner"
                      width={32}
                      height={32}
                      className="object-cover"
                    />
                  </figure>
                  <figcaption>
                    <h5>John Wilson</h5>
                    <p>Owner • (555) 123-4567</p>
                  </figcaption>
                </div>
              )}

              {index === 1 && (
                <div className={`${styles.skip__boxFooter} flex alignCenter`}>
                  <Bot />
                  <p className={`${styles.colorLight}`}>
                    AI calling 4,328 property owners
                  </p>
                </div>
              )}

              {index === 2 && (
                <div className={`${styles.skip__boxFooter} flex alignCenter`}>
                  <Zap />
                  <p className={`${styles.colorLight}`}>
                    47 new leads ready for follow-up
                  </p>
                </div>
              )}
            </motion.div>
          ))}
        </div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5, delay: 0.4 }}
          className={`${styles.skip__text}`}
        >
          <p>{bottomText}</p>
        </motion.div>
      </div>
    </section>
  );
};

export default SkipTracingSection;
