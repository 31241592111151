import property1Part1 from "../../../assets/images/property1Part1.jpg" 
import property1Part2 from "../../../assets/images/property1Part2.jpg" 
import property1Part3 from "../../../assets/images/property1Part3.jpg" 
import property2Part1 from "../../../assets/images/property2Part1.jpg" 
import property2Part2 from "../../../assets/images/property2Part2.jpg" 
import property2Part3 from "../../../assets/images/property2Part3.jpg" 
import property3Part1 from "../../../assets/images/property3Part1.jpg" 
import property3Part2 from "../../../assets/images/property3Part2.jpg" 
import property3Part3 from "../../../assets/images/property3Part3.jpg" 
import property4Part1 from "../../../assets/images/property4Part1.jpg" 
import property4Part2 from "../../../assets/images/property4Part2.jpg" 
import property4Part3 from "../../../assets/images/property4Part3.jpg"

import property5Part1 from "../../../assets/images/property5Part1.jpg" 
import property5Part2 from "../../../assets/images/property5Part2.jpg" 
import property5Part3 from "../../../assets/images/property5Part3.jpg" 

import property6Part1 from "../../../assets/images/property6Part1.jpg" 
import property6Part2 from "../../../assets/images/property6Part2.jpg" 
import property6Part3 from "../../../assets/images/property6Part3.jpg" 

import property7Part1 from "../../../assets/images/property7Part1.jpg" 
import property7Part2 from "../../../assets/images/property7Part2.jpg" 
import property7Part3 from "../../../assets/images/property7Part3.jpg" 

import property8Part1 from "../../../assets/images/property8Part1.jpg" 
import property8Part2 from "../../../assets/images/property8Part2.jpg" 
import property8Part3 from "../../../assets/images/property8Part3.jpg" 

import property9Part1 from "../../../assets/images/property9Part1.jpg" 
import property9Part2 from "../../../assets/images/property9Part2.jpg" 
import property9Part3 from "../../../assets/images/property9Part3.jpg" 

import property10Part1 from "../../../assets/images/property10Part1.jpg" 
import property10Part2 from "../../../assets/images/property10Part2.jpg" 
import property10Part3 from "../../../assets/images/property10Part3.jpg" 

import property11Part1 from "../../../assets/images/property11Part1.jpg" 
import property11Part2 from "../../../assets/images/property11Part2.jpg" 
import property11Part3 from "../../../assets/images/property11Part3.jpg" 

import property12Part1 from "../../../assets/images/property12Part1.jpg" 
import property12Part2 from "../../../assets/images/property12Part2.jpg" 
import property12Part3 from "../../../assets/images/property12Part3.jpg" 

import property13Part1 from "../../../assets/images/property13Part1.jpg" 
import property13Part2 from "../../../assets/images/property13Part2.jpg" 
import property13Part3 from "../../../assets/images/property13Part3.jpg" 

import property14Part1 from "../../../assets/images/property14Part1.jpg" 
import property14Part2 from "../../../assets/images/property14Part2.jpg" 
import property14Part3 from "../../../assets/images/property14Part3.jpg" 

import property15Part1 from "../../../assets/images/property15Part1.jpg" 
import property15Part2 from "../../../assets/images/property15Part2.jpg" 
import property15Part3 from "../../../assets/images/property15Part3.jpg" 

import property16Part1 from "../../../assets/images/property16Part1.jpg" 
import property16Part2 from "../../../assets/images/property16Part2.jpg" 
import property16Part3 from "../../../assets/images/property16Part3.jpg" 

import property17Part1 from "../../../assets/images/property17Part1.jpg" 
import property17Part2 from "../../../assets/images/property17Part2.jpg" 
import property17Part3 from "../../../assets/images/property17Part3.jpg" 

export const properties = [
    {
        id: 1,
        images: [property1Part3,property1Part2,
          property1Part1
          
        ],
        price: "$415,200",
        beds: 3,
        baths: 2.5,
        sqft: 2850,
        year: 2004,
        address: "1123 Pinecrest Dr, Oakdale, TX",
        city: "Springfield",
        state: "OH",
        zip: "63999",
        lastSale: "7/15/2023",
        soldPrice: "$289k",
        pillType: "Pre-Foreclosure",
        pillTypeStyle:"status2",
        ownerId: 1,
      },
      {
        id: 2,
        images: [
          property2Part3,property2Part2
          ,property2Part1,
        ],
        price: "$532,900",
        beds: 4,
        baths: 3,
        sqft: 3650,
        year: 2010,
        address: "507 Maplewood Ct, Brookhaven, GA, 30319",
        city: "Springfield",
        state: "OH",
        zip: "40036",
        lastSale: "5/22/2022",
        soldPrice: "$425k",
        pillType: "Vacant",
        pillTypeStyle:"status6",
        ownerId: 2,
      },
      {
    
        id: 3,
        images: [
          property3Part3,property3Part2
          ,property3Part1,
        ],
        price: "$299,800",
        beds: 2,
        baths: 2,
        sqft: 1720,
        year: 1998,
        address: "2816 Sycamore Ln, Crestview, OH, 44827",
        city: "Springfield",
        state: "OH",
        zip: "40036",
        lastSale: "8/05/2021",
        soldPrice: "$215k",
        pillType: "Tax-Lien",
        ownerId: 3,
        pillTypeStyle:"status3",
      },
      {
        id: 4,
        images: [
          property4Part3,property4Part2
          ,property4Part1
        ],
        price: "$675,400",
        beds: 5,
        baths: 4,
        sqft: 4250,
        year: 2015,
        address: "1629 Boulder Ridge Dr, Sterling, CO, 80751",
        city: "Springfield",
        state: "OH",
        zip: "40036",
        lastSale: "10/13/2023",
        soldPrice: "$589k",
        pillType: "Absentee Owner",
        ownerId: 4,
        pillTypeStyle:"status4",
      },
      {
        id: 5,
        images: [
          property5Part3,property5Part2
          ,property5Part1
        ],
        price: "$245,600",
        beds: 3,
        baths: 2,
        sqft: 1900,
        year: 1992,
        address: "7318 Willow Bend Rd, Marion, IN, 46952",
        city: "Springfield",
        state: "OH",
        zip: "40036",
        lastSale: "6/30/2020",
        soldPrice: "$189k",
        pillType: "High Equity",
        ownerId: 5,
        pillTypeStyle:"status5",
      },

      {
        id: 6,
        images: [
          property6Part3,property6Part2
          ,property6Part1
        ],
        price: "$399,500",
        beds: 4,
        baths: 2.5,
        sqft: 2975,
        year: 2008,
        address: "8432 Timber Ridge Trl, Hickory, NC, 28601",
        city: "Springfield",
        state: "OH",
        zip: "40036",
        lastSale: "9/15/2023",
        soldPrice: "$345k",
        pillType: "Zombie Property",
        ownerId: 1,
        pillTypeStyle:"status1",
      },

      {
        id: 7,
        images: [
          property7Part3,property7Part2
          ,property7Part1
        ],
        price: "$569,700",
        beds: 5,
        baths: 3.5,
        sqft: 3900,
        year: 2017,
        address: "6521 Summit View Dr, Lancaster, PA, 17601",
        city: "Springfield",
        state: "OH",
        zip: "40036",
        lastSale: "4/10/2024",
        soldPrice: "$498k",
        pillType: "Probate",
        ownerId: 2,
        pillTypeStyle:"status7",
      },
      {
        id: 8,
        images: [
          property8Part3,property8Part2
          ,property8Part1
        ],
        price: "$315,800",
        beds: 3,
        baths: 2,
        sqft: 2150,
        year: 2002,
        address: "9904 Elm Grove Ct, Franklin, TN, 37064",
        city: "Springfield",
        state: "OH",
        zip: "40036",
        lastSale: "12/18/2023",
        soldPrice: "$272k",
        pillType: "Pre-Foreclosure",
        ownerId: 3,
        pillTypeStyle:"status2",
      },
      {
        id: 9,
        images: [
          property9Part3,property9Part2
          ,property9Part1
        ],
        price: "$228,900",
        beds: 2,
        baths: 1,
        sqft: 1400,
        year: 1985,
        address: "1108 Oakwood Cir, Monroe, LA, 71201",
        city: "Springfield",
        state: "OH",
        zip: "40036",
        lastSale: "3/21/2023 ",
        soldPrice: "$175,500",
        pillType: "Vacant",
        ownerId: 3,
        pillTypeStyle:"status6",
      },
       {
        id: 10,
        images: [
          property10Part3,property10Part2
          ,property10Part1
        ],
        price: " $462,300",
        beds: 4,
        baths: 3,
        sqft: 3250,
        year: 2006,
        address: "3426 Riverbend Ln, Madison, WI, 53705",
        city: "Springfield",
        state: "OH",
        zip: "40036",
        lastSale: "5/11/2022",
        soldPrice: "$399,000",
        pillType: "High Equity",
        ownerId: 3,
        pillTypeStyle:"status5",
      },
       {
        id: 11,
        images: [
          property11Part3,property11Part2
          ,property11Part1
        ],
        price: "$387,200",
        beds: 3,
        baths: 2.5,
        sqft: 2700,
        year: 2011,
        address: "1803 Sunrise Ct, Lakewood, CO, 80228",
        city: "Springfield",
        state: "OH",
        zip: "40036",
        lastSale: "2/20/2024",
        soldPrice: "$345,500",
        pillType:"Probate",
        ownerId: 3,
        pillTypeStyle:"status7",
      },
      {
        id: 12,
        images: [
          property12Part3,property12Part2
          ,property12Part1
        ],
        price: "$278,500",
        beds: 2,
        baths: 2,
        sqft: 1875,
        year: 1995,
        address: "712 Birch Hollow Rd, Dover, DE, 19901",
        city: "Springfield",
        state: "OH",
        zip: "40036",
        lastSale: "11/29/2023",
        soldPrice: "$239,000",
        pillType: "Zombie Property",
        ownerId: 3,
        pillTypeStyle:"status1",
      }
]

export const propertiesPartTwo=[
  {
    id: 1,
    images: [property13Part3,property13Part2,
      property13Part1
    ],
    price: "$749,900",
    beds: 6,
    baths: 5,
    sqft: 5300,
    year: 2019,
    address: "5203 Crestview Dr, Scottsdale, AZ, 85251",
    city: "Springfield",
    state: "OH",
    zip: "63999",
    lastSale: "1/15/2023",
    soldPrice: "$685k",
    pillType: "Probate",
    ownerId: 2,
    pillTypeStyle:"status7",
  },

  {
    id: 2,
    images: [
      property14Part3,property14Part2
      ,property14Part1,
    ],
    price: "$189,300",
    beds: 2,
    baths: 1,
    sqft: 1250,
    year: 1980,
    address: "493 Maple St, Peoria, IL, 61615",
    city: "Springfield",
    state: "OH",
    zip: "40036",
    lastSale: "6/10/2022",
    soldPrice: "$159k",
    pillType: "Vacant",
    pillTypeStyle:"status6",
    ownerId: 2,
  },

  {
    id: 3,
    images: [
      property15Part3,property15Part2
      ,property15Part1,
    ],
    price: "$572,600",
    beds: 4,
    baths: 3.5,
    sqft: 3800,
    year: 2014,
    address: "2017 Ivy Ridge Dr, Charleston, SC, 29412",
    city: "Springfield",
    state: "OH",
    zip: "40036",
    lastSale: "8/24/2023",
    soldPrice: "$499k",
    pillType: "High Equity",
    ownerId: 3,
    pillTypeStyle:"status5",
  },
 
  {
    id: 4,
    images: [
      property16Part3,property16Part2
      ,property16Part1,
    ],
    price: "$325,700",
    beds: 3,
    baths: 2,
    sqft: 2200,
    year: 2005,
    address: "1305 Aspen Ct, Des Moines, IA, 50310",
    city: "Springfield",
    state: "OH",
    zip: "40036",
    lastSale: "3/05/2023",
    soldPrice: "$280k",
    pillType: "Tax-Lien",
    ownerId: 3,
    pillTypeStyle:"status3",
  },
  {
    id: 5,
    images: [
      property17Part3,property17Part2
      ,property17Part1,
    ],
    price: "$468,900",
    beds: 5,
    baths: 4,
    sqft: 3600,
    year: 2016,
    address: "6789 Oak Haven Dr, Boise, ID, 83709",
    city: "Springfield",
    state: "OH",
    zip: "40036",
    lastSale: "9/30/2023",
    soldPrice: "$410k",
    pillType: "Vacant",
    pillTypeStyle:"status6",
    ownerId: 2,
  },
  {
    id: 6,
    images: [
      property6Part3,property6Part2
      ,property6Part1
    ],
    price: "$399,500",
    beds: 4,
    baths: 2.5,
    sqft: 2975,
    year: 2008,
    address: "8432 Timber Ridge Trl, Hickory, NC, 28601",
    city: "Springfield",
    state: "OH",
    zip: "40036",
    lastSale: "9/15/2023",
    soldPrice: "$345k",
    pillType: "Zombie Property",
    ownerId: 1,
    pillTypeStyle:"status1",
  },
  {
    id: 7,
    images: [
      property7Part3,property7Part2
      ,property7Part1
    ],
    price: "$569,700",
    beds: 5,
    baths: 3.5,
    sqft: 3900,
    year: 2017,
    address: "6521 Summit View Dr, Lancaster, PA, 17601",
    city: "Springfield",
    state: "OH",
    zip: "40036",
    lastSale: "4/10/2024",
    soldPrice: "$498k",
    pillType: "Probate",
    ownerId: 2,
    pillTypeStyle:"status7",
  },
  {
    id: 8,
    images: [
      property8Part3,property8Part2
      ,property8Part1
    ],
    price: "$315,800",
    beds: 3,
    baths: 2,
    sqft: 2150,
    year: 2002,
    address: "9904 Elm Grove Ct, Franklin, TN, 37064",
    city: "Springfield",
    state: "OH",
    zip: "40036",
    lastSale: "12/18/2023",
    soldPrice: "$272k",
    pillType: "Pre-Foreclosure",
    ownerId: 3,
    pillTypeStyle:"status2",
  },
  {
    id: 9,
    images: [
      property9Part3,property9Part2
      ,property9Part1
    ],
    price: "$228,900",
    beds: 2,
    baths: 1,
    sqft: 1400,
    year: 1985,
    address: "1108 Oakwood Cir, Monroe, LA, 71201",
    city: "Springfield",
    state: "OH",
    zip: "40036",
    lastSale: "3/21/2023 ",
    soldPrice: "$175,500",
    pillType: "Vacant",
    ownerId: 3,
    pillTypeStyle:"status6",
  },
   {
    id: 10,
    images: [
      property10Part3,property10Part2
      ,property10Part1
    ],
    price: " $462,300",
    beds: 4,
    baths: 3,
    sqft: 3250,
    year: 2006,
    address: "3426 Riverbend Ln, Madison, WI, 53705",
    city: "Springfield",
    state: "OH",
    zip: "40036",
    lastSale: "5/11/2022",
    soldPrice: "$399,000",
    pillType: "High Equity",
    ownerId: 3,
    pillTypeStyle:"status5",
  },
   {
    id: 11,
    images: [
      property11Part3,property11Part2
      ,property11Part1
    ],
    price: "$387,200",
    beds: 3,
    baths: 2.5,
    sqft: 2700,
    year: 2011,
    address: "1803 Sunrise Ct, Lakewood, CO, 80228",
    city: "Springfield",
    state: "OH",
    zip: "40036",
    lastSale: "2/20/2024",
    soldPrice: "$345,500",
    pillType:"Probate",
    ownerId: 3,
    pillTypeStyle:"status7",
  },
  {
    id: 12,
    images: [
      property12Part3,property12Part2
      ,property12Part1
    ],
    price: "$278,500",
    beds: 2,
    baths: 2,
    sqft: 1875,
    year: 1995,
    address: "712 Birch Hollow Rd, Dover, DE, 19901",
    city: "Springfield",
    state: "OH",
    zip: "40036",
    lastSale: "11/29/2023",
    soldPrice: "$239,000",
    pillType: "Zombie Property",
    ownerId: 3,
    pillTypeStyle:"status1",
  }

]